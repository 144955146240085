import { createReducer } from "@reduxjs/toolkit";
import { set, unset } from "lodash";
import {
  setMetaProp,
  setMetaProps,
  resetWidgetMetaProperty,
} from "legacy/actions/metaActions";
import {
  fetchPageSuccess,
  fetchPublishedPageSuccess,
} from "legacy/actions/pageActions";
import { deleteWidgets } from "legacy/actions/widgetActions";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";

export type WidgetMetadata = Record<string, unknown> & {
  metaLastChange?: Date;
};
export type MetaState = Record<string, WidgetMetadata>;

const initialState: MetaState = {};

const metaReducer = createReducer<MetaState>(initialState, (builder) =>
  builder
    .addCase(
      setMetaProp,
      (state, { payload: { propertyName, propertyValue, widgetId } }) => {
        if (!state[widgetId]) {
          state[widgetId] = {};
        }
        state[widgetId].metaLastChange = new Date();
        set(state, `${widgetId}.${propertyName}`, propertyValue);
      },
    )
    .addCase(setMetaProps, (state, { payload: { updates, widgetId } }) => {
      if (!updates) return;
      if (!state[widgetId]) {
        state[widgetId] = {};
      }
      state[widgetId].metaLastChange = new Date();
      for (const propertyName in updates) {
        set(state, `${widgetId}.${propertyName}`, updates[propertyName]);
      }
    })
    .addCase(deleteWidgets, (state, { payload: { widgetIds } }) => {
      widgetIds?.forEach((widgetId) => {
        delete state[widgetId];
      });
    })
    .addCase(
      resetWidgetMetaProperty,
      (state, { payload: { widgetId, resetOptions } }) => {
        // Do not reset if the widget meta state does not exist
        if (!state[widgetId]) return;
        if (!resetOptions || !resetOptions.propertyNames) {
          state[widgetId] = { metaLastChange: new Date() };
        } else {
          resetOptions.propertyNames.forEach((propertyName: string) => {
            unset(state, `${widgetId}.${propertyName}`);
          });
          state[widgetId].metaLastChange = new Date();
          state[widgetId].isTouched = false;
        }
      },
    )
    .addCase(fetchPageSuccess, () => initialState)
    .addCase(fetchPublishedPageSuccess, () => initialState)
    .addCase(ReduxActionTypes.RESET_WIDGETS, () => initialState),
);

export default metaReducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationScope } from "@superblocksteam/shared";
import { set } from "lodash";
import { stopEvaluation } from "legacy/actions/evaluationActions";
import { initCanvasLayout } from "legacy/actions/pageActions";
import { toScopedEntity } from "store/utils/scope";
import {
  fetchApplicationSuccess,
  doneRefactoringAppConfiguration,
} from "../applicationActions";
import { AppTimer, AppTimerScoped } from "./TimerConstants";
import { renameTimers } from "./timerActions";

const initialState: {
  [ApplicationScope.GLOBAL]: Record<string, AppTimerScoped>;
  [ApplicationScope.APP]: Record<string, AppTimerScoped>;
  [ApplicationScope.PAGE]: Record<string, AppTimerScoped>;
} = {
  [ApplicationScope.GLOBAL]: {},
  [ApplicationScope.PAGE]: {},
  [ApplicationScope.APP]: {},
};

type TimersMap = typeof initialState;

export const timersSlice = createSlice({
  name: "timers",
  initialState,
  reducers: {
    overwriteScopedTimers: (
      state,
      action: PayloadAction<{
        scope: ApplicationScope;
        timers: TimersMap[ApplicationScope];
      }>,
    ) => {
      state[action.payload.scope] = action.payload.timers;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(initCanvasLayout, (state, action) => {
        if (action.payload.timerMap) {
          state[ApplicationScope.PAGE] = toScopedEntity<AppTimerScoped>(
            action.payload.timerMap,
            ApplicationScope.PAGE,
          );
        }
      })
      .addCase(fetchApplicationSuccess, (state, action) => {
        if (action.payload.configuration?.dsl?.timers?.timerMap) {
          state[ApplicationScope.APP] = toScopedEntity<AppTimerScoped>(
            action.payload.configuration.dsl.timers.timerMap as Record<
              string,
              AppTimer
            >,
            ApplicationScope.APP,
          );
        }
      })
      .addCase(doneRefactoringAppConfiguration, (state, action) => {
        if (action.payload.dsl?.timers?.timerMap) {
          state[ApplicationScope.APP] = toScopedEntity<AppTimerScoped>(
            action.payload.dsl.timers.timerMap as Record<string, AppTimer>,
            ApplicationScope.APP,
          );
        }
      })
      .addCase(renameTimers, (state, action) => {
        action.payload.updates.forEach(
          ({ timerId, propertyName, propertyValue, scope }) => {
            const timer = state[scope][timerId];
            if (timer) {
              set(timer, propertyName, propertyValue);
            }
          },
        );
      })
      .addCase(stopEvaluation, () => initialState);
  },
});

export const { overwriteScopedTimers } = timersSlice.actions;

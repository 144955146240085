import { isEqual } from "lodash";
import { useRef } from "react";

/**
 * A hook that caches a (non-primitive) value and returns the cached value for as long it
 * does not change with respect to a deep equality function.
 * This is useful when a non-primitive is to be used in a dependency array:
 * the cached value returned will be referentially the same object for as long as the
 * object has not changed and therefore the dependency will not change.
 * @param value     The value to cache. It does not make sense use this hook for primitive values.
 * @param isEqualFn The (deep) equality function to compare objects, defaults to `isEqual` from lodash.
 * @returns         `value` or a cached version of `value` that is (deeply) equal to `value`.
 */
export function useCachedValue<T>(
  value: T,
  isEqualFn: (cachedValue: T, curValue: T) => boolean = isEqual,
) {
  const cached = useRef(value);
  if (cached.current !== value && !isEqualFn(cached.current, value)) {
    cached.current = value;
  }
  return cached.current;
}

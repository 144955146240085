import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { ClientAuthMethod, getNativeOpenApiPlugin } from './shared';

export const NotionPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Notion',
  id: ExtendedIntegrationPluginId.NOTION,
  authMethods: {
    bearer: true,
    oauth2BringYourOwn: {
      tokenUrl: 'https://api.notion.com/v1/oauth/token',
      authorizationUrl: 'https://api.notion.com/v1/oauth/authorize',
      revokeTokenUrl: 'https://api.notion.com/v1/oauth/revoke',
      clientAuthMethod: ClientAuthMethod.BASIC,
      iconUrl: 'https://superblocks.s3.us-west-2.amazonaws.com/img/integrations/notion-login.svg',
      authorizationExtraParams: {
        responseType: 'code',
        owner: 'user'
      }
    }
  },
  serverURL: 'https://api.notion.com',
  headers: [{ key: 'Notion-Version', value: '2022-06-28' }]
});

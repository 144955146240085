import { ApplicationScope } from "@superblocksteam/shared";
import memoizeOne from "memoize-one";
import { all, call, select } from "redux-saga/effects";
import {
  getMergedDataTreeKeys,
  getMergedDataTree,
} from "legacy/selectors/dataTreeSelectors";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "legacy/utils/PerformanceTracker";
import { ROOT } from "store/utils/types";
import { createSaga } from "../../../utils/saga";
import { selectApiById, selectApiMeta } from "../selectors";
import slice from "../slice";
import { ActionsDto, ApiDto } from "../types";
import { extractBindingStringsFromActions } from "../utils/bindings";
import type { ApiMeta } from "../slice";

interface PayloadElement {
  id: string;
  name: string;
  bindings: string[];
  allIdentifiers: string[];
}

type Payload = Array<PayloadElement>;

const arraysEqual = memoizeOne(
  (a1: string[] | undefined, a2: string[] | undefined) => {
    return a1?.length === a2?.length && a1?.every((v, i) => v === a2?.[i]);
  },
);

// Loads all api deps based on extractingBindings && getEntityNames keys changing
function* getApiToComponentDepsInternal(
  {
    apiIdsToAnalyze,
  }: {
    // if `apiIdsToAnalyze` is undefined, then all APIs are analyzed
    apiIdsToAnalyze?: string[];
  },
  callId: number,
): Generator<any, Payload, any> {
  const dataTreeKeys: ReturnType<typeof getMergedDataTreeKeys> = yield select(
    (s) => getMergedDataTreeKeys(s, ApplicationScope.PAGE),
  );

  const apiMeta: Record<string, ApiMeta> = yield select(selectApiMeta);
  const apiIds = (apiIdsToAnalyze ?? Object.keys(apiMeta)).filter((id) => {
    if (id === ROOT) return false;
    const meta = apiMeta[id];
    const keysChanged = !arraysEqual(
      meta.identifiersAtLastBindingExtraction,
      dataTreeKeys,
    );
    const isFlaggedForExtraction = meta?.extractingBindings === callId;
    return keysChanged || isFlaggedForExtraction;
  });

  if (!apiIds.length) {
    return [];
  }
  const apiDtos: ApiDto[] = yield all(
    apiIds.map((id) => select(selectApiById, id)),
  );
  if (!apiDtos.length) {
    return [];
  }

  const dataTree: ReturnType<typeof getMergedDataTree> = yield select(
    getMergedDataTree,
    ApplicationScope.PAGE,
  );

  PerformanceTracker.startAsyncTracking(
    PerformanceTransactionName.EXTRACT_BINDINGS_FROM_API,
  );
  const bindings: Payload = yield all(
    apiDtos
      .filter((api) => api?.actions)
      .map(function* (api: ApiDto) {
        const bindingStrings: string[] = yield call(
          extractBindingStringsFromActions,
          api.actions as ActionsDto,
          dataTreeKeys,
          dataTree,
        );
        return {
          id: api.id,
          name: api?.actions?.name,
          bindings: bindingStrings,
          allIdentifiers: dataTreeKeys,
        } as PayloadElement;
      }),
  );
  PerformanceTracker.stopAsyncTracking(
    PerformanceTransactionName.EXTRACT_BINDINGS_FROM_API,
  );
  return bindings;
}

export const getApiToComponentDepsSaga = createSaga(
  getApiToComponentDepsInternal,
  "getApiToComponentDeps",
  {
    sliceName: "apis",
    autoGenerateUniqueKey: true,
  },
);

slice.saga(getApiToComponentDepsSaga, {
  start(state, { payload, callId }) {
    (payload.apiIdsToAnalyze ?? Object.keys(state.meta)).forEach((id) => {
      const meta = state.meta[id];
      if (id !== ROOT && meta?.needsBindingExtraction) {
        state.meta[id].extractingBindings = callId;
        state.meta[id].needsBindingExtraction = false;
      }
    });
  },
  success(state, { payload, meta }) {
    payload.forEach(({ id, bindings, allIdentifiers: keys }) => {
      state.meta[id] = state.meta[id] ?? {};
      state.meta[id].extractingBindings = undefined;
      state.meta[id].extractedBindings = bindings;
      state.meta[id].identifiersAtLastBindingExtraction = keys;
    });
  },
  error(state, { meta }) {
    (meta.args.apiIdsToAnalyze ?? Object.keys(state.meta)).forEach((id) => {
      state.meta[id].extractingBindings = undefined;
      state.meta[id].needsBindingExtraction = false;
      state.meta[id].extractedBindings = [];
    });
  },
});

import { Organization } from "@superblocksteam/shared";
import { Slice } from "store/utils/slice";
import { EntitiesStateWithMeta } from "store/utils/types";

export type OrganizationMeta = {
  userId?: string;
};

type OrganizationState = EntitiesStateWithMeta<Organization, OrganizationMeta>;

export default new Slice<OrganizationState>("organizations", {
  entities: {},
  loading: {},
  errors: {},
  meta: {},
});

import { HANDLING_STREAMING_MESSAGES } from "./shared";

export const SEND_DOCUMENTATION = `
# Send Block
The **Send** block is used to send messages to the frontend **onMessage** event handler. 

The **Send** block can be used:
- **Outside** of a **Stream** block: This pattern is useful to stream partial data to the client. For example, you could **send** each page of an API response as it's received rather than waiting for it to complete.
- **Inside** of a **Stream** block: This pattern is less common, since by default, the Stream block will automatically send a message for each message received by the trigger. You can disable this auto-send behavior and use the **Send** block in order to retain control of when messages are sent - for example, you could use the **Send** block to batch messages to the client. 

${HANDLING_STREAMING_MESSAGES}
`;

import { ApiTriggerType } from "@superblocksteam/shared";
import {
  StreamControlBlock,
  StreamControl,
  BlockType,
} from "store/slices/apisV2/control-flow/types";
import { BLOCK_HELP_CONTENT } from "../../ControlFlow/common-block-type-info";
import { makeStringValidJsVarName } from "../../ControlFlow/utils";
import { BaseTemplateField, BaseTemplateWithExtras } from "./BaseTemplate";
import {
  ControlFlowFormComponentType,
  ControlFlowFormItem,
  FormComponentType,
} from "./FormTemplateTypes";
export default class StreamTemplate extends BaseTemplateWithExtras<StreamControlBlock> {
  private getConfig(): Readonly<StreamControl> {
    return this.source.config;
  }

  autoSendField(): BaseTemplateField<StreamControlBlock, boolean> {
    const currentConfig = this.getConfig();
    const fieldValue = currentConfig.autoSend;

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Automatically send messages to client",
      subtitle:
        "Automatically trigger an onMessage event for each message received by the trigger step",
      componentType: FormComponentType.SWITCH,
      controlFlowTooltip:
        BLOCK_HELP_CONTENT[BlockType.STREAM].fieldHelpContent.autoSend,
    };

    const updateValue = (block: StreamControlBlock, newValue: boolean) => {
      block.config.autoSend = newValue;
    };

    return {
      formItem,
      fieldValue,
      updateValue,
    };
  }

  showProcessField(): BaseTemplateField<StreamControlBlock, boolean> {
    const currentConfig = this.getConfig();
    const fieldValue = currentConfig.process != null;

    const updateValue = (block: StreamControlBlock, newValue: boolean) => {
      block.config.process =
        newValue === false ? undefined : block.config.process || [];
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Process messages",
      subtitle:
        "Show the process section. This section can be used to process each message received by the trigger step",
      componentType: FormComponentType.SWITCH,
      initialValue: fieldValue,
      controlFlowTooltip:
        BLOCK_HELP_CONTENT[BlockType.STREAM].fieldHelpContent.showProcess,
      showConfirm:
        currentConfig.process != null && currentConfig.process.length > 0,
      confirmTitle: "Disabling this will delete all process blocks.",
    };

    return {
      formItem,
      fieldValue,
      updateValue,
    };
  }

  variablesField(): BaseTemplateField<
    StreamControlBlock,
    Record<string, string>
  > {
    const currentConfig = this.getConfig();

    const fieldValue: Record<string, string> = {};
    fieldValue.item = currentConfig.variables.item;

    const updateValue = (
      block: StreamControlBlock,
      newValue: Record<string, string>,
    ) => {
      const newVars: Record<string, string> = {};
      Object.keys(newValue).forEach((key) => {
        newVars[key] = makeStringValidJsVarName(newValue[key]);
      });
      block.config.variables = newVars as StreamControl["variables"];
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Streaming Variables",
      componentType: ControlFlowFormComponentType.LOCAL_VARIABLES_LIST,
      varNameOverrides: {
        item: "the current message",
      },
    };

    return {
      formItem,
      fieldValue,
      updateValue,
    };
  }

  computeFields() {
    return {
      ...(this.triggerType !== ApiTriggerType.SCHEDULE
        ? { toggleAutoSend: this.autoSendField() }
        : {}),
      showProcess: this.showProcessField(),
      ...(this.getConfig().process ? { itemVar: this.variablesField() } : {}),
    };
  }
}

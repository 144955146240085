import { FontFamily } from "@superblocksteam/shared";

export const LEGACY_AVAILABLE_FONTS: Record<string, FontFamily> = {
  Roboto: {
    name: "Roboto",
    weights: [100, 300, 400, 500, 700, 900],
    type: "google",
    key: "Roboto",
  },
  Inter: {
    name: "Inter",
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],

    type: "google",
    key: "Inter",
  },
  Poppins: {
    name: "Poppins",
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    type: "google",
    key: "Poppins",
  },
  "Noto Sans": {
    name: "Noto Sans",
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    type: "google",
    key: "Noto Sans",
  },
  "Work Sans": {
    name: "Work Sans",
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    type: "google",
    key: "Work Sans",
  },
  "DM Sans": {
    name: "DM Sans",
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    type: "google",
    key: "DM Sans",
  },
  "Roboto Mono": {
    name: "Roboto Mono",
    weights: [100, 200, 300, 400, 500, 600, 700],
    type: "google",
    key: "Roboto Mono",
  },
};

export const DEFAULT_FONT_WEIGHTS = [300, 400, 500, 700];

import {
  SUPERBLOCKS_AUTHORIZATION_HEADER,
  WidgetTypes,
} from "@superblocksteam/shared";
import { omit } from "lodash";
import TokenProvider from "auth/token";
import { getSimplifiedTypeFromWidgetType } from "legacy/utils/ai";
import { WidgetProps } from "legacy/widgets/BaseWidget/types";
import { ComponentEditAction, SortedProperties } from "./types";

export type FeedbackAction = {
  action: "accept" | "discard" | "exit" | "redo";
  type: string;
  modified: boolean;
  changeset?: Array<ComponentEditAction>;
  actionsRequestId: string;
};

export const sendAiWidgetEditActionsFeedback = async ({
  actionsRequestId,
  action,
  modified,
  type,
  changeset,
  aiServiceURL,
}: FeedbackAction & {
  aiServiceURL: string;
}): Promise<void> => {
  const authorizationJwt = TokenProvider.getToken();

  await fetch(`${aiServiceURL}/feedback`, {
    method: "POST",
    body: JSON.stringify({
      feedbacks: [
        {
          id: actionsRequestId,
          action,
          modified,
          component: type,
          changeset,
        },
      ],
    }),
    headers: {
      "Content-Type": "application/json",
      ...(authorizationJwt
        ? {
            [SUPERBLOCKS_AUTHORIZATION_HEADER]: `Bearer ${authorizationJwt}`,
          }
        : {}),
    },
  });
};

export const getAiWidgetEditActionsStream = async ({
  existingWidget,
  prompt,
  context,
  signal,
  onMessage,
  onError,
  onResponse,
  aiServiceURL,
}: {
  existingWidget: Partial<WidgetProps> | SortedProperties;
  prompt: string;
  context: Record<string, any>;
  signal?: AbortSignal;
  onMessage: (message: any) => void;
  onError: (error: string) => void;
  onResponse: (response: Response) => void;
  aiServiceURL: string;
}) => {
  const { streamServerSideEvents } = await import(
    /* webpackChunkName: "client-utils" */ "store/utils/client"
  );
  const componentType = existingWidget.type
    ? getSimplifiedTypeFromWidgetType(existingWidget.type as WidgetTypes)
    : "";

  await streamServerSideEvents({
    url: `${aiServiceURL}/systems/comedt`,
    body: {
      context,
      component: omit(existingWidget, ["type", "widgetId"]),
      type: componentType,
      prompt,
    },
    signal,
    onMessage,
    onResponse,
    onComplete: () => {},
    onError,
  });
};

import { createReducer } from "@reduxjs/toolkit";
import {
  openEditorTab,
  setEditorHeight,
} from "legacy/actions/editorPreferencesActions";
import { ApiEditorSizes } from "legacy/pages/Editor/ApiEditorSizes";

const initialState: APIPaneReduxState = {
  height: ApiEditorSizes.POPUP_SIZE,
};

const apiPaneReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(openEditorTab, (state) => {
      state.height = Math.max(state.height, ApiEditorSizes.POPUP_SIZE);
    })
    .addCase(setEditorHeight, (state, action) => {
      state.height = action.payload.height;
    }),
);

export interface APIPaneReduxState {
  height: number;
}

export default apiPaneReducer;

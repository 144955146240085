import ddLog from "../../../src/utils/logger";

function log(msg: string, id: string | undefined) {
  if (id) {
    ddLog.debug(`${msg} ${id}`, { id }, false);
  } else {
    ddLog.debug(msg, undefined, false);
  }
}

export function createLock() {
  let resolve: (() => void) | undefined;
  let prom: Promise<void> | undefined;
  let queue: Array<Promise<() => void>> = [];

  async function obtainLock(id?: string): Promise<() => void> {
    log("locking", id);
    if (resolve) {
      log("waiting to lock on previous lock", id);
      await prom;
      log("done waiting to lock on previous lock", id);
    }

    log("locked", id);

    let r: () => void;
    prom = new Promise((res) => {
      resolve = res;
      r = res;
    });

    return () => {
      log("unlocked", id);
      r();
    };
  }

  async function lock(id?: string): Promise<() => void> {
    if (queue.length) {
      log("queuing for lock", id);
    }

    const called = new Set();
    for (const caller of queue) {
      await caller;
      called.add(caller);
    }

    const unlocker = obtainLock(id);
    queue.push(unlocker);
    queue = queue.filter((caller) => !called.has(caller));

    return unlocker;
  }

  return lock;
}

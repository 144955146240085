// this function encodes characters of !'()* which are not encoded in encodeURIComponent
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
function fixedEncodeURIComponent(str: string) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    // The encoding of a character needs to be 2 hex digits, i.e. %0a and not %a, but this is not an issue here since all characters have code points between 0x10 and 0xff.
    return "%" + c.charCodeAt(0).toString(16);
  });
}

export function getUrlWithBranch(
  baseUrl: string,
  branch: string | undefined,
  suffixPath = "",
) {
  return (
    baseUrl +
    // TODO: get rid of the conditional and always use the path with the branch
    (branch ? `/branches/${fixedEncodeURIComponent(branch)}` : "") +
    suffixPath
  );
}

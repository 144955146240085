import equal from "@superblocksteam/fast-deep-equal/es6";
import {
  restartEvaluation,
  stopEvaluation,
} from "legacy/actions/evaluationActions";
import {
  disableDragAction,
  disableNudgeAction,
  focusWidget,
  selectWidgets,
  setSelectedWidgetsAncestory,
} from "legacy/actions/widgetActions";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { createImmerReducer } from "../createReducer";

const initialState: WidgetDragResizeState = {
  isNudgeDisabled: false,
  isDraggingDisabled: false,
  isDragging: false,
  isDraggingNewWidget: false,
  isResizing: false,
  selectedWidgets: [],
  focusedWidgetId: undefined,
  selectedWidgetsAncestory: {},
};

const widgetDraggingReducer = createImmerReducer(initialState, (builder) => {
  builder
    .addCase(stopEvaluation, () => initialState)
    .addCase(restartEvaluation, () => initialState)
    .addCase(disableDragAction, (state: WidgetDragResizeState, action) => {
      state.isDraggingDisabled = action.payload.isDraggingDisabled;
    })
    .addCase(disableNudgeAction, (state: WidgetDragResizeState, action) => {
      state.isNudgeDisabled = action.payload.isNudgeDisabled;
    })
    .addCase(
      ReduxActionTypes.SET_WIDGET_DRAGGING,
      (
        state: WidgetDragResizeState,
        action: ReduxAction<
          { isDragging: boolean; isDraggingNewWidget: boolean },
          typeof ReduxActionTypes.SET_WIDGET_DRAGGING
        >,
      ) => {
        state.isDragging = action.payload.isDragging;
        state.isDraggingNewWidget = action.payload.isDraggingNewWidget;
      },
    )
    .addCase(
      ReduxActionTypes.SET_DRAGGING_DROP_TARGET,
      (
        state: WidgetDragResizeState,
        action: ReduxAction<
          { dropTargetId: string },
          typeof ReduxActionTypes.SET_DRAGGING_DROP_TARGET
        >,
      ) => {
        const old = state.currentDropTarget;
        state.currentDropTarget = action.payload.dropTargetId;
        if (old !== action.payload.dropTargetId) {
          state.currentDropTargetRows = undefined;
        }
      },
    )
    .addCase(
      ReduxActionTypes.SET_DRAGGING_DROP_TARGET_ROWS,
      (
        state: WidgetDragResizeState,
        action: ReduxAction<
          { dropTargetRows: number },
          typeof ReduxActionTypes.SET_DRAGGING_DROP_TARGET_ROWS
        >,
      ) => {
        state.currentDropTargetRows = action.payload.dropTargetRows;
      },
    )
    .addCase(
      ReduxActionTypes.SET_WIDGET_RESIZING,
      (
        state: WidgetDragResizeState,
        action: ReduxAction<
          { isResizing: boolean },
          typeof ReduxActionTypes.SET_WIDGET_RESIZING
        >,
      ) => {
        state.isResizing = action.payload.isResizing;
        if (!action.payload.isResizing) {
          delete state.currentWidgetPixelSize;
        }
      },
    )
    .addCase(
      ReduxActionTypes.SET_WIDGET_RESIZING_DIMENSIONS,
      (
        state: WidgetDragResizeState,
        action: ReduxAction<
          { width: number; height: number },
          typeof ReduxActionTypes.SET_WIDGET_RESIZING_DIMENSIONS
        >,
      ) => {
        state.currentWidgetPixelSize = {
          width: action.payload.width,
          height: action.payload.height,
        };
      },
    )
    .addCase(
      ReduxActionTypes.SET_WIDGET_RESIZING_STACK_WIDTH,
      (
        state: WidgetDragResizeState,
        action: ReduxAction<
          { resizingStackWidth: number | null | undefined },
          typeof ReduxActionTypes.SET_WIDGET_RESIZING_STACK_WIDTH
        >,
      ) => {
        state.resizingStackWidth = action.payload.resizingStackWidth;
      },
    )
    .addCase(selectWidgets, (state: WidgetDragResizeState, action) => {
      const { widgetIds, selectingMultiple } = action.payload;

      if (selectingMultiple) {
        widgetIds.forEach((widgetId) => {
          if (state.selectedWidgets.includes(widgetId)) {
            // We're selecting multiple and this widgetId
            // is already selected widget, so remove it
            state.selectedWidgets.splice(
              state.selectedWidgets.indexOf(widgetId),
              1,
            );
          } else {
            state.selectedWidgets.push(widgetId);
          }
        });
      } else if (!equal(widgetIds, state.selectedWidgets)) {
        state.selectedWidgets = widgetIds;
      }
    })
    .addCase(focusWidget, (state: WidgetDragResizeState, action) => {
      state.focusedWidgetId = action.payload.widgetId;
    })
    .addCase(
      setSelectedWidgetsAncestory,
      (state: WidgetDragResizeState, action) => {
        const entries = action.payload;
        if (entries.length < 1) return;

        state.selectedWidgetsAncestory = {};

        entries.forEach((entry) => {
          state.selectedWidgetsAncestory[entry.widgetId] = entry.ancestory;
        });
      },
    );
});

export type WidgetDragResizeState = {
  isNudgeDisabled: boolean;
  isDraggingDisabled: boolean;
  isDragging: boolean;

  isResizing: boolean;
  currentWidgetPixelSize?: { width: number; height: number };

  currentDropTarget?: string;
  currentDropTargetRows?: number;

  isDraggingNewWidget: boolean;
  selectedWidgets: string[];
  focusedWidgetId?: string;
  selectedWidgetsAncestory: Record<string, string[]>;

  // for fit content width
  resizingStackWidth?: number | null;
};

export default widgetDraggingReducer;

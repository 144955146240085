import { ExtendedIntegrationPluginId, Plugin, SUPERBLOCKS_OPENAPI_TENANT_KEYWORD } from '../../types';
import { PUBLIC_INTEGRATIONS_CDN_URL, getNativeOpenApiPlugin } from './shared';

export const ElasticSearchPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Elasticsearch',
  id: ExtendedIntegrationPluginId.ELASTICSEARCH,
  authMethods: {
    basic: true
  },
  openApiSpecURL: `${PUBLIC_INTEGRATIONS_CDN_URL}/openapi/elasticsearch.yaml`,
  logoURL: `${PUBLIC_INTEGRATIONS_CDN_URL}/img/elasticsearch.png`,
  serverURL: `${SUPERBLOCKS_OPENAPI_TENANT_KEYWORD}`,
  tenantInput: {
    label: 'Elasticsearch host',
    placeholder: 'https://6997e2f921fe5a0eb92af014d4c6fa47.us-central1.gcp.cloud.es.io'
  }
});

import {
  type BaseBlock,
  LOOP_TYPE,
  OrchestratorViewMode,
  PlaceholderInfo,
  Signature,
  ViewMode,
  WAIT_NUM,
  WAIT_STR,
} from "@superblocksteam/shared";

export type Api = {
  metadata: {
    name: string;
    id: string;
    organization: string;
    timestamps?: {
      created: string;
      updated: string;
      deactivated: boolean;
    };
    // These properties are merged in from the v3 api entity
    creator?: {
      id: string;
      name: string;
    };
    folder?: string;
  };
  blocks?: BaseBlock[];
  trigger: Trigger;
  signature?: Signature;
};

export type Trigger = {
  application?: ApplicationTriggerConfig;
  workflow?: WorkflowTriggerConfig;
  job?: JobTriggerConfig;
};

type Profile = {
  default?: string;
  available?: string[];
};

export type Profiles = {
  modes?: {
    [ViewMode.EDITOR]?: Profile;
    [ViewMode.PREVIEW]?: Profile;
    [ViewMode.DEPLOYED]?: Profile;
  };
};

type ApplicationTriggerConfig = {
  id?: string;
  pageId?: string;
  options?: {
    executeOnPageLoad?: boolean;
  };
};

export type WorkflowTriggerConfig = {
  options?: {
    profiles?: Profiles;
    deployedCommitId?: string;
  };
  parameters?: {
    query: Record<string, { values: string[] }>;
    body: Record<string, any>;
  };
};

export enum JobInterval {
  INTERVAL_UNSPECIFIED = "INTERVAL_UNSPECIFIED",
  INTERVAL_MINUTE = "INTERVAL_MINUTE",
  INTERVAL_HOUR = "INTERVAL_HOUR",
  INTERVAL_DAY = "INTERVAL_DAY",
  INTERVAL_WEEK = "INTERVAL_WEEK",
  INTERVAL_MONTH = "INTERVAL_MONTH",
}

type JobDays = {
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
};

export type JobTriggerConfig = {
  options?: {
    profiles: Profiles;
    sendEmailOnFailure: boolean;
    deployedCommitId?: string;
  };
  frequency?: number; // gte 1
  interval?: JobInterval;
  dayOfMonth?: number; // gte 1, lte 32
  days?: JobDays;
  time?: string; // UTC datestring, as per google protobuf: https://protobuf.dev/reference/protobuf/google.protobuf/#timestamp
  timezoneLocale?: string;
};

export { WAIT_NUM, WAIT_STR, LOOP_TYPE, BaseBlock as Block };
interface BaseExecutionRequest {
  options: {
    includeEventOutputs: boolean;
    includeEvents: boolean;
    includeResolved: boolean;
  };
  inputs: Record<string, any>;
}

interface ApiDefinitionExecutionRequest extends BaseExecutionRequest {
  definition: { api: Omit<Api, "trigger"> };
  profile?: Profile;
}

interface ApiFetchExecutionRequest extends BaseExecutionRequest {
  fetch: {
    id: string;
    profile: {
      name: string;
      id?: string;
    };
    branchName?: string;
    viewMode?: OrchestratorViewMode;
    test?: boolean;
    commitId?: string;
  };
}

export type ApiV2ExecutionRequest =
  | ApiDefinitionExecutionRequest
  | ApiFetchExecutionRequest;

export type ExecutionError = {
  name?: string;
  message: string;
  formPath: string;
  blockPath: string;
  handled?: boolean;
};

export type ExecutionEventRequest = {
  summary: string;
  metadata: {
    placeHoldersInfo?: Record<string, PlaceholderInfo>;
  };
};

export type Resolutions = Record<
  string, // name of the field: eg: "range", for the range field let's say the code is {{[1,2,3]}}
  {
    value: any; // This is what the field evaluated too, eg: [1,2,3]
    bindings: any[]; // This is a breakdown of each binding, eg: [[1,2,3]]
  }
>;

export type ExecutionEvent = {
  name: string;
  type: string;
  timestamp: string;
  start?: Record<string, unknown>;
  end?: {
    performance?: {
      start: string | number;
      finish: string | number;
      total: string | number;
      execution: string | number;
      overhead: string | number;
    };
    error?: ExecutionError;
    output?: {
      result?: any;
      request?: string;
      requestV2?: ExecutionEventRequest;
      stdout?: Array<string>;
      stderr?: Array<string>;
    };
    resolved?: Resolutions;
  };
  parent: string;
};

export interface StreamEvent {
  result: {
    event: ExecutionEvent;
    execution: string;
  };
}

export interface ApiV2ExecutionResponse {
  execution: string;
  output?: {
    result: any;
  };
  status: "STATUS_EXECUTING" | "STATUS_COMPLETED" | "STATUS_UNSPECIFIED";
  events?: Array<ExecutionEvent | StreamEvent>;
  errors?: Array<{ message: string; handled?: boolean }>;
}

export type FileRequestParam = {
  originalName: string;
  buffer: string; // base64 encoded string
  encoding: "base64";
  mimetype: "text/plain";
};

import { ApplicationScope as Scope } from "@superblocksteam/shared";

export type ScopeResult = {
  scope: Scope;
  prefix?: string;
  hidden?: boolean;
}[];

const APP_SCOPES = Object.freeze([
  { scope: Scope.APP },
  { scope: Scope.GLOBAL },
]);
const PAGE_SCOPES = Object.freeze([
  { scope: Scope.PAGE },
  { scope: Scope.APP, prefix: "App", hidden: true },
  { scope: Scope.GLOBAL },
]);
const GLOBAL_SCOPES = Object.freeze([{ scope: Scope.GLOBAL }]);

export const resolveScopes = (scope: Scope): Readonly<ScopeResult> => {
  switch (scope) {
    case Scope.APP:
      return APP_SCOPES;
    case Scope.PAGE:
      return PAGE_SCOPES;
    case Scope.GLOBAL:
      return GLOBAL_SCOPES;
    default: {
      const exhaustiveCheck: never = scope;
      throw new Error(`Unhandled scope: ${exhaustiveCheck}`);
    }
  }
};

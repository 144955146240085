import {
  EmailVerificationStatus,
  GroupBrief,
  SurveyToShow,
  UserAuthSource,
} from "@superblocksteam/shared";

export const ANONYMOUS_USERNAME = "anonymousUser";

type Gender = "MALE" | "FEMALE";

export type User = {
  id: string;
  email: string;
  currentOrganizationId: string;
  organizationIds: string[];
  applications: UserApplication[];
  username: string;
  name: string;
  gender: Gender;
  anonymousId: string;
  isAnonymous: boolean;
  surveyToShow: SurveyToShow;
  emailVerificationStatus: EmailVerificationStatus;
  isAdmin: boolean;
  groups: GroupBrief[];
  metadata: Record<string, unknown>;
  source: UserAuthSource;
  new: boolean;
};

interface UserApplication {
  id: string;
  name: string;
}

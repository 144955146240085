import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { createImmerReducer } from "../createReducer";

const initialState: CanvasSelectionState = {
  isDraggingForSelection: false,
  widgetId: "",
};

const canvasSelectionReducer = createImmerReducer(initialState, (builder) =>
  builder
    .addCase(
      ReduxActionTypes.START_CANVAS_SELECTION,
      (
        state: CanvasSelectionState,
        action: ReduxAction<
          { widgetId?: string },
          typeof ReduxActionTypes.START_CANVAS_SELECTION
        >,
      ) => {
        state.isDraggingForSelection = true;
        state.widgetId = action.payload.widgetId;
      },
    )
    .addCase(
      ReduxActionTypes.STOP_CANVAS_SELECTION,
      (state: CanvasSelectionState) => {
        state.isDraggingForSelection = false;
        state.widgetId = "";
      },
    ),
);

export type CanvasSelectionState = {
  isDraggingForSelection: boolean;
  widgetId?: string;
};

export default canvasSelectionReducer;

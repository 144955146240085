import { PLUGIN_ID_TO_PROTO_CLASS } from "@superblocksteam/shared";
import { Block } from "../store/slices/apisV2/backend-types";

export const marshalBlockInPlace = (block: Block): void => {
  if (!block?.step) {
    return;
  }
  for (const pluginId in PLUGIN_ID_TO_PROTO_CLASS) {
    if (pluginId in block.step) {
      const newPluginData = block.step[pluginId];
      const ProtoClass = PLUGIN_ID_TO_PROTO_CLASS[pluginId];
      const proto = new ProtoClass(newPluginData);
      // update it in-place to be marshalled json
      block.step[pluginId] = proto.toJson();
    }
  }
};

const unmarshalBlockInPlace = (block: Block): void => {
  if (!block?.step) {
    return;
  }
  for (const pluginId in PLUGIN_ID_TO_PROTO_CLASS) {
    if (pluginId in block.step) {
      // update it in-place to be un-marshalled json that is needed by templates
      block.step[pluginId] = PLUGIN_ID_TO_PROTO_CLASS[pluginId].fromJson(
        block.step[pluginId],
      );
    }
  }
};

// backend-types.ts contains a "copy" of proto types
// frontend operates based on those "copied" types
// block might be a control block that has children
// but where those children locate is unknown, so
// this function will try to examine the whole object
// and look for properties with name "blocks" then marshal it
export const findAndMarshalProtoBlocks = (object: any): void => {
  if (object === undefined || object === null) {
    return;
  }
  Object.entries(object).forEach(([key, value]): void => {
    if (key === "blocks" && Array.isArray(value)) {
      // marshal in place
      (value as Block[]).map(marshalBlockInPlace);
    }
    if (key && typeof value === "object") {
      findAndMarshalProtoBlocks(value);
    }
  });
};

// This function will try to examine the whole object
// and look for properties with name "blocks" then unmarshal it
export const findAndUnMarshalProtoBlocks = (object: any): void => {
  if (object === undefined || object === null) {
    return;
  }
  Object.entries(object).forEach(([key, value]): void => {
    if (key === "blocks" && Array.isArray(value)) {
      // un-marshal in place
      (value as Block[]).map(unmarshalBlockInPlace);
    }
    if (key && typeof value === "object") {
      findAndUnMarshalProtoBlocks(value);
    }
  });
};

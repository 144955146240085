import { AppTimer } from "store/slices/application/timers/TimerConstants";
import type { AppStateVar6 } from "@superblocksteam/shared";
import type { ApiInfo } from "legacy/constants/ApiConstants";
import type { PropertyPaneControlConfig } from "legacy/constants/PropertyControlConstants";
import type { WidgetProps } from "legacy/widgets";
import type { NestedItemProps } from "legacy/widgets/NestedItem";

type AiEditProperties = Record<string, unknown>;

export function getPropertyName(
  item: {
    originalItemId?: PropertyPaneControlConfig["originalItemId"];
    propertyName: PropertyPaneControlConfig["propertyName"];
  },
  itemProperties:
    | WidgetProps
    | NestedItemProps
    | ApiInfo
    | AppTimer
    | AppStateVar6
    | AiEditProperties,
): string {
  return typeof item.propertyName === "function"
    ? item.propertyName(itemProperties, {
        originalItemId: item.originalItemId,
      })
    : item.propertyName;
}

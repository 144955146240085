import equal from "@superblocksteam/fast-deep-equal/es6";
import { DependencyList, useRef } from "react";

/**
 * Custom hook that memoizes a value based on deep equality of dependencies.
 * @param factory A function that returns the value to be memoized.
 * @param deps An array of dependencies that trigger the memoization.
 * @returns The memoized value.
 */
const useDeepMemo = <T>(factory: () => T, deps: DependencyList): T => {
  const ref = useRef<{ value: T; deps: DependencyList }>();

  // Check if the memoized value needs to be updated
  if (!ref.current || !equal(deps, ref.current.deps)) {
    ref.current = { value: factory(), deps };
  }

  return ref.current.value;
};

export default useDeepMemo;

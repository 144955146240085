import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const TwilioPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Twilio',
  id: ExtendedIntegrationPluginId.TWILIO,
  authMethods: {
    basic: {
      username: { label: 'API key SID', placeholder: 'myapikeyid' },
      password: { label: 'API key secret', placeholder: 'myapikeysecret' }
    }
  },
  serverURL: 'https://api.twilio.com'
});

import { Dimension } from "@superblocksteam/shared";
import {
  CanvasLayout,
  WidgetTypes,
  WidgetWidthModes,
  WidgetHeightModes,
} from "legacy/constants/WidgetConstants";
import { type ChildPosInfos } from "./hooks";

export const childStackItemElementId = (childWidgetId: string) =>
  `wrapped-stack-item-${childWidgetId}`;

export const isStackLayout = (layout: CanvasLayout | undefined | null) => {
  if (layout == null) {
    return false;
  }
  return layout !== CanvasLayout.FIXED;
};

export const isInvisibleButExpandedInStack = (props: {
  isVisible?: boolean;
  collapseWhenHidden?: boolean;
  parentLayout?: CanvasLayout;
  type?: string;
}) => {
  return (
    !props.isVisible &&
    !props.collapseWhenHidden &&
    (isStackLayout(props.parentLayout) ||
      props.type === WidgetTypes.SECTION_WIDGET) // Sections are in Pages,Modal,Slideouts which are similar to vStacks layouts
  );
};

type WidgetShape = {
  left?: Dimension<"gridUnit">;
  top?: Dimension<"gridUnit">;
  height?: Dimension<WidgetHeightModes>;
  width?: Dimension<WidgetWidthModes>;
  widgetId?: string;
};
const dimensionIsGridUnit = (
  dimension: Dimension | undefined,
): dimension is Dimension<"gridUnit"> => {
  return dimension?.mode === "gridUnit";
};

const dimensionIsGridType = (
  dimension: Dimension | undefined,
): dimension is Dimension<"gridUnit" | "fillParent" | "fitContent"> => {
  return (
    dimension?.mode === "gridUnit" ||
    dimension?.mode === "fillParent" ||
    dimension?.mode === "fitContent"
  );
};

export type StackDragPositions = Record<
  string,
  {
    left: Dimension<"gridUnit" | "fillParent" | "fitContent">;
    top: Dimension<"gridUnit" | "fillParent" | "fitContent">;
    height: Dimension<WidgetHeightModes>;
    width: Dimension<WidgetWidthModes>;
  }
>;

type RectLike<T> = {
  top: T;
  left: T;
  bottom: T;
  right: T;
};

type WithSizeLike<T> = {
  width: T;
  height: T;
};

const makeRectGetters = (isVertical: boolean) => {
  return {
    major: {
      start: <T>(rectLike: RectLike<T>): T => {
        return isVertical ? rectLike.top : rectLike.left;
      },
      end: <T>(rectLike: RectLike<T>): T => {
        return isVertical ? rectLike.bottom : rectLike.right;
      },
      length: <T>(rectLike: WithSizeLike<T>): T => {
        return isVertical ? rectLike.height : rectLike.width;
      },
    },
    minor: {
      start: <T>(rectLike: RectLike<T>): T => {
        return isVertical ? rectLike.left : rectLike.top;
      },
      end: <T>(rectLike: RectLike<T>): T => {
        return isVertical ? rectLike.right : rectLike.bottom;
      },
      length: <T>(rectLike: WithSizeLike<T>): T => {
        return isVertical ? rectLike.width : rectLike.height;
      },
    },
  };
};

export const RECT_GETTERS = {
  vertical: makeRectGetters(true),
  horizontal: makeRectGetters(false),
};

export const getOverridePos = (
  widgetShape: WidgetShape,
  overrides?: StackDragPositions,
): WidgetShape => {
  const override = widgetShape.widgetId && overrides?.[widgetShape.widgetId];
  if (!override) {
    return widgetShape;
  }
  const { left, top, height } = override;
  if (
    !dimensionIsGridUnit(left) ||
    !dimensionIsGridUnit(top)
    // Do not check width because it being a px value is perfectly fine
  ) {
    return widgetShape;
  }
  return {
    ...widgetShape,
    left,
    top,
    height: dimensionIsGridType(height) ? height : widgetShape.height,
    width: override.width ?? widgetShape.width,
  };
};

// TODO layouts: unit tests for the functionality in this hook

// This does not give the geometrically cloests child to the cursor, but gives a good enough result
// so that the caller knows where the cursor is within the stack.
export const computeClosestChildInStack = (params: {
  childRects: ChildPosInfos;
  isVertical: boolean;
  x: number;
  y: number;
}): {
  widgetId: string | null;
  widgetIndex: number;
  distance: number;
  posIsAfterMidpoint?: boolean;
} => {
  const { childRects, isVertical, x, y } = params;

  const closestChild = Object.keys(childRects).reduce(
    (
      acc: {
        widgetId: string | null;
        widgetIndex: number;
        distance: number;
      },
      key,
    ) => {
      const [childRect, origIndex] = childRects[key];
      // TODO layouts: this won't work for Hstacks that wrap
      const distance = isVertical
        ? Math.abs(y - childRect.top)
        : Math.abs(x - childRect.left);
      if (distance < acc.distance) {
        acc = {
          widgetId: key,
          widgetIndex: origIndex,
          distance,
        };
      }
      return acc;
    },
    {
      widgetId: null,
      widgetIndex: -1,
      distance: Number.MAX_SAFE_INTEGER,
    },
  );

  if (closestChild.widgetId == null) {
    return closestChild;
  }

  const [childRect] = childRects[closestChild.widgetId];
  const midpoint = isVertical
    ? (childRect.top + childRect.bottom) / 2
    : (childRect.left + childRect.right) / 2;

  const isAfterMidpoint = (isVertical ? y : x) > midpoint;

  return {
    ...closestChild,
    posIsAfterMidpoint: isAfterMidpoint,
  };
};

import { cssTransition } from "react-toastify";
import { css, keyframes } from "styled-components";

const slideInTop = keyframes`
  from {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;
const slideInBottom = keyframes`
  from {
    transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;
const slideInRight = keyframes`
  from {
    transform: translate3d(20%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;
const slideInLeft = keyframes`
  from {
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

export const slideAndFadeCSS = css`
  [class^="Toastify__fade--enter"] {
    animation-duration: 0.3s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  .Toastify__fade--exit--top-left,
  .Toastify__fade--exit--top-right,
  .Toastify__fade--exit--top-center,
  .Toastify__fade--exit--bottom-left,
  .Toastify__fade--exit--bottom-right,
  .Toastify__fade--exit--bottom-center {
    animation-direction: reverse;
  }

  .Toastify__fade--enter--top-left,
  .Toastify__fade--exit--top-left,
  .Toastify__fade--enter--bottom-left,
  .Toastify__fade--exit--bottom-left {
    animation-name: ${slideInLeft};
  }

  .Toastify__fade--enter--top-right,
  .Toastify__fade--exit--top-right,
  .Toastify__fade--enter--bottom-right,
  .Toastify__fade--exit--bottom-right {
    animation-name: ${slideInRight};
  }

  .Toastify__fade--enter--top-center,
  .Toastify__fade--exit--top-center {
    animation-name: ${slideInTop};
  }

  .Toastify__fade--enter--bottom-center,
  .Toastify__fade--exit--bottom-center {
    animation-name: ${slideInBottom};
  }
`;

export const FadeAndSlide = cssTransition({
  enter: "Toastify__fade--enter",
  exit: "Toastify__fade--exit",
  duration: [200, 300],
  appendPosition: true,
});

import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const GoogleDrivePlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Google Drive',
  id: ExtendedIntegrationPluginId.GOOGLE_DRIVE,
  authMethods: {
    oauth2BringYourOwn: {
      authorizationUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
      tokenUrl: 'https://oauth2.googleapis.com/token',
      revokeTokenUrl: 'https://oauth2.googleapis.com/revoke',
      iconUrl: 'https://integrations.superblocks.com/img/drive.png',
      authorizationExtraParams: {
        responseType: 'code'
      }
    }
  },
  serverURL: 'https://www.googleapis.com/drive/v3'
});

import React from "react";
import { ReactComponent as DemoIcon } from "assets/icons/checklist/atom.svg";
import { ReactComponent as BadgeIcon } from "assets/icons/checklist/check-badge.svg";
import { ReactComponent as SurveyIcon } from "assets/icons/checklist/flag.svg";
import { ReactComponent as InviteIcon } from "assets/icons/checklist/invite-outlined.svg";
import { ReactComponent as TrophyIcon } from "assets/icons/checklist/trophy.svg";
import { ReactComponent as AppIcon } from "assets/icons/home/app.svg";
import { ReactComponent as JobIcon } from "assets/icons/home/job.svg";
import { ReactComponent as WorkflowIcon } from "assets/icons/home/workflow.svg";
import { ReactComponent as ApiIcon } from "assets/icons/widgets/api.svg";
import { ReactComponent as CloseIcon } from "legacy/assets/icons/control/close.svg";
import { IconProps, IconWrapper } from "legacy/constants/IconConstants";

export const ChecklistIcons = {
  TROPHY_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <TrophyIcon />
    </IconWrapper>
  ),
  SURVEY_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <SurveyIcon />
    </IconWrapper>
  ),
  EXPLORE_DEMO_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <DemoIcon />
    </IconWrapper>
  ),
  CONNECT_INTEGRATION_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <ApiIcon />
    </IconWrapper>
  ),
  APP_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <AppIcon />
    </IconWrapper>
  ),
  INVITE_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <InviteIcon />
    </IconWrapper>
  ),
  WORKFLOW_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <WorkflowIcon />
    </IconWrapper>
  ),
  SCHEDULED_JOB_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <JobIcon />
    </IconWrapper>
  ),
  BADGE_ICON: (props: IconProps) => (
    <IconWrapper {...props}>
      <BadgeIcon />
    </IconWrapper>
  ),
  CLOSE_ICON: (props: IconProps) => (
    <IconWrapper data-test="checklist-close-button" {...props}>
      <CloseIcon />
    </IconWrapper>
  ),
};

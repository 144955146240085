export interface TableFilters {
  byColumn?: { [column: string]: ColumnFilter };
}

export interface ColumnFilter {
  byValue?: FilterByValue;
  condition?: Condition;
}

interface FilterByValue {
  excluded?: any[];
}

type Condition = Condition0 | Condition1 | Condition2;
export type ConditionType = Condition["type"];

// nullary conditions types
export enum Condition0Type {
  all = "all",
  isEmpty = "isEmpty",
  isNotEmpty = "isNotEmpty",
  isTrue = "isTrue",
  isFalse = "isFalse",
}
// unary conditions types
export enum Condition1Type {
  textIs = "textIs",
  textContains = "textContains",
  textDoesNotContain = "textDoesNotContain",
  textStartsWith = "textStartsWith",
  textEndsWith = "textEndsWith",
  isEqualTo = "isEqualTo",
  isNotEqualTo = "isNotEqualTo",
  isLessThan = "isLessThan",
  isLessThanOrEqualTo = "isLessThanOrEqualTo",
  isGreaterThan = "isGreaterThan",
  isGreaterThanOrEqualTo = "isGreaterThanOrEqualTo",
  dateIs = "dateIs",
  dateIsBefore = "dateIsBefore",
  dateIsAfter = "dateIsAfter",
}
// binary conditions types
export enum Condition2Type {
  isBetween = "isBetween",
  dateIsBetween = "dateIsBetween",
}

interface Condition0 {
  type: Condition0Type;
}
interface Condition1 {
  type: Condition1Type;
  param: any;
}
interface Condition2 {
  type: Condition2Type;
  param1: any;
  param2: any;
}

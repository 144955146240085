import {
  restartEvaluation,
  stopEvaluation,
} from "legacy/actions/evaluationActions";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import {
  ActionTriggerMap,
  DependencyMap,
} from "legacy/utils/DynamicBindingTypes";
import { createImmerReducer } from "../createReducer";

export type EvaluationDependencyState = {
  // Keys are dotted paths. Values depend on keys.
  inverseDependencyMap: DependencyMap;
  // Keys & values are top-level names. Keys depend on values
  entityDependencyMap: DependencyMap;
  // Keys are top-level names. Values are lists of API names.
  // It includes direct and indirect dependencies and is based on runtime dependency info.
  entityToApiDepMap: DependencyMap | undefined;
  // Keys are action names. Values are lists of trigger info
  actionTriggerMap: ActionTriggerMap;
};

const initialState: EvaluationDependencyState = {
  inverseDependencyMap: {},
  entityDependencyMap: {},
  entityToApiDepMap: undefined,
  actionTriggerMap: {},
};

const evaluationDependencyReducer = createImmerReducer(
  initialState,
  (builder) =>
    builder
      .addCase(
        ReduxActionTypes.SET_EVALUATION_DEPENDENCIES,
        (
          state: EvaluationDependencyState,
          action: ReduxAction<
            EvaluationDependencyState,
            typeof ReduxActionTypes.SET_EVALUATION_DEPENDENCIES
          >,
        ) => {
          state.inverseDependencyMap = Object.freeze(
            action.payload.inverseDependencyMap,
          );
          state.actionTriggerMap = Object.freeze(
            action.payload.actionTriggerMap,
          );
          if (!state.entityToApiDepMap) {
            state.entityToApiDepMap = {};
          }
          for (const key in action.payload.entityToApiDepMap) {
            state.entityToApiDepMap[key] =
              action.payload.entityToApiDepMap[key];
          }
          state.entityDependencyMap = Object.freeze(
            action.payload.entityDependencyMap,
          );
        },
      )
      .addCase(stopEvaluation, () => initialState)
      .addCase(restartEvaluation, () => initialState),
);

export default evaluationDependencyReducer;

import useResizeObserver from "@react-hook/resize-observer";
import { useState, useLayoutEffect } from "react";
import React from "react";
import { DebounceSettings, useDebounce } from "./useDebounce";

// only use this for size not position
export function useElementRect<T extends HTMLElement>(
  target: React.RefObject<T> | T | null,
  wait?: number,
  options?: DebounceSettings,
) {
  const [size, setSize] = useState<DOMRect>();
  const setSizeDebounced = useDebounce(setSize, wait ?? 0, options);

  useLayoutEffect(() => {
    const element = target && "current" in target ? target.current : target;

    if (!element) {
      return;
    }

    setSize(element.getBoundingClientRect());
  }, [target]);

  // Where the magic happens
  useResizeObserver(
    target,
    (entry) => setSizeDebounced && setSizeDebounced(entry.contentRect),
  );
  return size;
}

export function useElementWidth<T extends HTMLElement>(
  target: React.RefObject<T> | T | null,
  wait?: number,
) {
  const rect = useElementRect(target, wait);
  return rect?.width ?? 0;
}

function useElementHeight<T extends HTMLElement>(
  target: React.RefObject<T> | T | null,
  wait?: number,
) {
  const rect = useElementRect(target, wait);
  return rect?.height ?? 0;
}

export function useHeightChange<T extends HTMLElement>(
  target: React.RefObject<T> | T | null,
  onChange: (height: number) => void,
  wait?: number,
) {
  const height = useElementHeight(target, wait);
  const onChangeRef = React.useRef(onChange);

  React.useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  React.useEffect(() => {
    onChangeRef.current(height);
  }, [height]);
}

export function useWidthChange<T extends HTMLElement>(
  target: React.RefObject<T> | T | null,
  onChange: (width: number) => void,
  wait?: number,
) {
  const width = useElementWidth(target, wait);
  const onChangeRef = React.useRef(onChange);

  React.useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  React.useEffect(() => {
    onChangeRef.current(width);
  }, [width]);
}

export function useRectChange<T extends HTMLElement>(
  target: React.RefObject<T> | T | null,
  onChange: (width: number, height: number) => void,
  wait?: number,
) {
  const size = useElementRect(target, wait);
  const onChangeRef = React.useRef(onChange);

  React.useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  React.useEffect(() => {
    if (size) {
      onChangeRef.current(size.width, size.height);
    }
  }, [size]);
}

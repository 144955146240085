import {
  BlockType,
  ControlFlowFrontendDSL,
} from "store/slices/apisV2/control-flow/types";
import { getBlockGeneratedVariables } from "./block-generated-variables";
import {
  BlockPositionLocator,
  findBlockLocation,
  getParentsFromLocator,
  getPriorSiblingsFromBlock,
  getPriorSiblingsFromLocator,
} from "./locators";

function addParentVariables(
  state: ControlFlowFrontendDSL,
  variables: Set<string>,
  parent: string,
) {
  const block = state.blocks[parent];
  const blockVars = Object.values(getBlockGeneratedVariables(block));
  blockVars.forEach((v) => variables.add(v));
}

function addSiblingVariables(
  state: ControlFlowFrontendDSL,
  variables: Set<string>,
  sibling: string,
) {
  const block = state.blocks[sibling];
  if (block.type === BlockType.VARIABLES) {
    const blockVars = Object.values(getBlockGeneratedVariables(block));
    blockVars.forEach((v) => variables.add(v));
  }
}

/**
 * The function `getScopedBlockVariable` returns a set of variables that are scoped within a given
 * block and its parent blocks. This only includes control flow variables, not step variables/outputs.
 * @returns The function `getScopedBlockVariable` returns a `Set` of strings.
 */
function computeControlFlowVarsInScope(
  name: string,
  state: ControlFlowFrontendDSL,
): Set<string>;
function computeControlFlowVarsInScope(
  name: BlockPositionLocator | null,
  state: ControlFlowFrontendDSL,
): Set<string>;
function computeControlFlowVarsInScope(
  locatorOrName: BlockPositionLocator | null | string,
  state: ControlFlowFrontendDSL,
  locator = typeof locatorOrName === "string"
    ? findBlockLocation(state, locatorOrName)
    : locatorOrName,
): Set<string> {
  if (locator == null) return new Set();
  const variables = new Set<string>();
  const addSiblingVars = addSiblingVariables.bind(null, state, variables);

  // Add variables from direct siblings that run before this block
  getPriorSiblingsFromLocator(state, locator).forEach(addSiblingVars);

  // Look up the parent chain and add variables from each parent
  for (const parent of getParentsFromLocator(state, locator)) {
    addParentVariables(state, variables, parent);
    getPriorSiblingsFromBlock(state, parent).forEach(addSiblingVars);
  }
  return variables;
}

export default computeControlFlowVarsInScope;

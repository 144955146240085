import { produce, Immutable } from "immer";
import { Reducer } from "redux";
import { all } from "redux-saga/effects";

import {
  Action,
  ActionDefinition,
  ActionDefinitionWithMeta,
  PayloadAction,
  PayloadActionWithCallId,
  PayloadActionWithMeta,
} from "./action";
import { initializeSaga } from "./effects";
import {
  FullSagaDefinition,
  SagaActionMeta,
  SagaPayload,
  SagaResult,
} from "./saga";

type ImmerReducer<TState, TAction> = (state: TState, action: TAction) => void;

type ActionFilterFunc = (action: Action) => boolean;
type ActionFilter =
  | string
  | string[]
  | ActionDefinition<any>
  | ActionDefinitionWithMeta<any, any>
  | ActionFilterFunc;

type ActionFilterType<TFilter> =
  TFilter extends ActionDefinition<infer TPayload>
    ? PayloadAction<TPayload>
    : TFilter extends ActionDefinitionWithMeta<infer Payload, infer Meta>
      ? PayloadActionWithMeta<Payload, Meta>
      : any;

type ActionMatcher<TState> = {
  filter: ActionFilter;
  reducer: ImmerReducer<TState, Action>;
};

type SagaReducers<TState, TPayload, TResult> = {
  start?: (state: TState, payload: PayloadActionWithCallId<TPayload>) => void;
  success?: (
    state: TState,
    payload: PayloadActionWithMeta<TResult, SagaActionMeta<TPayload>>,
  ) => void;
  error?: (
    state: TState,
    payload: PayloadActionWithMeta<Error, SagaActionMeta<TPayload>>,
  ) => void;
  cancel?: (state: TState, payload: PayloadAction<TPayload>) => void;
  store?: (state: TState, payload: PayloadAction<TResult>) => void;
};

function matcherFilter<TState>(action: Action) {
  return (matcher: ActionMatcher<TState>) => {
    const byString =
      typeof matcher.filter === "string" && action.type === matcher.filter;

    const byStringArray =
      Array.isArray(matcher.filter) && matcher.filter.includes(action.type);

    const byDefinition =
      (matcher.filter as ActionDefinition<any>).type === action.type;

    const byFilter =
      typeof matcher.filter === "function" && matcher.filter(action);

    return byString || byStringArray || byDefinition || byFilter;
  };
}

export class Slice<TState> {
  private sagas: FullSagaDefinition<unknown, unknown>[];
  private matchers: ActionMatcher<TState>[];
  private sliceName: string;
  private initialState: Immutable<TState>;

  constructor(name: string, initialState: Immutable<TState>) {
    this.sliceName = name;
    this.matchers = [];
    this.sagas = [];
    this.initialState = initialState;
  }

  get name(): string {
    return this.sliceName;
  }

  get rootSaga(): () => Generator {
    const sagas = this.sagas;

    return function* watch() {
      yield all(sagas.map((saga) => initializeSaga(saga)));
    };
  }

  get rootReducer(): Reducer<Immutable<TState>, Action> {
    return (state: Immutable<TState> = this.initialState, action: Action) =>
      produce((state: TState, action: Action) => {
        this.matchers
          .filter(matcherFilter<TState>(action))
          .forEach((matcher) => matcher.reducer(state, action));
      })(state, action) as Immutable<TState>;
  }

  get selector() {
    return (state: Record<string, any>) => {
      return state[this.sliceName] as TState;
    };
  }

  reducer<
    TFilter extends ActionFilter,
    TAction extends ActionFilterType<TFilter>,
  >(filter: TFilter, reducer: ImmerReducer<TState, TAction>): void {
    this.matchers.push({
      filter,
      reducer: reducer as ImmerReducer<TState, Action>,
    });
  }

  saga<
    TDefinition extends FullSagaDefinition<any, any>,
    TPayload extends SagaPayload<TDefinition>,
    TResult extends SagaResult<TDefinition>,
  >(saga: TDefinition, reducers: SagaReducers<TState, TPayload, TResult>) {
    this.sagas.push(saga);

    if (reducers.start) {
      this.reducer(saga.triggers, reducers.start);
    }
    if (reducers.success) {
      this.reducer(saga.success, reducers.success);
    }
    if (reducers.error) {
      this.reducer(saga.error, reducers.error);
    }
    if (reducers.store) {
      this.reducer(saga.store, reducers.store);
    }
    if (reducers.cancel) {
      this.reducer(saga.cancel, reducers.cancel);
    }
  }
}

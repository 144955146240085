import { Dimension } from "@superblocksteam/shared";
import { WidgetAutoWidthUpdate } from "legacy/actions/pageActions";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { createImmerReducer } from "../createReducer";

export type DynamicProperties = {
  height?: Dimension<"px">;
  width?: Dimension<"px">;
};

export type DynamicWidgetsLayoutState = Record<string, DynamicProperties>;

export type SetEvaluatedWidgetLayoutPayload = {
  widgetId: string;
  props: DynamicProperties;
};

export type ClearEvaluatedWidgetLayoutPayload = {
  widgetId: string;
};

export interface DynamicLayoutState {
  widgets: DynamicWidgetsLayoutState;
}

const initialState: DynamicLayoutState = {
  widgets: {},
};

const dynamicLayoutReducer = createImmerReducer(initialState, (builder) =>
  builder
    .addCase(
      ReduxActionTypes.SET_DYNAMIC_WIDGET_PROPS,
      (
        state: DynamicLayoutState,
        action: ReduxAction<
          SetEvaluatedWidgetLayoutPayload,
          typeof ReduxActionTypes.SET_DYNAMIC_WIDGET_PROPS
        >,
      ): void => {
        const { widgetId, props } = action.payload;

        if (!state.widgets[widgetId]) {
          state.widgets[widgetId] = {};
        }
        Object.keys(props).forEach((key) => {
          state.widgets[widgetId][key as keyof DynamicProperties] =
            props[key as keyof DynamicProperties];
        });
      },
    )
    .addCase(
      ReduxActionTypes.CLEAR_DYNAMIC_WIDGET_HEIGHT,
      (
        state: DynamicLayoutState,
        action: ReduxAction<
          ClearEvaluatedWidgetLayoutPayload,
          typeof ReduxActionTypes.CLEAR_DYNAMIC_WIDGET_HEIGHT
        >,
      ): void => {
        delete state.widgets[action.payload.widgetId]?.height;
      },
    )
    .addCase(
      ReduxActionTypes.CLEAR_DYNAMIC_WIDGET_WIDTH,
      (
        state: DynamicLayoutState,
        action: ReduxAction<
          ClearEvaluatedWidgetLayoutPayload,
          typeof ReduxActionTypes.CLEAR_DYNAMIC_WIDGET_WIDTH
        >,
      ): void => {
        delete state.widgets[action.payload.widgetId]?.width;
      },
    )
    .addCase(
      ReduxActionTypes.WIDGET_UPDATE_AUTO_WIDTH,
      (
        state: DynamicLayoutState,
        action: ReduxAction<
          WidgetAutoWidthUpdate,
          typeof ReduxActionTypes.WIDGET_UPDATE_AUTO_WIDTH
        >,
      ): void => {
        state.widgets[action.payload.widgetId] = {
          ...state.widgets[action.payload.widgetId],
          width: Dimension.px(action.payload.widthInPx),
        };
      },
    )
    .addCase(ReduxActionTypes.RESET_WIDGETS, () => {
      return initialState;
    }),
);

export default dynamicLayoutReducer;

/**
 * The function `createVariableName` takes a base name and an array of existing names, and returns a
 * unique variable name by appending a number to the base name if necessary.
 * @param {string} baseName - A string representing the base name of the variable.
 * @param {string[]} existingNames - existingNames is an array of strings that contains the names of
 * variables that already exist.
 */
export const createVariableName = (
  baseName: string,
  existingNames: Set<string>,
): string => {
  const { newName } = createVariableNameInternal(baseName, existingNames);
  return newName;
};

/**
 * The function `createVariableNames` takes a list of base names and an array of existing names, and
 * returns a list of unique variable names by appending a number to the base name if necessary.
 * Note that it will choose a number that works for all of the base names, so if you pass in
 * ["a", "b"], ["a"] it will return ["a2", "b2"] instead of ["a2", "b1"].
 *
 */
export const createVariableNames = (
  baseNames: string[],
  existingNames: Set<string>,
): string[] => {
  const nameToNumb: Record<string, number> = {};
  baseNames.forEach((baseName) => {
    const { numb } = createVariableNameInternal(baseName, existingNames);
    nameToNumb[baseName] = numb;
  });

  const highestNumb = Math.max(...Object.values(nameToNumb));
  const names = baseNames.map((baseName) => {
    if (highestNumb === 0) {
      return baseName;
    }
    return `${baseName}${highestNumb + 1}`;
  });

  return names;
};

const createVariableNameInternal = (
  baseName: string,
  existingNames: Set<string>,
): { newName: string; numb: number } => {
  let name = baseName;

  // If the base name is not already in the list of existing names, return the base name.
  if (!existingNames.has(name)) {
    return { newName: name, numb: 0 };
  }

  // if the base name is already in the list of existing names, append a number to the base name
  let index = 1;
  while (existingNames.has(name)) {
    index += 1;
    name = `${baseName}${index}`;
  }
  return { newName: name, numb: index - 1 };
};

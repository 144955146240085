import {
  BreakControlBlock,
  BreakControl,
  BlockType,
} from "store/slices/apisV2/control-flow/types";
import { BLOCK_HELP_CONTENT } from "../../ControlFlow/common-block-type-info";
import {
  addBindingsToStringValue,
  removeBindingsFromStringValue,
} from "../../ControlFlow/utils";
import { BaseTemplate, BaseTemplateField } from "./BaseTemplate";
import {
  ControlFlowFormComponentType,
  ControlFlowFormItem,
} from "./FormTemplateTypes";

export default class BreakTemplate extends BaseTemplate<BreakControlBlock> {
  private getConfig(): Readonly<BreakControl> {
    return this.source.config;
  }

  conditionField(): BaseTemplateField<BreakControlBlock, string> {
    const currentConfig = this.getConfig();
    const fieldValue = removeBindingsFromStringValue(currentConfig.condition);

    const updateValue = (block: BreakControlBlock, newValue: string) => {
      block.config.condition = addBindingsToStringValue(newValue);
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "If condition",
      componentType: ControlFlowFormComponentType.EXPRESSION_EDITOR,
      subHeading: "Break out of a loop if the above condition is true",
      expectedValue: "boolean",
      controlFlowTooltip:
        BLOCK_HELP_CONTENT[BlockType.BREAK].fieldHelpContent.ifCondition,
    };

    return {
      formItem,
      fieldValue,
      updateValue,
    };
  }

  computeFields() {
    return {
      condition: this.conditionField(),
    };
  }
}

import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const LaunchDarklyPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'LaunchDarkly',
  id: ExtendedIntegrationPluginId.LAUNCHDARKLY,
  authMethods: {
    // Ideally this would use an empty tokenPrefix, but that's treated as no
    // prefix and Bearer use used as the prefix.
    apiKeyForm: [{ label: 'Token', header: 'Authorization', key: 'token' }]
  },
  serverURL: 'https://app.launchdarkly.com'
});

import { normalize, denormalize, PageDSL8 } from "@superblocksteam/shared";
import type { WidgetMap } from "legacy/widgets";

class CanvasWidgetsNormalizer {
  static normalize(dsl?: PageDSL8): {
    entities: {
      canvasWidgets: WidgetMap;
    };
    result: string;
  } {
    return normalize<WidgetMap>(dsl);
  }

  static denormalize(
    pageWidgetId: string,
    entities: { canvasWidgets: WidgetMap },
  ): PageDSL8 {
    return denormalize<WidgetMap>(pageWidgetId, entities);
  }
}

export default CanvasWidgetsNormalizer;

import { ERROR_CODES } from "legacy/constants/ApiConstants";
import {
  ReduxAction,
  ReduxActionTypes,
  ReduxActionErrorPayload,
} from "legacy/constants/ReduxActionConstants";
import { createImmerReducer } from "../createReducer";

const initialState: ErrorReduxState = {
  safeCrash: false,
  safeCrashCode: undefined,
  safeCrashReason: undefined,
  currentError: { sourceAction: "", message: "" },
};

const errorReducer = createImmerReducer(initialState, (builder) =>
  builder
    .addCase(
      ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS,
      (
        state: ErrorReduxState,
        action: ReduxAction<
          ReduxActionErrorPayload,
          typeof ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS
        >,
      ) => ({
        ...state,
        safeCrash: true,
        safeCrashCode: action.payload.code,
        safeCrashReason: action.payload.reason,
      }),
    )
    .addCase(
      ReduxActionTypes.REPORT_ERROR,
      (
        state: ErrorReduxState,
        action: ReduxAction<
          ReduxActionErrorPayload,
          typeof ReduxActionTypes.REPORT_ERROR
        >,
      ) => {
        return {
          ...state,
          currentError: {
            sourceAction: action.payload.source,
            message: action.payload.message,
          },
        };
      },
    )
    .addCase(ReduxActionTypes.FLUSH_ERRORS, () => initialState),
);

export interface ErrorReduxState {
  safeCrash: boolean;
  safeCrashCode?: ERROR_CODES;
  safeCrashReason?: SafeCrashReason;
  currentError: {
    sourceAction?: string;
    message?: string;
  };
}

export enum SafeCrashReason {
  BRANCH_NOT_FOUND = "branchNotFound",
}

export default errorReducer;

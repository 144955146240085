import {
  findBlockLocation,
  getPriorSiblingsFromLocator,
} from "store/slices/apisV2/control-flow/locators";
import {
  ControlFlowFrontendDSL,
  GenericBlock,
  ParallelControlBlock,
  BlockType,
} from "store/slices/apisV2/control-flow/types";

const computeParallelBlocksInScopeFromUpstreamSiblingBlocks = (
  blockName: string,
  state: ControlFlowFrontendDSL,
): ParallelControlBlock["name"][] => {
  // Find all siblings of this block that are upsteam and get their vars
  // Start from index 0 of the parent block's children to ensure correct overrwrite order

  const blockLocation = findBlockLocation(state, blockName);
  if (!blockLocation) {
    throw new Error(
      `Cannot find location of block ${blockName} from DSL ${JSON.stringify(
        state,
      )}`,
    );
  }
  const upstreamSiblingBlocks = getPriorSiblingsFromLocator(
    state,
    blockLocation,
  );

  const upsteamParallelBlocks = upstreamSiblingBlocks.filter(
    (siblingBlockId) => {
      const block = state.blocks[siblingBlockId];
      return block.type === BlockType.PARALLEL;
    },
  );

  return upsteamParallelBlocks;
};

const computeParallelBlocksInScopeFromParentBlock = (
  blockName: GenericBlock["name"],
  state: ControlFlowFrontendDSL,
): ParallelControlBlock["name"][] => {
  const block = state.blocks[blockName];
  if (!block.parentId) {
    throw new Error(
      `Cannot find parent of block ${blockName} from DSL ${JSON.stringify(
        state,
      )}`,
    );
  }
  const parentBlock = state.blocks[block.parentId];

  const blockLocation = findBlockLocation(state, block.name);
  if (!blockLocation) {
    throw new Error(
      `Cannot find location of block ${block.name} from DSL ${JSON.stringify(
        state,
      )}`,
    );
  }

  if (parentBlock.type === BlockType.PARALLEL) {
    return [parentBlock.name];
  }

  return [];
};

export const computeParallelBlocksInScope = (
  blockName: string,
  state: ControlFlowFrontendDSL,
): ParallelControlBlock["name"][] => {
  let blocksInScope: ParallelControlBlock["name"][] = [];

  // Start by traversing up the tree and getting all vars from upstream siblings in the
  // same parent block. Vars with the same name already defined will not get added because
  // they are overridden in the more specific scope farther down the scope chain
  let continueInScopeChain = true;
  let currentBlockName = blockName;

  while (continueInScopeChain) {
    // Get parallel blocks with the same parent that are upstream of this block
    blocksInScope = [
      ...blocksInScope,
      ...computeParallelBlocksInScopeFromUpstreamSiblingBlocks(
        currentBlockName,
        state,
      ),
    ];

    // Now get parallels in scope from parent blocks
    // If there is none, that means we've reached the root and have already
    // added upstream siblings, so we can stop
    const currentBlock = state.blocks[currentBlockName];
    if (currentBlock.parentId) {
      // Now get the parent's vars
      blocksInScope = [
        ...blocksInScope,
        ...computeParallelBlocksInScopeFromParentBlock(currentBlockName, state),
      ];

      currentBlockName = currentBlock.parentId;
    } else {
      continueInScopeChain = false;
    }
  }

  return blocksInScope;
};

import WebSocket from 'isomorphic-ws';

export function connectWebSocket(wsUrl: string): Promise<WebSocket> {
  return new Promise((resolve, reject) => {
    const ws = new WebSocket(wsUrl);

    ws.addEventListener('open', () => {
      // Resolve the promise with the WebSocket instance when the connection is open
      resolve(ws);
    });

    ws.addEventListener('error', (error) => {
      // Reject the promise if there's an error
      reject(error);
    });
  });
}

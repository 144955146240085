import {
  VariableMode,
  VariableType,
} from "@superblocksteam/shared/src/types/api/control-flow";
import {
  VariablesControlBlock,
  VariableEntry,
  VariablesControl,
} from "store/slices/apisV2/control-flow/types";
import {
  makeStringValidJsVarName,
  addBindingsToStringValue,
  removeBindingsFromStringValue,
} from "../../ControlFlow/utils";
import { BaseTemplate, BaseTemplateField } from "./BaseTemplate";
import {
  ControlFlowFormComponentType,
  ControlFlowFormItem,
} from "./FormTemplateTypes";

const VARIABLE_DATA_FORMAT = "any";

export default class VariablesTemplate extends BaseTemplate<VariablesControlBlock> {
  private getConfig(): Readonly<VariablesControl> {
    return this.source.config;
  }

  variablesField(): BaseTemplateField<
    VariablesControlBlock,
    Partial<VariableEntry>[]
  > {
    const currentConfig = this.getConfig();
    const fieldValue = currentConfig.variables.map(
      (variablesEntry: VariableEntry) => {
        return {
          ...variablesEntry,
          value: removeBindingsFromStringValue(variablesEntry.value || ""),
        };
      },
    );

    const updateValue = (
      block: VariablesControlBlock,
      newValue: Partial<VariableEntry>[],
    ) => {
      block.config.variables = newValue.map(
        (variablesEntry: Partial<VariableEntry>) => {
          const newEntry: VariableEntry = {
            key: makeStringValidJsVarName(variablesEntry.key),
            value: addBindingsToStringValue(variablesEntry.value ?? ""),
            type: variablesEntry.type ?? VariableType.SIMPLE,
            mode: VariableMode.READWRITE,
          };
          return newEntry;
        },
      );
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Variables",
      addLabel: "Add variable",
      componentType: ControlFlowFormComponentType.VARIABLES_LIST,
      expectedValue: VARIABLE_DATA_FORMAT,
    };

    return {
      formItem,
      fieldValue,
      updateValue,
    };
  }

  computeFields() {
    return {
      variables: this.variablesField(),
    };
  }
}

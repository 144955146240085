class LoginState {
  private static _instance: LoginState;

  // this is used by Google Tag Manager analytics, to check if the user load the page right after login
  public newlyLoggedIn = false;

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }
}

export default LoginState.Instance;

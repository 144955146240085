import {
  RepositoryDto,
  RepositoryWithEntitiesDto,
} from "@superblocksteam/shared";
import { callServer, HttpMethod } from "../../utils/client";

export function getRepositories() {
  return callServer<RepositoryWithEntitiesDto[]>({
    method: HttpMethod.Get,
    url: "/v1/repositories",
  });
}

export function refetchRepository(id: string) {
  return callServer<RepositoryDto>({
    method: HttpMethod.Put,
    url: `/v1/repositories/${id}`,
  });
}

export function deleteRepository(id: string) {
  return callServer<unknown>({
    method: HttpMethod.Delete,
    url: `/v1/repositories/${id}`,
  });
}

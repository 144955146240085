import {
  CustomComponentProperty,
  PreviousCustomComponentConfigs,
  RegisteredComponents,
} from "@superblocksteam/shared";
import semver from "semver";
import { fastClone } from "utils/clone";

// These migrations are used to upgrade the Superblocks config files as needed.
// For example if we add a new required field
const CustomComponentConfigMigrator: Array<{
  migrateWhenPreviousVersionLt: string;
  migrator: (config: Record<string, any>) => RegisteredComponents;
}> = [
  {
    migrateWhenPreviousVersionLt: "0.0.20",
    migrator: (config) => config,
  },
  {
    migrateWhenPreviousVersionLt: "0.0.21",
    migrator: (config) => {
      const newConfigs: any = fastClone(config);

      // Mutate each component's definition
      Object.values(newConfigs).forEach((newConfig) => {
        (newConfig as any).properties = (
          newConfig as PreviousCustomComponentConfigs["0.0.20"]
        ).statefulProperties.map((property) => {
          let newDataType: CustomComponentProperty["dataType"];
          if (property.inputType === "text") {
            newDataType = "string";
          } else if (property.inputType === "js") {
            newDataType = "any";
          } else {
            newDataType = property.inputType;
          }
          return {
            path: property.path,
            dataType: newDataType,
            propertiesPanelDisplay: {
              label: property.label,
              controlType: property.inputType === "js" ? "js-expr" : "text",
              placeholder: property.placeholder,
            },
          } as CustomComponentProperty;
        });
        delete (newConfig as any).statefulProperties;

        (newConfig as any).events = (newConfig as any).eventHandlers;
        delete (newConfig as any).eventHandlers;
      });

      return newConfigs;
    },
  },
];

// Recursively migrate configs to the latest version
export function migrateCustomComponentsConfig(
  config: any,
  options: {
    previousVersion: string;
  },
): RegisteredComponents {
  return sharedMigrator(CustomComponentConfigMigrator, config, options);
}

function sharedMigrator(
  migratorObject: typeof CustomComponentConfigMigrator,
  config: RegisteredComponents,
  {
    previousVersion,
  }: {
    previousVersion: string;
  },
) {
  let newConfig: any = JSON.parse(JSON.stringify(config));

  // Find all migrators in the range between previousVersion and currentVersion
  const migratorFns = migratorObject.filter(
    ({ migrateWhenPreviousVersionLt }) =>
      semver.lt(previousVersion, migrateWhenPreviousVersionLt),
  );

  migratorFns.forEach(
    ({ migrator, migrateWhenPreviousVersionLt: migrateWhenUpgradingTo }) => {
      try {
        newConfig = migrator(newConfig);
      } catch (e: any) {
        // Migrations fail if our preconditions aren't met
        console.warn(
          `Error while migrating config to version ${migrateWhenUpgradingTo}: ${e.message}`,
        );
        throw e;
      }
    },
  );
  return newConfig;
}

import {
  TryCatchControlBlock,
  TryCatchControl,
  BlockType,
} from "store/slices/apisV2/control-flow/types";
import { BLOCK_HELP_CONTENT } from "../../ControlFlow/common-block-type-info";
import { makeStringValidJsVarName } from "../../ControlFlow/utils";
import { BaseTemplate, BaseTemplateField } from "./BaseTemplate";
import {
  ControlFlowFormComponentType,
  ControlFlowFormItem,
  FormComponentType,
} from "./FormTemplateTypes";

export default class TryCatchTemplate extends BaseTemplate<TryCatchControlBlock> {
  private getConfig(): Readonly<TryCatchControl> {
    return this.source.config;
  }

  variablesField(
    include?: string[],
  ): BaseTemplateField<TryCatchControlBlock, Record<string, string>> {
    const currentConfig = this.getConfig();
    const fieldValue = currentConfig.variables;

    const updateValue = (
      block: TryCatchControlBlock,
      newValue: Record<string, string>,
    ) => {
      const newVars: Record<string, string> = {};
      Object.keys(newValue).forEach((key) => {
        newVars[key] = makeStringValidJsVarName(newValue[key]);
      });
      block.config.variables = newVars as TryCatchControl["variables"];
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Error variable",
      componentType: ControlFlowFormComponentType.LOCAL_VARIABLES_LIST,
    };

    return {
      formItem,
      fieldValue,
      updateValue,
    };
  }

  enableFinallyField(): BaseTemplateField<TryCatchControlBlock, boolean> {
    const currentConfig = this.getConfig();
    const fieldValue = Boolean(currentConfig.finally);

    const updateValue = (block: TryCatchControlBlock, newValue: boolean) => {
      block.config.finally =
        newValue === false ? undefined : currentConfig.finally || [];
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Enable finally",
      componentType: FormComponentType.SWITCH,
      showConfirm:
        currentConfig.finally != null && currentConfig.finally.length > 0,
      controlFlowTooltip:
        BLOCK_HELP_CONTENT[BlockType.TRY_CATCH].fieldHelpContent.showFinally,
      confirmTitle: "Disabling finally will delete all of its blocks.",
    };

    return {
      formItem,
      fieldValue,
      updateValue,
    };
  }

  computeFields() {
    return {
      variables: this.variablesField(),
      enableFinally: this.enableFinallyField(),
    };
  }
}

import { ExtendedIntegrationPluginId, Plugin, SUPERBLOCKS_OPENAPI_TENANT_KEYWORD } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const JiraPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Jira Cloud',
  id: ExtendedIntegrationPluginId.JIRA,
  authMethods: {
    basic: { password: { label: 'API token', placeholder: 'my-api-token' } }
  },
  serverURL: `https://${SUPERBLOCKS_OPENAPI_TENANT_KEYWORD}.atlassian.net`,
  tenantInput: {
    label: 'Jira domain',
    placeholder: 'your-domain',
    subtitle: 'Your organization domain (e.g. https://<your-domain>.atlassian.net)'
  }
});

import { createAction } from "@reduxjs/toolkit";
import { ApplicationScope } from "@superblocksteam/shared";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";

export const updatePropertyPaneWidthAction = createAction(
  "UPDATE_PROPERTY_PANE_WIDTH",
  (width?: number) => {
    return {
      payload: {
        width,
      },
    };
  },
);

export const showItemPropertyPane = createAction(
  "SHOW_PROPERTY_PANE",
  (
    item?: { kind: ItemKinds; id: string; scope: ApplicationScope },
    isVisible?: boolean,
  ) => ({
    payload: { item, isVisible },
  }),
);

export const updateHoveredPropertyInPropertyPane = createAction(
  "UPDATE_PROPERTY_PANE_HOVERED_PROPERTY",
  (hoveredPropertyPath?: string) => {
    return {
      payload: {
        hoveredPropertyPath,
      },
    };
  },
);

export const updateActivePropertyInPropertyPane = createAction(
  "UPDATE_PROPERTY_PANE_ACTIVE_PROPERTY",
  (activePropertyPath?: string) => {
    return {
      payload: {
        activePropertyPath,
      },
    };
  },
);

import { ApiInfo } from "legacy/constants/ApiConstants";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { getStore } from "store/dynamic";
import { addNewPromise } from "store/utils/resolveIdSingleton";
import logger from "utils/logger";

const MAX_QUEUE_LENGTH = 50 * 1024;
/**
 * The `StreamDispatcher` function handles dispatching messages from a stream, allowing for queuing and
 * blocking when necessary.
 */
const StreamDispatcher = (apiId: string, apiInfo: ApiInfo) => {
  const store = getStore();
  const queue: Array<any> = [];
  let isBlocked = false;

  const dispatchEventHandlers = (message: any) => {
    isBlocked = true;
    store.dispatch({
      type: ReduxActionTypes.HANDLE_STREAM_MESSAGE,
      payload: {
        onMessage: apiInfo.onMessage ?? [],
        apiId,
        callbackId,
        message,
      },
    });
  };

  const callbackId = addNewPromise(() => {
    if (queue.length) {
      const message = queue.shift();
      dispatchEventHandlers(message);
    } else {
      isBlocked = false;
    }
  }, true);

  const addMessage = (message: any) => {
    if (!isBlocked) {
      dispatchEventHandlers(message);
    } else if (queue.length < MAX_QUEUE_LENGTH) {
      queue.push(message);
    } else {
      logger.warn(
        `Message queue length exceeded, dropping message: ${JSON.stringify(
          message,
        )}`,
      );
    }
  };

  return {
    addMessage,
  };
};

export default StreamDispatcher;

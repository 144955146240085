import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const DatadogPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Datadog',
  id: ExtendedIntegrationPluginId.DATADOG,
  authMethods: {
    apiKeyForm: [
      { label: 'API key', header: 'DD-API-KEY', key: 'api-key' },
      { label: 'Application key', header: 'DD-APPLICATION-KEY', key: 'app-key' }
    ]
  },
  serverURL: 'https://api.datadoghq.com'
});

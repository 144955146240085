import { Slice } from "store/utils/slice";
import { EntitiesStateWithMeta } from "store/utils/types";
import type { DeploymentDto } from "@superblocksteam/shared";

type DeploymentsState = EntitiesStateWithMeta<DeploymentDto, any>;

export default new Slice<DeploymentsState>("deployments", {
  entities: {},
  loading: {},
  errors: {},
  meta: {},
});

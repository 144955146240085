import { WidgetType } from "legacy/constants/WidgetConstants";

const WIDGET_DOC_URLS: Record<WidgetType, string> = {
  BUTTON_WIDGET:
    "https://docs.superblocks.com/applications/components-library/button",
  TEXT_WIDGET:
    "https://docs.superblocks.com/applications/components-library/text",
  IMAGE_WIDGET:
    "https://docs.superblocks.com/applications/components-library/image",
  INPUT_WIDGET:
    "https://docs.superblocks.com/applications/components-library/input",
  CONTAINER_WIDGET:
    "https://docs.superblocks.com/applications/components-library/container",
  DATE_PICKER_WIDGET:
    "https://docs.superblocks.com/applications/components-library/datepicker",
  TABLE_WIDGET:
    "https://docs.superblocks.com/applications/components-library/table",
  DROP_DOWN_WIDGET:
    "https://docs.superblocks.com/applications/components-library/dropdown",
  CHECKBOX_WIDGET:
    "https://docs.superblocks.com/applications/components-library/checkbox",
  RADIO_GROUP_WIDGET:
    "https://docs.superblocks.com/applications/components-library/radio-button",
  TABS_WIDGET:
    "https://docs.superblocks.com/applications/components-library/tabs",
  CHART_WIDGET:
    "https://docs.superblocks.com/applications/components-library/charts",
  MAP_WIDGET:
    "https://docs.superblocks.com/applications/components-library/map",
  FILE_PICKER_WIDGET:
    "https://docs.superblocks.com/applications/components-library/file-picker",
  VIDEO_WIDGET:
    "https://docs.superblocks.com/applications/components-library/video",
  GRID_WIDGET:
    "https://docs.superblocks.com/applications/components-library/grids",
  CODE_WIDGET:
    "https://docs.superblocks.com/applications/components-library/code-editor",
  PDF_VIEWER_WIDGET:
    "https://docs.superblocks.com/applications/components-library/pdf-viewer",
  IFRAME_WIDGET:
    "https://docs.superblocks.com/applications/components-library/iframe",
  DIFF_WIDGET:
    "https://docs.superblocks.com/applications/components-library/diff-viewer",
  MODAL_WIDGET:
    "https://docs.superblocks.com/applications/components-library/modal",
  SLIDEOUT_WIDGET:
    "https://docs.superblocks.com/applications/components-library/slideout",
  RICH_TEXT_EDITOR_WIDGET:
    "https://docs.superblocks.com/applications/components-library/rich-text-editor",
  FORM_WIDGET:
    "https://docs.superblocks.com/applications/components-library/form",
  FORM_BUTTON_WIDGET:
    "https://docs.superblocks.com/applications/components-library/form#form-buttons",
  CANVAS_WIDGET: "",
  PAGE_WIDGET: "https://docs.superblocks.com/applications/multi-page/pages",
  SECTION_WIDGET: "",
  ICON_WIDGET:
    "https://docs.superblocks.com/applications/components-library/icon",
  SKELETON_WIDGET: "",
  CHAT_WIDGET:
    "https://docs.superblocks.com/applications/components-library/chat",
  CUSTOM_WIDGET: "",
  UNREGISTERED_CUSTOM_WIDGET: "",
  SWITCH_WIDGET:
    "https://docs.superblocks.com/applications/components-library/switch",
  CALLOUT_WIDGET:
    "https://docs.superblocks.com/applications/components-library/callout",
  MENU_WIDGET:
    "https://docs.superblocks.com/applications/components-library/menu",
  LINK_WIDGET:
    "https://docs.superblocks.com/applications/components-library/link",
};

export default WIDGET_DOC_URLS;

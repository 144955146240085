import { ThemeMode, Typographies } from "@superblocksteam/shared";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import {
  BASE_THEME_EXISTING_APPS,
  CURRENT_THEME_VERSION,
} from "legacy/themes/constants";
import { generateTheme } from "legacy/themes/generateTheme";
import { GeneratedTheme } from "legacy/themes/types";
import {
  getDefaultTypographies,
  getThemeWithVersion,
} from "legacy/themes/utils";
import { fetchApplicationSuccess } from "store/slices/application/applicationActions";
import { createImmerReducer } from "../createReducer";

export interface ThemeState {
  generatedTheme: GeneratedTheme;
  storedTypographies?: Typographies;
  overrideThemeMode?: ThemeMode; // This can be set to override the theme mode for APP THEMING
}

const initialState: ThemeState = {
  generatedTheme: generateTheme(BASE_THEME_EXISTING_APPS),
  storedTypographies: getDefaultTypographies(CURRENT_THEME_VERSION),
  overrideThemeMode: undefined,
};

const themeReducer = createImmerReducer(initialState, (builder) =>
  builder
    .addCase(
      ReduxActionTypes.UPDATE_GENERATED_THEME,
      (
        draftState: ThemeState,
        action: ReduxAction<
          { generatedTheme: GeneratedTheme; storedTypographies?: Typographies },
          typeof ReduxActionTypes.UPDATE_GENERATED_THEME
        >,
      ) => {
        return {
          ...draftState,
          generatedTheme: action.payload.generatedTheme,
          storedTypographies: action.payload.storedTypographies,
        };
      },
    )
    .addCase(
      ReduxActionTypes.SET_OVERRIDE_THEME_MODE,
      (
        draftState: ThemeState,
        action: ReduxAction<
          { mode?: ThemeMode },
          typeof ReduxActionTypes.SET_OVERRIDE_THEME_MODE
        >,
      ) => {
        return {
          ...draftState,
          overrideThemeMode: action.payload.mode,
        };
      },
    )
    .addCase(fetchApplicationSuccess, (draftState, action) => {
      const overrideMode = draftState.overrideThemeMode;

      return {
        ...draftState,
        storedTypographies: action.payload.settings?.theme?.typographies,
        generatedTheme: generateTheme(
          getThemeWithVersion(action.payload.settings?.theme),
          overrideMode,
        ),
      };
    }),
);

export default themeReducer;

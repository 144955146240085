import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const IntercomPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Intercom',
  id: ExtendedIntegrationPluginId.INTERCOM,
  authMethods: {
    bearer: true
  },
  serverURL: 'https://api.intercom.io'
});

import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const FrontPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Front',
  id: ExtendedIntegrationPluginId.FRONT,
  authMethods: {
    bearer: true
  },
  serverURL: 'https://api2.frontapp.com'
});

import { PerSideBorder } from "@superblocksteam/shared";

export const BorderUtils = {
  x: (border: PerSideBorder | undefined) => {
    return (
      (border?.left?.width?.value ?? 0) + (border?.right?.width?.value ?? 0)
    );
  },
  y: (border: PerSideBorder | undefined) => {
    return (
      (border?.top?.width?.value ?? 0) + (border?.bottom?.width?.value ?? 0)
    );
  },
};

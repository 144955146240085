export const CODE_SYMBOL = "`";

export const HANDLING_STREAMING_MESSAGES = `
## Handling Messages
Messages produced in Streaming APIs are sent to the client.

### In Apps
In Apps, the client is the App UI.

**Stream Response Type**

In order to handle the messages sent in the API:
1. Ensure your API response type is set to **Stream**
2. Use the ${CODE_SYMBOL}onMessage${CODE_SYMBOL} event handler to process each message

**Streaming Data to the App UI**

In order to display streaming data in your UI components
1. In your ${CODE_SYMBOL}onMessage${CODE_SYMBOL} event handler, write the messages to a variable in the format you wish to display
2. Bind your UI component to the variable

**Hint:** A common use case is to set the value of the variable to a list of all messages that is built up as the API runs.

Learn more about Streaming data to Applications [here](https://docs.superblocks.com/applications/streaming-applications/overview#stream-data-to-the-frontend)

### In Workflows
In Workflows, this client is the machine making the request. When invoking a Streaming API as a workflow using HTTP/2, messages will be streamed back to the client.
`;

import { createReducer } from "@reduxjs/toolkit";
import { OpenPricingModalAction } from "legacy/actions/modalActions";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";

export interface ModalState {
  showQualificationModal: string | null;
  showPricingModal: boolean;
  showPurchasedModal: string | null;
  showDowngradeModal: boolean;
  autoLoaded: boolean;
}

const initialState: ModalState = {
  showQualificationModal: null,
  showPricingModal: false,
  showPurchasedModal: null,
  showDowngradeModal: false,
  autoLoaded: false,
};

const modalReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(
      ReduxActionTypes.OPEN_QUALIFICATION_MODAL,
      (state: ModalState) => ({
        ...state,
        showQualificationModal: "UPGRADE",
      }),
    )
    .addCase(
      ReduxActionTypes.OPEN_TRIAL_EXTENSION_MODAL,
      (state: ModalState) => ({
        ...state,
        showQualificationModal: "TRIAL",
      }),
    )
    .addCase(
      ReduxActionTypes.CLOSE_QUALIFICATION_MODAL,
      (state: ModalState) => ({
        ...state,
        showQualificationModal: null,
      }),
    )
    .addCase(
      ReduxActionTypes.OPEN_PRICING_MODAL,
      (
        state: ModalState,
        action: ReduxAction<
          OpenPricingModalAction,
          typeof ReduxActionTypes.OPEN_PRICING_MODAL
        >,
      ) => ({
        ...state,
        ...action.payload,
        showPricingModal: true,
      }),
    )
    .addCase(ReduxActionTypes.CLOSE_PRICING_MODAL, (state: ModalState) => ({
      ...state,
      showPricingModal: false,
    }))
    .addCase(
      ReduxActionTypes.OPEN_PURCHASED_MODAL,
      (
        state: ModalState,
        action: ReduxAction<
          { planName: string },
          typeof ReduxActionTypes.OPEN_PURCHASED_MODAL
        >,
      ) => ({
        ...state,
        showPurchasedModal: action.payload.planName,
      }),
    )
    .addCase(ReduxActionTypes.CLOSE_PURCHASED_MODAL, (state: ModalState) => ({
      ...state,
      showPurchasedModal: null,
    }))
    .addCase(ReduxActionTypes.OPEN_DOWNGRADE_MODAL, (state: ModalState) => ({
      ...state,
      showDowngradeModal: true,
    }))
    .addCase(ReduxActionTypes.CLOSE_DOWNGRADE_MODAL, (state: ModalState) => ({
      ...state,
      showDowngradeModal: false,
    })),
);

export default modalReducer;

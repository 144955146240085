import {
  ReduxActionTypes,
  ReduxAction,
} from "legacy/constants/ReduxActionConstants";
import { Slice } from "store/utils/slice";
import { EntitiesStateWithMeta, ROOT } from "store/utils/types";
import { defaults, Flags, Flag } from "./models/Flags";

type FlagsMeta = {
  organizationId?: string;
};

export type FlagsState = EntitiesStateWithMeta<Flags, FlagsMeta>;

const slice = new Slice<FlagsState>("featureFlags", {
  entities: { [ROOT]: defaults },
  loading: {},
  errors: {},
  meta: {},
});

slice.reducer(
  ReduxActionTypes.UPDATE_FEATURE_FLAG,
  (state: FlagsState, action: ReduxAction<{ flagName: Flag; value: any }>) => {
    const { flagName, value } = action.payload;
    state.entities[ROOT][flagName] = value;
  },
);

export default slice;

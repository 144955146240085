import {
  runEventHandlers,
  runEventHandlersSuccess,
  runEventHandlersError,
} from "legacy/actions/widgetActions";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { createImmerReducer } from "../createReducer";

export interface GlobalState {
  preventNavigation: boolean;
  activeEventHandlers: number;
}

const initialState: GlobalState = {
  preventNavigation: false, //this is used in tests to indicate if a reload confirm box will show when user navigating away from page
  activeEventHandlers: 0,
};

const globalReducer = createImmerReducer(initialState, (builder) =>
  builder
    .addCase(
      ReduxActionTypes.SET_PREVENT_NAVIGATION,
      (
        state: GlobalState,
        action: ReduxAction<
          { preventNavigation: boolean },
          typeof ReduxActionTypes.SET_PREVENT_NAVIGATION
        >,
      ) => {
        state.preventNavigation = action.payload.preventNavigation;
      },
    )
    .addCase(runEventHandlers, (state: GlobalState) => {
      state.activeEventHandlers++;
    })
    .addCase(runEventHandlersSuccess, (state: GlobalState) => {
      state.activeEventHandlers--;
    })
    .addCase(runEventHandlersError, (state: GlobalState) => {
      state.activeEventHandlers--;
    }),
);

export default globalReducer;

import * as BackendTypes from "../backend-types";
import { convertBlockToBackend } from "./convert-block-to-backend";
import { ControlFlowFrontendDSL } from "./types";

export function getBlockFromApi(
  controlFlow: ControlFlowFrontendDSL,
  blockName: string,
) {
  const processBlock = (blockName: string): BackendTypes.Block => {
    const block = controlFlow.blocks[blockName];
    if (block == null) {
      throw new Error("block not found");
    }
    return convertBlockToBackend(block, (name: string) => processBlock(name));
  };

  return processBlock(blockName);
}

// Perf: https://jsbench.me/g2lcs2r4xj/1
function omit<T, K1 extends keyof T>(obj: T, key1: K1): Omit<T, K1>;
function omit<T, K1 extends keyof T, K2 extends keyof T>(
  obj: T,
  key1: K1,
  key2: K2,
): Omit<T, K1 | K2>;
function omit<T, K1 extends keyof T, K2 extends keyof T, K3 extends keyof T>(
  obj: T,
  key1: K1,
  key2: K2,
  key3: K3,
): Omit<T, K1 | K2 | K3>;
function omit<
  T,
  K1 extends keyof T,
  K2 extends keyof T,
  K3 extends keyof T,
  K4 extends keyof T,
>(obj: T, key1: K1, key2: K2, key3: K3, key4: K4): Omit<T, K1 | K2 | K3 | K4>;
function omit<
  T,
  K1 extends keyof T,
  K2 extends keyof T,
  K3 extends keyof T,
  K4 extends keyof T,
>(
  obj: T,
  key1: K1,
  key2: K2 | undefined = undefined,
  key3: K3 | undefined = undefined,
  key4: K4 | undefined = undefined,
):
  | Omit<T, K1 | K2 | K3 | K4>
  | Omit<T, K1 | K2 | K3>
  | Omit<T, K1 | K2>
  | Omit<T, K1> {
  const target: Partial<T> = {};
  for (const _k in obj) {
    const k = _k as keyof T;
    if (k === key1 || k === key2 || k === key3 || k === key4) continue;
    target[_k] = obj[_k];
  }

  return target as any;
}

export default omit;

import { ApplicationScope } from "@superblocksteam/shared";
import {
  emptyDataTree,
  getDataEntities,
} from "legacy/entities/DataTree/DataTreeHelpers";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";

export type ScopedFakeDataTree = {
  [ApplicationScope.GLOBAL]: Record<string, unknown>;
  [ApplicationScope.APP]: Record<string, unknown>;
  [ApplicationScope.PAGE]: Record<string, unknown>;
};

/**
 * This function converts a dataTree to only it's top level entities.
 * We do this in order to extract entities for renaming. When renaming,
 * we do not care about paths inside of top level entities so this is
 * sufficient.
 */
export function createFakeDataTree(dataTree: DataTree): ScopedFakeDataTree {
  const output: ScopedFakeDataTree = emptyDataTree();
  getDataEntities(dataTree).forEach(({ location, entity }) => {
    if (typeof entity === "object" && "ENTITY_TYPE" in entity) {
      output[location.scope][location.name] = {};
    }
  });
  // special case for embed, since this is namespaced
  Object.entries(dataTree.PAGE.Embed ?? {}).forEach(([key, value]) => {
    if (key !== "ENTITY_TYPE") output[ApplicationScope.PAGE][key] = {};
  });
  return output;
}

export {
  refactorNameInCode,
  refactorNameInString,
  refactorNameInValue,
} from "@superblocksteam/shared";

import {
  restartEvaluation,
  stopEvaluation,
} from "legacy/actions/evaluationActions";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { createImmerReducer } from "../createReducer";

export interface LifecycleState {
  lastStartTime: Date | null;
  lastCompleteTime: Date | null;
  isNextCycleQueued: boolean;
  startedPageLoadApis: boolean;
}

const initialState: LifecycleState = {
  lastStartTime: null,
  lastCompleteTime: null,
  isNextCycleQueued: false,
  startedPageLoadApis: false,
};

const lifecycleReducer = createImmerReducer(initialState, (builder) =>
  builder
    .addCase(stopEvaluation, () => initialState)
    .addCase(restartEvaluation, () => initialState)
    .addCase(ReduxActionTypes.START_EVALUATE_TREE, (state) => {
      return {
        ...state,
        lastStartTime: new Date(),
        isNextCycleQueued: false,
      };
    })
    .addCase(ReduxActionTypes.SET_EVALUATED_TREE, (state) => {
      return {
        ...state,
        lastCompleteTime: new Date(),
      };
    })
    .addCase(ReduxActionTypes.START_EVALUATE_QUEUE, (state) => {
      return {
        ...state,
        isNextCycleQueued: true,
      };
    })
    .addCase(ReduxActionTypes.STARTED_PAGE_LOAD_APIS, (state) => {
      return {
        ...state,
        startedPageLoadApis: true,
      };
    }),
);

export default lifecycleReducer;

import { Slice } from "store/utils/slice";
import { EntitiesStateWithMeta } from "store/utils/types";
import { ApiDto, ApiExecutionResponseDto } from "./types";

export type ApiMeta = {
  applicationId?: string;
  executionResult?: ApiExecutionResponseDto;
  dirty?: boolean;
  saving?: boolean;

  needsBindingExtraction?: boolean; // API requires new bindings to be extracted
  extractingBindings?: number; // the API's bindings are currently being extracted by the saga call with this callId
  extractedBindings?: string[]; // API has extracted bindings
  identifiersAtLastBindingExtraction?: string[]; // Used for checking if bindings need to be extracted again

  apiDependencies?: string[];
  cancelled?: boolean;
  concurrentRuns?: number;
  waitingForEvaluationSince?: string;
  savingFailuresCount?: number;
  testProfile?: string; // used to general test url profile param
};

export type ApiState = EntitiesStateWithMeta<ApiDto, ApiMeta>;

export default new Slice<ApiState>("apis", {
  entities: {},
  loading: {},
  errors: {},
  meta: {},
});

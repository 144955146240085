import { getNextBlockName } from "store/slices/apisShared/getNextBlockName";
import { DEFAULT_PARALLEL_POOL_SIZE } from "store/slices/apisV2/constants";
import { createVariableName } from "store/slices/apisV2/control-flow/createVariableName";
import {
  ParallelControlBlock,
  DynamicParallelControlBlock,
  ParallelWait,
  ParallelControl,
  BlockType,
} from "store/slices/apisV2/control-flow/types";
import { Flag, defaults } from "store/slices/featureFlags";
import { BLOCK_HELP_CONTENT } from "../../ControlFlow/common-block-type-info";
import {
  makeStringValidJsVarName,
  addBindingsToStringValue,
  removeBindingsFromStringValue,
} from "../../ControlFlow/utils";
import { BaseTemplateField, BaseTemplateWithExtras } from "./BaseTemplate";
import {
  ControlFlowFormComponentType,
  ControlFlowFormItem,
  FormComponentType,
  FormSectionMeta,
} from "./FormTemplateTypes";

type DerivedTypeField = "DYNAMIC" | "STATIC";
type ParallelTemplateField<V> = BaseTemplateField<ParallelControlBlock, V>;

export default class ParallelTemplate extends BaseTemplateWithExtras<ParallelControlBlock> {
  private getConfig(): Readonly<ParallelControl> {
    return this.source.config;
  }

  parallelTypeField(): ParallelTemplateField<DerivedTypeField> {
    const currentConfig = this.getConfig();
    const fieldValue = currentConfig.static == null ? "DYNAMIC" : "STATIC";

    const updateValue = (
      block: ParallelControlBlock,
      newValue: DerivedTypeField,
    ) => {
      if (newValue === "STATIC") {
        const oldPaths = currentConfig.dynamic?.blocks ?? [];
        block.config.dynamic = undefined;
        block.config.static = {
          paths: {
            Path1: oldPaths,
            Path2: [], // Important: Keep the default to 2 paths even after switching to static. 1 feels weird.
          },
        };
      } else {
        const oldPaths = currentConfig.static?.paths ?? {};
        block.config.static = undefined;
        // 1st item in oldPaths
        const firstPath: string[] = Object.values(oldPaths)[0] ?? [];
        const currentVariables = this.getCurrentScopedVariables(block);
        block.config.dynamic = {
          variables: {
            item: createVariableName("item", currentVariables),
          },
          blocks: firstPath,
          paths: "{{[1,2,3]}}",
        };
      }
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Parallel type",
      componentType: FormComponentType.DROPDOWN,
      controlFlowTooltip:
        BLOCK_HELP_CONTENT[BlockType.PARALLEL].fieldHelpContent.parallelType,
      options: [
        {
          key: "STATIC",
          value: "STATIC",
          displayName: "Static: a fixed number of paths",
        },
        {
          key: "DYNAMIC",
          value: "DYNAMIC",
          displayName: "Dynamic: a path for each item in a list",
        },
      ],
    };

    return {
      fieldValue,
      formItem,
      updateValue,
    };
  }

  waitTypeField(): ParallelTemplateField<ParallelWait> {
    const currentConfig = this.getConfig();
    const fieldValue = currentConfig.wait;

    const updateValue = (
      block: ParallelControlBlock,
      newValue: ParallelWait,
    ) => {
      block.config.wait = newValue;
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Wait for",
      componentType: FormComponentType.DROPDOWN,
      controlFlowTooltip:
        BLOCK_HELP_CONTENT[BlockType.PARALLEL].fieldHelpContent.waitFor,
      options: [
        {
          key: String(ParallelWait.WAIT_ALL),
          value: ParallelWait.WAIT_ALL,
          displayName: "all paths to complete",
        },
        {
          key: String(ParallelWait.WAIT_NONE),
          value: ParallelWait.WAIT_NONE,
          displayName: "none of the paths to complete",
        },
      ],
    };

    return {
      fieldValue,
      updateValue,
      formItem,
    };
  }

  variablesField(): BaseTemplateField<
    ParallelControlBlock,
    Record<string, string>
  > {
    const currentConfig = this.getConfig();

    const fieldValue: Record<string, string> = {};
    if (currentConfig.dynamic)
      fieldValue.item = currentConfig.dynamic.variables.item;

    const updateValue = (
      block: ParallelControlBlock,
      newValue: Record<string, string>,
    ) => {
      const dynamicBlock = block as DynamicParallelControlBlock;
      dynamicBlock.config.dynamic.variables.item = makeStringValidJsVarName(
        newValue.item,
      ) as DynamicParallelControlBlock["config"]["dynamic"]["variables"]["item"];
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Parallel variables",
      componentType: ControlFlowFormComponentType.LOCAL_VARIABLES_LIST,
    };

    return {
      formItem,
      fieldValue,
      updateValue,
    };
  }

  staticPathsField(): ParallelTemplateField<string[]> {
    const currentConfig = this.getConfig();
    const staticPaths =
      (currentConfig.static && currentConfig.static.paths) ?? {};
    const fieldValue = Object.keys(staticPaths);

    const updateValue = (block: ParallelControlBlock, newValue: string[]) => {
      if (block.config.static) {
        block.config.static.paths = newValue.reduce(
          (acc: typeof staticPaths, path, idx) => {
            const oldKey = fieldValue[idx];
            acc[path] = staticPaths[path] ?? staticPaths[oldKey] ?? [];
            return acc;
          },
          {},
        );
      }
    };

    const getAddedName = (idx: number) => {
      const keys = Object.keys(staticPaths);
      const newKey = getNextBlockName("Path", keys, idx);
      return newKey;
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Path names",
      componentType: ControlFlowFormComponentType.UNIQUE_LIST_INPUT,
      subHeading: "List of paths to run in parallel",
      placeholder: "PathName",
      itemTypeText: "path",
      getAddedName,
      getShowConfirmOnDelete: (idx: number) => {
        const key = fieldValue[idx];
        return staticPaths[key]?.length > 0;
      },
      confirmTitle: "Deleting this path will delete all its blocks.",
    };

    return {
      fieldValue,
      updateValue,
      formItem,
    };
  }

  dynamicPathsField(): ParallelTemplateField<string> {
    const currentConfig = this.getConfig();
    const fieldValue = currentConfig.dynamic
      ? removeBindingsFromStringValue(currentConfig.dynamic.paths)
      : "{{}}";

    const updateValue = (block: ParallelControlBlock, newValue: string) => {
      if (block.config.dynamic) {
        block.config.dynamic.paths = addBindingsToStringValue(newValue);
      }
    };
    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "List of items",
      subHeading: "List of items over which to run blocks in parallel",
      controlFlowTooltip:
        BLOCK_HELP_CONTENT[BlockType.PARALLEL].fieldHelpContent.dynamicItems,
      componentType: ControlFlowFormComponentType.EXPRESSION_EDITOR,
      expectedValue: "Array<any>",
      exampleData: `[
  { "first_name": "Billie" },
  { "first_name": "Thom" }
]`,
    };

    return {
      fieldValue,
      updateValue,
      formItem,
    };
  }

  dynamicItemVariableField(): ParallelTemplateField<string> {
    const currentConfig = this.getConfig();
    const fieldValue = currentConfig.dynamic?.variables?.item ?? "item";

    const updateValue = (block: ParallelControlBlock, newValue: string) => {
      if (block.config.dynamic) {
        block.config.dynamic.variables.item =
          makeStringValidJsVarName(newValue);
      }
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Current item variable name",
      componentType: FormComponentType.INPUT_TEXT,
      rules: [
        {
          required: true,
          message: "Current item variable name is required",
        },
      ],
    };

    return {
      fieldValue,
      updateValue,
      formItem,
    };
  }

  poolEnableField(): ParallelTemplateField<boolean> {
    const currentConfig = this.getConfig();
    const fieldValue = currentConfig.poolSize != null;

    const updateValue = (block: ParallelControlBlock, newValue: boolean) => {
      if (newValue) {
        block.config.poolSize = Math.min(
          this.quotas?.poolMaxSize ?? defaults[Flag.PARALLEL_POOL_MAX_SIZE],
          DEFAULT_PARALLEL_POOL_SIZE,
        );
      } else {
        block.config.poolSize = undefined;
      }
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Enable pooling",
      controlFlowTooltip:
        BLOCK_HELP_CONTENT[BlockType.PARALLEL].fieldHelpContent.enablePooling,
      componentType: FormComponentType.SWITCH,
    };

    return {
      fieldValue,
      updateValue,
      formItem,
      sectionMeta: this.advancedSection,
    };
  }

  poolSizeField(): ParallelTemplateField<number | undefined> {
    const currentConfig = this.getConfig();
    const fieldValue = currentConfig?.poolSize;

    const updateValue = (
      block: ParallelControlBlock,
      newValue: string | number | undefined,
    ) => {
      if (newValue == null) {
        block.config.poolSize = 0;
        return;
      }
      const num = typeof newValue === "string" ? Number(newValue) : newValue;
      if (Number.isNaN(num)) {
        return;
      } else {
        block.config.poolSize = num;
      }
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Pool size",
      controlFlowTooltip:
        BLOCK_HELP_CONTENT[BlockType.PARALLEL].fieldHelpContent.poolSize,
      componentType: ControlFlowFormComponentType.NUMBER_INPUT,
      subHeading:
        this.quotas?.poolMaxSize != null
          ? `Maximum supported pool size: ${this.quotas.poolMaxSize}`
          : undefined,
    };

    return {
      fieldValue,
      updateValue,
      formItem,
      sectionMeta: this.advancedSection,
    };
  }

  advancedSection: FormSectionMeta = {
    name: "Advanced",
    sectionHeader: "Advanced",
    isCollapsible: true,
    defaultCollapsed: true,
    asTitle: false,
  };

  computeFields() {
    const config: ParallelControl = this.source.config;
    const isStatic = config.static != null;

    return {
      parallelType: this.parallelTypeField(),
      ...(isStatic
        ? {}
        : {
            "dynamic.paths": this.dynamicPathsField(),
          }),
      waitType: this.waitTypeField(),
      ...(isStatic
        ? {
            staticPaths: this.staticPathsField(),
          }
        : {
            dynamicItemVariable: this.dynamicItemVariableField(),
            variables: this.variablesField(),
          }),
      poolSizeEnable: this.poolEnableField(),
      ...(config.poolSize != null && {
        poolSizeField: this.poolSizeField(),
      }),
    };
  }
}

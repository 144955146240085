export enum LocalDevServerStatus {
  UNKNOWN = "UNKNOWN",
  CONNECTED = "CONNECTED",
  NOT_CONNECTED = "NOT_CONNECTED",
}

export type GitState = { active: false } | { active: true; branch?: string };

export type LocalDevServerState =
  | { status: Exclude<LocalDevServerStatus, LocalDevServerStatus.CONNECTED> }
  | { status: LocalDevServerStatus.CONNECTED; git: GitState };

import { ApplicationScope, LanguagePluginID } from "@superblocksteam/shared";

import { createDraft, Draft, finishDraft } from "immer";
import { isArray, isBoolean, isEqual, isNumber, isString } from "lodash";
import { call, put, select, take } from "redux-saga/effects";

import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { getDataTree } from "legacy/selectors/dataTreeSelectors";
import {
  getExistingPageNames,
  getExistingWidgetNames,
} from "legacy/selectors/sagaSelectors";
import { validateName } from "legacy/utils/helpers";
import {
  extractJsEvaluationPairs,
  extractPythonEvaluationPairs,
} from "legacy/workers/evaluationUtils";

import { selectAllApiUnionNames } from "store/slices/apisShared/selectors";
import { typeSafeEntries } from "store/utils/types";
import log from "../../../../utils/logger";

import {
  createFakeDataTree,
  ScopedFakeDataTree,
  refactorNameInCode,
  refactorNameInString,
  refactorNameInValue,
} from "../../../helpers/refactoring";
import { Action, PayloadActionWithMeta } from "../../../utils/action";
import { callSagas, createSaga, SagaActionMeta } from "../../../utils/saga";

import { copyStepOutput } from "../actions";
import { persistApiSaga } from "../sagas/persistApi";
import { updateApiSaga } from "../sagas/updateApi";
import { selectApiById } from "../selectors";
import slice from "../slice";
import { ApiDto, ActionDto } from "../types";

function getExtractorByPluginId(pluginId?: string) {
  if (!pluginId) {
    return null;
  }

  switch (pluginId.trim().toLowerCase()) {
    case LanguagePluginID.Python:
      return extractPythonEvaluationPairs;
    case LanguagePluginID.JavaScript:
      return extractJsEvaluationPairs;
    default:
      return null;
  }
}

interface RefactorNamePayload {
  apiId: string;
  stepId?: string;
  oldName: string;
  newName: string;
  namespace?: string;
}

function valueUnrefactorable(
  value: unknown,
): value is boolean | number | string {
  return !value || isBoolean(value) || isNumber(value);
}

function* validateNewName(newName: string, api: ApiDto, stepId?: string) {
  let existingNames: string[];
  if (stepId) {
    existingNames = Object.values(api?.actions?.actions || {}).map(
      (action) => action.name,
    );
  } else {
    existingNames = yield select((state): string[] => {
      return [
        ...getExistingWidgetNames(state).filter(
          // widgets are already renamed; this gets around "Name is already taken"
          (widget) => widget !== newName,
        ),
        ...getExistingPageNames(state),
        ...selectAllApiUnionNames(state),
      ];
    });
  }
  const nameError = validateName(newName, existingNames);
  if (nameError) {
    throw new Error(nameError);
  }
}

function* selectFakeDataTree(
  oldName: string,
  newName: string,
  api: ApiDto,
  stepId?: string,
): Generator<unknown, ScopedFakeDataTree, DataTree> {
  if (stepId) {
    return {
      [ApplicationScope.GLOBAL]: {},
      [ApplicationScope.APP]: {},
      [ApplicationScope.PAGE]: Object.fromEntries(
        Object.values(api?.actions?.actions || {}).map((action) => [
          action.name,
          {},
        ]),
      ),
    };
  } else {
    const dataTree: ReturnType<typeof getDataTree> = yield select(getDataTree);
    const output = createFakeDataTree(dataTree);
    output[ApplicationScope.PAGE][oldName] = {};
    output[ApplicationScope.PAGE][newName] = undefined;
    return output;
  }
}

export function* refactorApi(
  api: ApiDto,
  oldName: string,
  newName: string,
  fakeDataTree: ScopedFakeDataTree,
  stepId?: string,
  namespace?: string,
): Generator<
  unknown,
  ApiDto,
  // Types here come from individual effects, so this "any" is actually quite representative
  any
> {
  const draft = createDraft(api);

  if (draft?.actions && draft?.actions?.name === oldName) {
    draft.actions.name = newName;
    // even though actions.name should be the single source of truth since it is versioned, we can still update api.name to be the same
    draft.name = newName;
  }

  let action: Draft<ActionDto> | undefined =
    draft?.actions?.actions[stepId ?? draft?.actions?.triggerActionId];

  // App-scope refactoring is done in the server, not here
  const scope = ApplicationScope.PAGE;

  while (typeof action !== "undefined") {
    if (stepId && action.name === oldName) {
      action.name = newName;
    }

    for (const [key, value] of typeSafeEntries(action.configuration)) {
      if (!action || valueUnrefactorable(value)) {
        continue;
      }

      if (isString(value)) {
        const extractor = getExtractorByPluginId(action.pluginId);

        if (key === "body" && extractor) {
          const newValue: string | null = yield call(refactorNameInCode, {
            value,
            oldName,
            newName,
            dataTree: fakeDataTree[scope],
            extractPairs: extractor,
            namespace,
          });

          if (newValue === null) {
            log.error(
              `Unable to parse code snippet "${value}" during renaming`,
            );
          } else {
            action.configuration[key] = newValue;
          }
        } else {
          action.configuration[key] = yield call(refactorNameInString, {
            value,
            oldName,
            newName,
            dataTree: fakeDataTree[scope],
            extractPairs: extractJsEvaluationPairs,
            namespace,
          });
        }
      } else if (isArray(value)) {
        for (const propertyIndex in value) {
          const property = value[propertyIndex];
          if (valueUnrefactorable(property)) {
            continue;
          }
          if (isString(property)) {
            value[propertyIndex] = yield call(refactorNameInString, {
              value: property,
              oldName,
              newName,
              dataTree: fakeDataTree[scope],
              extractPairs: extractJsEvaluationPairs,
              namespace,
            });
          } else {
            for (const [propertyKey, propertyValue] of typeSafeEntries(
              property,
            )) {
              property[propertyKey] = yield call(refactorNameInValue, {
                oldValue: propertyValue,
                oldName,
                newName,
                dataTree: fakeDataTree[scope],
                extractPairs: extractJsEvaluationPairs,
                namespace,
              });
            }
          }
        }
      }
    }

    // TODO: Support branches in the future
    const children: string[] | undefined =
      action.children && Object.values(action.children);

    // QUESTION- Is there any reason not to simply iterate over actions.actions rather than using "children"?
    action =
      children && children.length
        ? draft?.actions?.actions[children[0]]
        : undefined;
  }
  if (stepId) {
    yield put(copyStepOutput.create({ apiId: api.id, oldName, newName }));
  }
  return finishDraft(draft) as unknown as ApiDto;
}

function* persistRefactoredApi(refactoredApi: ApiDto) {
  yield callSagas([updateApiSaga.apply(refactoredApi)]);

  const result: PayloadActionWithMeta<
    ApiDto | Error,
    SagaActionMeta<ApiDto>
  > = yield take((action: Action) => {
    if (
      action.type === persistApiSaga.success.type ||
      action.type === persistApiSaga.error.type
    ) {
      const castedAction = action as PayloadActionWithMeta<
        void,
        SagaActionMeta<{ api: ApiDto }>
      >;
      return castedAction.meta.args.api.id === refactoredApi.id;
    }

    return false;
  });

  if (result.payload instanceof Error) {
    throw result.payload;
  }
}

export function* refactorNameSagaInternal({
  apiId,
  newName,
  oldName,
  stepId,
  namespace,
}: RefactorNamePayload) {
  if (oldName === newName) {
    // no-op
    return;
  }
  const api: ApiDto = yield select(selectApiById, apiId);
  yield* validateNewName(newName, api, stepId);
  const fakeDataTree: ScopedFakeDataTree = yield call(
    selectFakeDataTree,
    oldName,
    newName,
    api,
    stepId,
  );
  const refactoredApi = yield* refactorApi(
    api,
    oldName,
    newName,
    fakeDataTree,
    stepId,
  );

  if (isEqual(refactoredApi, api)) {
    return;
  }

  yield* persistRefactoredApi(refactoredApi);
}

export const refactorNameSaga = createSaga(
  refactorNameSagaInternal,
  "refactorNameSaga",
  {
    sliceName: "apis",
    keySelector: (payload) => payload.apiId,
  },
);

slice.saga(refactorNameSaga, {});

import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const GeminiPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Gemini',
  id: ExtendedIntegrationPluginId.GEMINI,
  authMethods: {
    apiKeyForm: [{ label: 'API key', header: 'x-goog-api-key', key: 'api-key' }]
  },
  serverURL: 'https://generativelanguage.googleapis.com',
  isStreamable: true
});

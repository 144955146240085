import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const AnthropicPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Anthropic',
  id: ExtendedIntegrationPluginId.ANTHROPIC,
  authMethods: {
    apiKeyForm: [{ label: 'API key', header: 'x-api-key', key: 'api-key' }]
  },
  serverURL: 'https://api.anthropic.com',
  isStreamable: true,
  headers: [{ key: 'anthropic-version', value: '2023-06-01' }]
});

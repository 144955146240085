import { LDClient, initialize, LDFlagSet } from "launchdarkly-js-client-sdk";
import envs from "env";
import { isProduction } from "utils/env";
import { Flag, Flags } from "./models/Flags";

interface FeatureFlagManager {
  getKeysForOrg: (typeof FeatureFlagManagerLaunchDarkly)["prototype"]["getKeysForOrg"];
}

class FeatureFlagManagerLaunchDarkly {
  private clientKey;
  private client?: LDClient;

  constructor(clientKey: string) {
    this.clientKey = clientKey;
  }

  getKeysForOrg = async (
    orgId?: string,
    organizationBillingPlan?: string,
    bootstrap?: LDFlagSet,
  ): Promise<Flags> => {
    // https://docs.launchdarkly.com/sdk/client-side/javascript/migration-2-to-3#understanding-differences-between-users-and-contexts
    const user = {
      key: orgId,
      kind: "user",
      plan: organizationBillingPlan,
    };
    if (this.client === undefined) {
      // Bootstrap from server
      // https://docs.launchdarkly.com/sdk/features/bootstrapping
      this.client = initialize(this.clientKey, user, { bootstrap });
      await this.client.waitForInitialization();
    } else if (this.client.getContext()?.key !== orgId) {
      await this.client.identify(user);
    }
    // Force all non-production envs to enable the code formatting FF.
    // Remove conditional flow with EG-18006
    const result = this.client.allFlags();
    if (isProduction()) {
      return result;
    }

    return {
      ...result,
      [Flag.CODE_FORMATTING]: true,
    };
  };
}

const flagClient: FeatureFlagManager = new FeatureFlagManagerLaunchDarkly(
  envs.get("SUPERBLOCKS_UI_LAUNCH_DARKLY_CLIENT_KEY"),
);
export default flagClient;

import {
  createSelectorCreator,
  lruMemoize,
  LruMemoizeOptions,
  EqualityFn,
} from "reselect";

// Takes a function and a name, and returns a identical function that also tracks the performance of the function.
export const markFunction = <T extends (...args: any[]) => any>(
  name: string,
  fun: T,
): T => {
  return ((...args: any[]) => {
    if (performance.mark) {
      const startMark = `${name}-StartMark`;
      const endMark = `${name}-EndMark`;
      const fullName = `♻️👌 Selector ${name}`;
      performance.mark(startMark);
      const result = fun(...args);
      performance.mark(endMark);
      performance.measure(fullName, startMark, endMark);
      performance.clearMarks(startMark);
      performance.clearMarks(endMark);
      performance.clearMeasures(fullName);
      return result;
    }
    return fun(...args);
  }) as T;
};

const memo =
  (matching: string) =>
  <T extends (...args: any[]) => any>(
    fun: T,
    name: string,
    options: EqualityFn | LruMemoizeOptions | undefined,
  ) => {
    return lruMemoize((...args: any[]) => {
      // If the name matches, we want to log the performance as the args,
      // are only used for the memoization of the result.
      if (name === matching && performance.mark) {
        const startMark = `${name}-StartMark`;
        const endMark = `${name}-EndMark`;
        const fullName = `♻️👌 Selector ${name}`;

        performance.mark(startMark);
        const result = fun(...args);
        performance.mark(endMark);
        performance.measure(fullName, startMark, endMark);
        performance.clearMarks(startMark);
        performance.clearMarks(endMark);
        performance.clearMeasures(fullName);
        return result;
      }
      return fun(...args);
    }, options);
  };

export const createMarkedSelector = (
  name: string,
  options: EqualityFn | LruMemoizeOptions | undefined = undefined,
) => createSelectorCreator(memo(name), name, options);

export enum ENTITY_TYPE {
  API = "API",
  ACTION = "ACTION",
  STATE_VAR = "STATE_VAR",
  TIMER = "TIMER",
  WIDGET = "WIDGET",
  GLOBAL = "GLOBAL",
  THEME = "THEME",
  ICONS = "ICONS",
  EMBEDDING = "EMBEDDING",
  EMBED_PROP = "EMBED_PROP",
  CUSTOM_EVENT = "CUSTOM_EVENT",
  NESTED_ITEM = "NESTED_ITEM",
}

import { select, take } from "redux-saga/effects";
import { Action, type PayloadActionWithMeta } from "store/utils/action";
import { createSaga, SagaActionMeta } from "../../../utils/saga";
import * as BackendTypes from "../backend-types";
import {
  getCachedControlFlowDSL,
  selectCachedControlFlowById,
  selectControlFlowPluginInfo,
} from "../control-flow/control-flow-selectors";
import { convertDSLToBackendBlocks } from "../control-flow/dsl-converters";
import { selectV2ApiMetaById } from "../selectors";
import slice, { ApiDtoWithPb } from "../slice";
import { PersistApiPayload, persistV2ApiSaga } from "./persistV2Api";

function* hydrateBlockInternal(params: {
  blockId: string;
  apiId: string;
  updatingApiCallId: number;
}): Generator<unknown, undefined | BackendTypes.Api["blocks"], any> {
  const { blockId, apiId } = params;

  let persistResult: ApiDtoWithPb | undefined;

  yield take((action: Action) => {
    if (action.type === persistV2ApiSaga.success.type) {
      const { meta, payload } = action as PayloadActionWithMeta<
        ApiDtoWithPb,
        SagaActionMeta<PersistApiPayload>
      >;
      if (apiId === meta.args.api.metadata.id) {
        const persistUpdatingCallId = meta.args.updatingApiCallId;
        if (typeof persistUpdatingCallId === "number") {
          persistResult = payload;
          return persistUpdatingCallId >= params.updatingApiCallId;
        }
      }
    }
    return false;
  });

  if (!persistResult) {
    return;
  }
  const pluginInfo: ReturnType<typeof selectControlFlowPluginInfo> =
    yield select(selectControlFlowPluginInfo);

  const currentControlFlow: ReturnType<typeof selectCachedControlFlowById> =
    yield select((state) => selectCachedControlFlowById(state, apiId));

  if (!currentControlFlow) {
    return;
  }
  const hydratedControlFlow = getCachedControlFlowDSL(
    persistResult.apiPb,
    pluginInfo,
  );
  const currentBlock = currentControlFlow.blocks[blockId];
  const hydratedBlock = hydratedControlFlow.blocks[blockId];
  if (!currentBlock || !hydratedBlock) {
    return;
  }
  const apiMeta: ReturnType<typeof selectV2ApiMetaById> = yield select(
    (state) => selectV2ApiMetaById(state, apiId),
  );
  // we hydrate other blocks too, since we can't guarantee that currentControlFlow isn't about to be changed by another hydrateBlock saga
  const hydratingBlocks = new Set(Object.keys(apiMeta?.hydratingBlocks ?? {}));
  hydratingBlocks.add(blockId);

  const blocks = Object.keys(currentControlFlow.blocks).reduce(
    (newBlocks: typeof currentControlFlow.blocks, blockId) => {
      if (hydratingBlocks.has(blockId)) {
        newBlocks[blockId] = hydratedControlFlow.blocks[blockId];
      } else {
        newBlocks[blockId] = currentControlFlow.blocks[blockId];
      }
      return newBlocks;
    },
    {},
  );

  const newControlFlow = {
    ...currentControlFlow,
    blocks,
  };
  const newBlocks = convertDSLToBackendBlocks(newControlFlow);
  return newBlocks;
}

export const hydrateBlockSaga = createSaga(
  hydrateBlockInternal,
  "hydrateBlockSaga",
  {
    sliceName: slice.name,
  },
);

slice.saga(hydrateBlockSaga, {
  start(state, { payload }) {
    const hydratingBlocks = state.meta[payload.apiId].hydratingBlocks ?? {};
    hydratingBlocks[payload.blockId] = true;
    state.meta[payload.apiId].hydratingBlocks = hydratingBlocks;
  },
  success(state, { payload, meta }) {
    const hydratingBlocks = state.meta[meta.args.apiId].hydratingBlocks ?? {};
    delete hydratingBlocks[meta.args.blockId];

    if (!payload || !state.entities[meta.args.apiId]?.apiPb) {
      return;
    }
    state.entities[meta.args.apiId] = {
      ...state.entities[meta.args.apiId],
      apiPb: {
        ...state.entities[meta.args.apiId].apiPb,
        blocks: payload,
      },
    };
  },
  error(state, { payload, meta }) {
    const hydratingBlocks = state.meta[meta.args.apiId].hydratingBlocks ?? {};
    delete hydratingBlocks[meta.args.blockId];
  },
});

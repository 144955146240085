import { RepositoryWithEntitiesDto } from "@superblocksteam/shared";
import { Slice } from "store/utils/slice";
import { EntitiesStateWithMeta } from "store/utils/types";

type RepositoryMeta = {
  id?: string;
};

type RepositoriesState = EntitiesStateWithMeta<
  RepositoryWithEntitiesDto,
  RepositoryMeta
>;

export default new Slice<RepositoriesState>("repositories", {
  entities: {},
  loading: {},
  errors: {},
  meta: {},
});

import { select } from "redux-saga/effects";
import { EntitiesErrorType } from "store/utils/types";
import { SagaType, createSaga } from "../../../utils/saga";
import { selectCachedControlFlowById } from "../control-flow/control-flow-selectors";
import { selectV2ApiMetaById } from "../selectors";
import slice from "../slice";
import {
  EnrichedExecutionResult,
  ExecutionResponse,
  EnrichedExecutionProcessingOptions,
} from "../types";
import { getEnrichedExecutionResult } from "../utils/execution-response";

function* computedEnrichedExecutionInternal(payload: {
  executionResult: undefined | ExecutionResponse;
  apiId: string;
  options?: EnrichedExecutionProcessingOptions;
}): Generator<unknown, undefined | EnrichedExecutionResult, any> {
  const { executionResult, apiId } = payload;

  const controlFlow: ReturnType<typeof selectCachedControlFlowById> =
    yield select((state) => selectCachedControlFlowById(state, apiId));
  if (controlFlow == null) {
    return;
  }
  const apiMeta = yield select(selectV2ApiMetaById, apiId);
  const previousExecutionResult = apiMeta?.enrichedExecutionResult;
  const enrichedExecutionResult = getEnrichedExecutionResult(
    executionResult,
    controlFlow,
    payload.options?.mergeExecutionEvents ? previousExecutionResult : undefined,
    payload.options,
  );
  return enrichedExecutionResult;
}

export const computeEnrichedExecutionSaga = createSaga(
  computedEnrichedExecutionInternal,
  "computeEnrichedExecutionSaga",
  {
    sliceName: slice.name,
    type: SagaType.Throttled,
    keySelector: (payload) => payload.apiId,
    delay: 50,
  },
);

slice.saga(computeEnrichedExecutionSaga, {
  start(state, { payload }) {
    // do nothing
  },
  success(state, { payload, meta }) {
    if (payload) {
      state.meta[meta.args.apiId] = state.meta[meta.args.apiId] ?? {};
      state.meta[meta.args.apiId].enrichedExecutionResult = payload;
    }
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.apiId] = {
      type: EntitiesErrorType.EXECUTE_ERROR,
      error: payload,
    };
  },
});

import { Slice } from "store/utils/slice";
import { EntitiesStateWithMeta, ROOT } from "store/utils/types";
import { WidgetCardsCategorized, baseWidgets } from "./baseWidgets";

interface WidgetsState {
  widgets: WidgetCardsCategorized;
}

export default new Slice<
  EntitiesStateWithMeta<WidgetsState, Record<string, never>>
>("availableWidgets", {
  entities: { [ROOT]: { widgets: baseWidgets } },
  loading: {},
  errors: {},
  meta: {},
});

import {
  ReduxActionTypes,
  ReduxAction,
} from "legacy/constants/ReduxActionConstants";
import { createImmerReducer } from "../createReducer";

type EmbedInitialValuesState = Record<string, unknown>;

const initialState: EmbedInitialValuesState = {
  initialValues: {},
};

const embeddingReducer = createImmerReducer(initialState, (builder) =>
  builder.addCase(
    ReduxActionTypes.INIT_EMBED_PROPERTY_VALUES,
    (
      state,
      {
        payload: { values },
      }: ReduxAction<
        { values: Record<string, unknown> },
        typeof ReduxActionTypes.INIT_EMBED_PROPERTY_VALUES
      >,
    ) => {
      return { initialValues: values };
    },
  ),
);

export default embeddingReducer;

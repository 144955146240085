import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const SegmentPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Segment',
  id: ExtendedIntegrationPluginId.SEGMENT,
  authMethods: {
    bearer: true
  },
  serverURL: 'https://api.segmentapis.com'
});

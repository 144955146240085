import { ApiInfo6, DATA_DOMAIN_HEADER } from "@superblocksteam/shared";
import { MultiStepDef } from "./ActionConstants";

type ContentType = "application/json" | "application/x-www-form-urlencoded";

export const REQUEST_TIMEOUT_MS = 300000;

export const API_REQUEST_HEADERS: APIHeaders = {
  "Content-Type": "application/json",
};

export enum API_STATUS_CODES {
  REQUEST_UNAUTHORIZED = 401,
  RESOURCE_FORBIDDEN = 403,
  RESOURCE_NOT_FOUND = 404,
  TIMEOUT = 408,
  RESOURCE_CONFLICT = 409,
  SERVER_ERROR = 502,
  SERVER_UNAVAILABLE = 503,
  BAD_REQUEST = 400,
}

export enum ERROR_CODES {
  PAGE_NOT_FOUND = "PAGE_NOT_FOUND",
  SERVER_ERROR = "SERVER_ERROR",
  REQUEST_UNAUTHORIZED = "REQUEST_UNAUTHORIZED",
  REQUEST_TIMEOUT = "REQUEST_TIMEOUT",
  REFRESH_REQUIRED = "REFRESH_REQUIRED",
  VERIFY_RESOURCES_FAILED = "VERIFY_RESOURCES_FAILED",
  NO_AGENTS = "NO_AGENTS",
  JWT_EXPIRED = "JWT_EXPIRED",
}

interface APIHeaders {
  "Content-Type": ContentType;
  Accept?: string;
  [DATA_DOMAIN_HEADER]?: string;
}

export type ApiInfo = Omit<
  ApiInfo6,
  "onSuccess" | "onError" | "onCancel" | "onMessage"
> & {
  onSuccess?: MultiStepDef;
  onError?: MultiStepDef;
  onMessage?: MultiStepDef;
  onCancel?: MultiStepDef;
};

export type ApisMap = Record<ApiInfo["id"], ApiInfo>;

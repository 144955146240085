import { combineReducers } from "redux";
import entityReducer from "./entityReducers";
import embeddingReducer from "./entityReducers/embeddingReducer";
import evaluationsReducer from "./evaluationReducers";
import uiReducer from "./uiReducers";

export default combineReducers({
  entities: entityReducer,
  ui: uiReducer,
  evaluations: evaluationsReducer,
  embedding: embeddingReducer,
});

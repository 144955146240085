import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const BitbucketPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Bitbucket',
  id: ExtendedIntegrationPluginId.BITBUCKET,
  authMethods: {
    bearer: true,
    basic: true
  },
  serverURL: 'https://api.bitbucket.org/2.0'
});

import { createSaga } from "../../../utils/saga";
import slice from "../slice";
import { EnrichedExecutionResult } from "../types";

// eslint-disable-next-line require-yield
function* updateEnrichedExecutionInternal(payload: {
  newEnrichedExecutionResult: EnrichedExecutionResult;
  apiId: string;
}): Generator<unknown, undefined | EnrichedExecutionResult, any> {
  return payload.newEnrichedExecutionResult;
}

export const updateEnrichedExecutionSaga = createSaga(
  updateEnrichedExecutionInternal,
  "updateEnrichedExecutionSaga",
  {
    sliceName: slice.name,
  },
);

slice.saga(updateEnrichedExecutionSaga, {
  start(state, { payload }) {
    // do nothing
  },
  success(state, { payload, meta }) {
    if (payload) {
      state.meta[meta.args.apiId] = state.meta[meta.args.apiId] ?? {};
      state.meta[meta.args.apiId].enrichedExecutionResult = payload;
    }
  },
  error(state, { payload, meta }) {
    // do nothing
  },
});

import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import { UIErrorType } from "@superblocksteam/shared";
import React, { Component, PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ErrorComponent from "components/app/Error/ErrorComponent";
import { ERROR_CODES } from "legacy/constants/ApiConstants";
import {
  getSafeCrash,
  getSafeCrashCode,
  getSafeCrashReason,
} from "legacy/selectors/errorSelectors";
import logger, { stringifyError } from "utils/logger";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - ${(props) => props.theme.legacy.headerHeight});
  .bold-text {
    font-weight: ${(props) => props.theme.legacy.fontWeights[3]};
    font-size: 24px;
  }
  .page-unavailable-img {
    width: 35%;
  }
  .button-position {
    margin: auto;
  }
`;

interface AppErrorBoundaryProps extends PropsWithChildren {
  safeCrash?: boolean;
  safeCrashCode?: ERROR_CODES;
  safeCrashReason?: string;
}

class AppErrorBoundaryInternal extends Component<AppErrorBoundaryProps> {
  state = {
    hasError: false,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const { safeCrash, safeCrashCode, safeCrashReason } = this.props;

    let appCrashFirstMsgLine = "App crashed, error:";
    if (safeCrash) {
      appCrashFirstMsgLine = `App safe crashed`;

      if (safeCrashCode) {
        appCrashFirstMsgLine = `${appCrashFirstMsgLine} with code: ${safeCrashCode}`;
      }
      if (safeCrashReason) {
        appCrashFirstMsgLine = `${appCrashFirstMsgLine} and reason: ${safeCrashReason}`;
      }

      appCrashFirstMsgLine = `${appCrashFirstMsgLine}, error:`;
    }

    logger.error(
      `${appCrashFirstMsgLine}
${stringifyError(error)}
${JSON.stringify(errorInfo)}`,
      {
        superblocks_ui_error_type: UIErrorType.CRASH_APP_ERROR,
      },
    );
    Sentry.captureException(error);
    datadogRum.addError(error);
    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Wrapper>
          <ErrorComponent
            title="Oops! Something went wrong"
            errorMessage={
              <p>
                Please try again using the button below. <br />
                If the issue persists, please contact us
              </p>
            }
            buttonText={"Retry"}
            handleButtonClick={() => window.location.reload()}
          />
        </Wrapper>
      );
    }
    return this.props.children;
  }
}

const AppErrorBoundary: React.FC<PropsWithChildren> = (props) => {
  const safeCrash = useSelector(getSafeCrash);
  const safeCrashCode = useSelector(getSafeCrashCode);
  const safeCrashReason = useSelector(getSafeCrashReason);

  return (
    <AppErrorBoundaryInternal
      {...props}
      safeCrash={safeCrash}
      safeCrashCode={safeCrashCode}
      safeCrashReason={safeCrashReason}
    >
      {props.children}
    </AppErrorBoundaryInternal>
  );
};

export default AppErrorBoundary;

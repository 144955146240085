import { PageDSL8 } from "@superblocksteam/shared";
import { stopEvaluation } from "legacy/actions/evaluationActions";
import { updateCurrentRoute } from "legacy/actions/pageActions";
import { ReduxActionErrorTypes } from "legacy/constants/ReduxActionConstants";
import { createImmerReducer } from "../createReducer";

export interface AppViewReduxState {
  isInitialized: boolean;
  pageSwitchingError: boolean;
  dsl?: PageDSL8;
  currentLayoutId?: string;
}

const initialState: AppViewReduxState = {
  isInitialized: false,
  pageSwitchingError: false,
};

const appViewReducer = createImmerReducer(initialState, (builder) =>
  builder
    .addCase(stopEvaluation, () => initialState)
    // TODO(wylie): Use getSagaEvents helper once that doesn't cause a circular dependency
    .addCase("initializeAppViewerSaga/success", (state: AppViewReduxState) => {
      return { ...state, isInitialized: true };
    })
    .addCase("initializeAppViewerSaga/start", (state: AppViewReduxState) => {
      return { ...state, isInitialized: false, pageSwitchingError: false };
    })
    .addCase("initializeAppViewerSaga/error", (state: AppViewReduxState) => {
      return { ...state, isInitialized: true, pageSwitchingError: true };
    })
    .addCase(updateCurrentRoute.type, (state: AppViewReduxState) => {
      state.pageSwitchingError = false;
    })
    .addCase(
      ReduxActionErrorTypes.FETCH_PUBLISHED_PAGE_ERROR,
      (state: AppViewReduxState) => {
        state.pageSwitchingError = true;
      },
    ),
);

export default appViewReducer;

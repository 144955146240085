import { InputGroup } from "@blueprintjs/core";
import { debounce } from "lodash";
import React, { Component } from "react";
import styled from "styled-components";

interface StyledInputGroupProps {
  $compact?: boolean;
  $noMargin?: boolean;
  $width?: string;
  $flexGrow?: string;
}

const StyledInputGroup = styled(InputGroup)<StyledInputGroupProps>`
  border: 1px solid
    ${(props) => props.theme.legacy.appColors.textField.normal.border};
  border-radius: 4px;

  &&& input {
    box-shadow: none;
    background: none;
    font-size: 14px;
    height: 100%;
    padding-left: ${({ $compact }) => ($compact ? "28px" : "38px")};
  }

  &&& span {
    margin: ${({ $compact }) => ($compact ? "3px" : "9px")};
  }

  &&& svg {
    opacity: 0.6;
  }
  margin: ${({ $noMargin }) => ($noMargin ? "0" : "12px 16px")};
  width: ${({ $width }) => $width ?? "204px"};
  min-width: 150px;
  height: ${({ $compact }) => ($compact ? "24px" : "36px")};
  ${({ $flexGrow }) => $flexGrow && `flex-grow: ${$flexGrow}`};
`;

interface SearchComponentProps {
  onSearch: (value: any) => void;
  placeholder: string;
  value: string;
  compact?: boolean;
  noMargin?: boolean;
  width?: string;
  flexGrow?: string;
  dataTest?: string;
}

interface SearchComponentState {
  localValue: string;
}

class SearchComponent extends Component<
  SearchComponentProps,
  SearchComponentState
> {
  onDebouncedSearch = debounce(this.props.onSearch, 400);

  constructor(props: SearchComponentProps) {
    super(props);
    this.state = {
      localValue: props.value,
    };
  }

  componentDidUpdate(prevProps: Readonly<SearchComponentProps>) {
    // Reset local state if the value has updated via default value
    if (prevProps.value !== this.props.value) {
      this.setState({ localValue: this.props.value });
    }
  }

  handleSearch = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const search = event.target.value;
    this.setState({ localValue: search });
    this.onDebouncedSearch(search);
  };

  render() {
    return (
      <StyledInputGroup
        leftIcon="search"
        type="search"
        role="search"
        onChange={this.handleSearch}
        placeholder={this.props.placeholder}
        value={this.state.localValue ?? ""}
        $compact={this.props.compact}
        $noMargin={this.props.noMargin}
        $width={this.props.width}
        $flexGrow={this.props.flexGrow}
        data-test={this.props.dataTest}
      />
    );
  }
}

export default SearchComponent;

import { OnboardingTask, TASKS } from "@superblocksteam/shared";
import { tasks } from "pages/components/Checklist/tasks";
import { Slice } from "store/utils/slice";
import { EntityState } from "store/utils/types";

type State = {
  checklistTasks: OnboardingTask[];
  recommendedDatasources: string[];
};

type UserState = EntityState<State>;

const DEFAULT_TASK_DATE = new Date("2022-06-13T00:00:00-07:00");

export default new Slice<UserState>("user", {
  entity: {
    checklistTasks: tasks.map((task) => {
      return {
        id: task.id,
        completed:
          task.id === TASKS.COMPLETE_SURVEY ? DEFAULT_TASK_DATE : undefined, // set survey as done since on checklist load user has finished survey
      };
    }),
    recommendedDatasources: [],
  },
  loading: {} as any,
  error: {} as any,
});

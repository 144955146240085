import {
  PostApiDeployRequestBody,
  PostApplicationDeployRequestBody,
} from "@superblocksteam/schemas";
import {
  GetApiDeploymentsResponseBody,
  GetApplicationDeploymentsResponseBody,
  VersionedEntityType,
} from "@superblocksteam/shared";
import { callServer, HttpMethod } from "../../utils/client";

export function deployCommit(
  entityId: string,
  entityType: VersionedEntityType,
  commitId: string,
  deployMessage: string,
): Promise<PostApplicationDeployRequestBody | PostApiDeployRequestBody> {
  switch (entityType) {
    case VersionedEntityType.APPLICATION:
      return callServer<PostApplicationDeployRequestBody>({
        method: HttpMethod.Post,
        url: `v2/applications/${entityId}/deployments`,
        body: { commitId, deployMessage },
      });
    case VersionedEntityType.SCHEDULED_JOB:
    case VersionedEntityType.WORKFLOW:
      return callServer<PostApiDeployRequestBody>({
        method: HttpMethod.Post,
        url: `v3/apis/${entityId}/deployments`,
        body: { commitId, deployMessage },
      });
  }
}

export function getDeployments(
  entityId: string,
  entityType: VersionedEntityType,
): Promise<
  GetApiDeploymentsResponseBody | GetApplicationDeploymentsResponseBody
> {
  switch (entityType) {
    case VersionedEntityType.APPLICATION:
      return callServer({
        method: HttpMethod.Get,
        url: `v2/applications/${entityId}/deployments`,
      });
    case VersionedEntityType.SCHEDULED_JOB:
    case VersionedEntityType.WORKFLOW:
      return callServer({
        method: HttpMethod.Get,
        url: `v3/apis/${entityId}/deployments`,
      });
  }
}

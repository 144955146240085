import type { NestedItemParts } from "legacy/widgets/NestedItem";

export function extractPartsFromNestedItemId(itemId: string): NestedItemParts {
  const [widgetId, path] = itemId.split(":");
  return {
    widgetId,
    path,
  };
}

export function buildNestedItemId({ widgetId, path }: NestedItemParts): string {
  return [widgetId, path].join(":");
}

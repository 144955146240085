import { isBoolean } from "lodash";

/**
 * Internally used by VALIDATORS.BOOLEAN
 * See `parseValidationBoolean.test.ts` for examples.
 */
export function parseValidationBoolean(value: any): {
  isValid: boolean;
  parsed: boolean;
} {
  if (value === undefined) {
    return { isValid: true, parsed: false };
  }
  let parsed = value;
  const isABoolean = isBoolean(value);
  const isStringTrueFalse = value === "true" || value === "false";
  const isValid = isABoolean || isStringTrueFalse;
  if (isStringTrueFalse) parsed = value !== "false";
  return { isValid, parsed: Boolean(parsed) };
}

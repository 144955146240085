import { compile, serialize, stringify, prefixer, middleware } from "stylis";

const insertStyleRule = (rule: string) => {
  const styleTag = document.createElement("style");
  styleTag.appendChild(document.createTextNode(rule));
  document.head.appendChild(styleTag);
};

// Hash the style string to create a predictable namespace
// See https://stackoverflow.com/a/52171480/610632
const cyrb53 = (str: string, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export const styleAsClass = (
  strings: TemplateStringsArray,
  ...params: string[]
): string => {
  const style = String.raw(strings, ...params);
  // Classnames must start with a letter
  const namespace = `s` + cyrb53(style).toString(36); // alphanumeric
  const parsed = serialize(
    compile(`.${namespace}{${style}}`),
    middleware([prefixer, stringify]),
  );
  insertStyleRule(parsed);
  return namespace;
};

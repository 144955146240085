import { Integrations } from "@sentry/tracing";
import envs from "env";
import { GlobalConfigs } from "./types";

interface InjectedConfigs {
  sentry: {
    dsn: string;
    release: string;
    environment: string;
  };
  smartLook: {
    id: string;
  };
  enableGoogleOAuth: boolean;
  enableGithubOAuth: boolean;
  enableRapidAPI: boolean;
  segment: {
    apiKey: string;
    ceKey: string;
  };
  fusioncharts: {
    licenseKey: string;
  };
  enableMixpanel: boolean;
  google: string;
  enableTNCPP: boolean;
  cloudHosting: boolean;
  algolia: {
    apiId: string;
    apiKey: string;
    indexName: string;
  };
  logLevel: "debug" | "error";
  appVersion: {
    id: string;
    releaseDate: string;
  };
  intercomAppID: string;
  mailEnabled: boolean;
  disableTelemetry: boolean;
}

declare global {
  interface Window {
    SUPERBLOCKS_CONFIGS: InjectedConfigs;
    Intercom: any;
    dataLayer: any; // this is used by Google Tag manager
  }
}

const capitalizeText = (text: string) => {
  const rest = text.slice(1);
  const first = text[0].toUpperCase();
  return `${first}${rest}`;
};

const getConfigsFromEnvVars = (): InjectedConfigs => {
  return {
    sentry: {
      dsn: process.env.REACT_APP_SENTRY_DSN || "",
      release: process.env.REACT_APP_SENTRY_RELEASE || "",
      environment:
        process.env.REACT_APP_SENTRY_ENVIRONMENT ||
        capitalizeText(process.env.NODE_ENV || "development"),
    },
    smartLook: {
      id: process.env.REACT_APP_SMART_LOOK_ID || "",
    },
    enableGoogleOAuth: process.env.REACT_APP_OAUTH2_GOOGLE_CLIENT_ID
      ? process.env.REACT_APP_OAUTH2_GOOGLE_CLIENT_ID.length > 0
      : false,
    enableGithubOAuth: process.env.REACT_APP_OAUTH2_GITHUB_CLIENT_ID
      ? process.env.REACT_APP_OAUTH2_GITHUB_CLIENT_ID.length > 0
      : false,
    segment: {
      apiKey: process.env.REACT_APP_SEGMENT_KEY || "",
      ceKey: process.env.REACT_APP_SEGMENT_CE_KEY || "",
    },
    fusioncharts: {
      licenseKey: process.env.REACT_APP_FUSIONCHARTS_LICENSE_KEY || "",
    },
    enableMixpanel: process.env.REACT_APP_SEGMENT_KEY
      ? process.env.REACT_APP_SEGMENT_KEY.length > 0
      : false,
    algolia: {
      apiId: process.env.REACT_APP_ALGOLIA_API_ID || "",
      apiKey: process.env.REACT_APP_ALGOLIA_API_KEY || "",
      indexName: process.env.REACT_APP_ALGOLIA_SEARCH_INDEX_NAME || "",
    },
    logLevel:
      (process.env.REACT_APP_CLIENT_LOG_LEVEL as
        | "debug"
        | "error"
        | undefined) || "debug",
    google: envs.get("SUPERBLOCKS_UI_GOOGLE_MAPS_API_KEY"),
    enableTNCPP: process.env.REACT_APP_TNC_PP
      ? process.env.REACT_APP_TNC_PP.length > 0
      : false,
    enableRapidAPI: process.env.REACT_APP_MARKETPLACE_URL
      ? process.env.REACT_APP_MARKETPLACE_URL.length > 0
      : false,
    cloudHosting: process.env.REACT_APP_CLOUD_HOSTING
      ? process.env.REACT_APP_CLOUD_HOSTING.length > 0
      : false,
    appVersion: {
      id: process.env.REACT_APP_VERSION_ID || "",
      releaseDate: process.env.REACT_APP_VERSION_RELEASE_DATE || "",
    },
    intercomAppID: process.env.REACT_APP_INTERCOM_APP_ID || "",
    mailEnabled: process.env.REACT_APP_MAIL_ENABLED
      ? process.env.REACT_APP_MAIL_ENABLED.length > 0
      : false,
    disableTelemetry: true,
  };
};

const getConfig = (fromENV: string, fromWindow: string) => {
  if (fromWindow.length > 0) return { enabled: true, value: fromWindow };
  else if (fromENV.length > 0) return { enabled: true, value: fromENV };
  return { enabled: false, value: "" };
};

// TODO(Abhinav): See if this is called so many times, that we may need some form of memoization.
export const getGlobalConfigs = (): GlobalConfigs => {
  const { SUPERBLOCKS_CONFIGS } = window;
  const ENV_CONFIG = getConfigsFromEnvVars();

  // const sentry = getConfig(ENV_CONFIG.sentry, SUPERBLOCKS_CONFIGS.sentry);
  const sentryDSN = getConfig(
    ENV_CONFIG.sentry.dsn,
    SUPERBLOCKS_CONFIGS.sentry.dsn,
  );
  const sentryRelease = getConfig(
    ENV_CONFIG.sentry.release,
    SUPERBLOCKS_CONFIGS.sentry.release,
  );
  const sentryENV = getConfig(
    ENV_CONFIG.sentry.environment,
    SUPERBLOCKS_CONFIGS.sentry.environment,
  );
  const segment = getConfig(
    ENV_CONFIG.segment.apiKey,
    SUPERBLOCKS_CONFIGS.segment.apiKey,
  );
  const fusioncharts = getConfig(
    ENV_CONFIG.fusioncharts.licenseKey,
    SUPERBLOCKS_CONFIGS.fusioncharts.licenseKey,
  );
  const google = getConfig(ENV_CONFIG.google, SUPERBLOCKS_CONFIGS.google);

  // As the following shows, the config variables can be set using a combination
  // of env variables and injected configs
  const smartLook = getConfig(
    ENV_CONFIG.smartLook.id,
    SUPERBLOCKS_CONFIGS.smartLook.id,
  );

  const algoliaAPIID = getConfig(
    ENV_CONFIG.algolia.apiId,
    SUPERBLOCKS_CONFIGS.algolia.apiId,
  );
  const algoliaAPIKey = getConfig(
    ENV_CONFIG.algolia.apiKey,
    SUPERBLOCKS_CONFIGS.algolia.apiKey,
  );
  const algoliaIndex = getConfig(
    ENV_CONFIG.algolia.indexName,
    SUPERBLOCKS_CONFIGS.algolia.indexName,
  );

  const segmentCEKey = getConfig(
    ENV_CONFIG.segment.ceKey,
    SUPERBLOCKS_CONFIGS.segment.ceKey,
  );

  // We enable segment tracking if either the Cloud API key is set or the self-hosted CE key is set
  segment.enabled = segment.enabled || segmentCEKey.enabled;

  let sentryTelemetry = true;
  // Turn off all analytics if telemetry is disabled
  if (SUPERBLOCKS_CONFIGS.disableTelemetry) {
    smartLook.enabled = false;
    segment.enabled = false;
    sentryTelemetry = false;
  }

  return {
    sentry: {
      enabled:
        sentryDSN.enabled &&
        sentryRelease.enabled &&
        sentryENV.enabled &&
        sentryTelemetry,
      dsn: sentryDSN.value,
      release: sentryRelease.value,
      environment: sentryENV.value,
      normalizeDepth: 3,
      integrations: [
        new Integrations.BrowserTracing({
          // routingInstrumentation: Sentry.reactRouterV6Instrumentation(),
        }),
      ],
      tracesSampleRate: 0.5,
    },
    smartLook: {
      enabled: smartLook.enabled,
      id: smartLook.value,
    },
    segment: {
      enabled: segment.enabled,
      apiKey: segment.value,
      ceKey: segmentCEKey.value,
    },
    fusioncharts: {
      enabled: fusioncharts.enabled,
      licenseKey: fusioncharts.value,
    },
    algolia: {
      enabled: true,
      apiId: algoliaAPIID.value || "",
      apiKey: algoliaAPIKey.value || "",
      indexName: algoliaIndex.value || "",
    },
    google: {
      enabled: google.enabled,
      apiKey: google.value,
    },
    enableRapidAPI:
      ENV_CONFIG.enableRapidAPI || SUPERBLOCKS_CONFIGS.enableRapidAPI,
    enableGithubOAuth:
      ENV_CONFIG.enableGithubOAuth || SUPERBLOCKS_CONFIGS.enableGithubOAuth,
    enableGoogleOAuth:
      ENV_CONFIG.enableGoogleOAuth || SUPERBLOCKS_CONFIGS.enableGoogleOAuth,
    enableMixpanel:
      ENV_CONFIG.enableMixpanel || SUPERBLOCKS_CONFIGS.enableMixpanel,
    cloudHosting: ENV_CONFIG.cloudHosting || SUPERBLOCKS_CONFIGS.cloudHosting,
    logLevel: ENV_CONFIG.logLevel || SUPERBLOCKS_CONFIGS.logLevel,
    enableTNCPP: ENV_CONFIG.enableTNCPP || SUPERBLOCKS_CONFIGS.enableTNCPP,
    appVersion: ENV_CONFIG.appVersion || SUPERBLOCKS_CONFIGS.appVersion,
    intercomAppID:
      ENV_CONFIG.intercomAppID || SUPERBLOCKS_CONFIGS.intercomAppID,
    mailEnabled: ENV_CONFIG.mailEnabled || SUPERBLOCKS_CONFIGS.mailEnabled,
    disableTelemetry: SUPERBLOCKS_CONFIGS.disableTelemetry,
  };
};

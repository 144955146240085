import { createAction } from "@reduxjs/toolkit";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import { type ReferenceAnalysisState } from "./referenceAnalysisReducer";

export const setReferenceAnalysisState = createAction<ReferenceAnalysisState>(
  ReduxActionTypes.SET_REFERENCE_ANALYSIS_STATE,
);

export const setReferenceAnalysisModalOpen = createAction<null | ENTITY_TYPE>(
  ReduxActionTypes.SET_REFERENCE_ANALYSIS_MODAL_OPEN,
);

export const setAllReferencesModalOpenPath = createAction<null | string>(
  ReduxActionTypes.SET_ALL_REFERENCES_MODAL_OPEN_PATH,
);

import {
  DeploymentDto,
  GetApiDeploymentsResponseBody,
  GetApplicationDeploymentsResponseBody,
  VersionedEntityType,
} from "@superblocksteam/shared";
import { call } from "redux-saga/effects";
import { ROOT } from "store/utils/types";
import { createSaga } from "../../../utils/saga";
import { getDeployments } from "../client";
import slice from "../slice";

function* fetchDeploymentsSaga({
  entityId,
  entityType,
}: {
  entityId: string;
  entityType: VersionedEntityType;
}) {
  const result:
    | GetApiDeploymentsResponseBody
    | GetApplicationDeploymentsResponseBody = yield call(
    getDeployments,
    entityId,
    entityType,
  );
  return result;
}

const fetchDeployments = createSaga(
  fetchDeploymentsSaga,
  fetchDeploymentsSaga.name,
  {
    sliceName: slice.name,
  },
);
export { fetchDeployments as fetchDeploymentsSaga };

slice.saga(fetchDeployments, {
  start(state) {
    state.loading.deployments = true;
  },
  success(state, { payload }) {
    state.entities = payload.reduce(
      (result, deployment) => {
        result[deployment.deploymentId] = deployment;
        return result;
      },
      {} as Record<string, DeploymentDto>,
    );
    state.loading.deployments = false;
  },
  error(state, { payload }) {
    state.errors[ROOT] = { error: payload };
    state.loading.deployments = false;
  },
});

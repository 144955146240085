import {
  EmbedProperty8 as EmbedProperty,
  PageDSL8 as PageDSL,
} from "@superblocksteam/shared";

export const DEFAULT_EMBED_PROPERTY_META: EmbedPropertyMetaType = {
  value: null,
};

export type { EmbedProperty };

export type EmbedPropertyMap = Required<PageDSL>["embedding"]["propertyMap"];

export type EmbedPropertyAndMetaMap = Record<
  EmbedProperty["id"],
  { embedProp: EmbedProperty; embedPropMeta: EmbedPropertyMetaType | undefined }
>;
export type EmbedPropertyMetaType = {
  value: any;
};

export const EMBED_PATH_PREFIX = "Embed";

import { Dimension } from './Dimension';

export type Padding = {
  left?: Dimension<'px'>; // Default: { value: 0, mode: 'px' }
  right?: Dimension<'px'>; // Default: { value: 0, mode: 'px' }
  top?: Dimension<'px'>; // Default: { value: 0, mode: 'px' }
  bottom?: Dimension<'px'>; // Default: { value: 0, mode: 'px' }
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Padding {
  export const y = (padding?: Padding): Dimension<'px'> => {
    if (padding === undefined) {
      return Dimension.px(0);
    }
    return Dimension.add(padding.top ?? Dimension.px(0), padding.bottom ?? Dimension.px(0)).asFirst();
  };

  export const x = (padding?: Padding): Dimension<'px'> => {
    if (padding === undefined) {
      return Dimension.px(0);
    }
    return Dimension.add(padding.left ?? Dimension.px(0), padding.right ?? Dimension.px(0)).asFirst();
  };
}

import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const HubSpotPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'HubSpot',
  id: ExtendedIntegrationPluginId.HUBSPOT,
  authMethods: {
    bearer: true
  },
  serverURL: 'https://api.hubapi.com'
});

import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { Org } from "legacy/constants/orgConstants";
import { createImmerReducer } from "../createReducer";

const initialState: OrgReduxState = {
  currentOrg: {
    id: "",
    name: "",
  },
};

const orgReducer = createImmerReducer(initialState, (builder) =>
  builder.addCase(
    ReduxActionTypes.SET_CURRENT_ORG_ID,
    (
      draftState: OrgReduxState,
      action: ReduxAction<
        { orgId: string },
        typeof ReduxActionTypes.SET_CURRENT_ORG_ID
      >,
    ) => {
      draftState.currentOrg.id = action.payload.orgId;
    },
  ),
);

export interface OrgReduxState {
  currentOrg: Org;
}

export default orgReducer;

import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const GitHubPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'GitHub',
  id: ExtendedIntegrationPluginId.GITHUB,
  authMethods: {
    bearer: true
  },
  serverURL: 'https://api.github.com'
});

import { isArray, isObject } from "lodash";
import type { PropertyDto } from "../types";

export function recursivelyExtractValues(
  obj: any,
): (PropertyDto | string | boolean | number)[] {
  const res = Object.entries(obj).map((entry) => {
    if (isObject(entry[1]) || isArray(entry[1])) {
      return recursivelyExtractValues(entry[1]);
    }
    return entry[1] as PropertyDto | string | boolean | number;
  });

  return res.flat();
}

export function getNextBlockName(
  prefix: string,
  existingNames: string[],
  start: number,
) {
  let lastIndex = start + 1;
  let name = prefix + lastIndex;

  while (existingNames.includes(name)) {
    lastIndex += 1;
    name = prefix + lastIndex;
  }

  return name;
}

import { createReducer } from "@reduxjs/toolkit";
import {
  ButtonClickErrorPayload,
  ButtonClickPayload,
  ButtonType,
  LoadButtonPayloadSuccess,
} from "@superblocksteam/shared";
import {
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";

export interface DynamicFormState {
  dynamicFormButtonLoading: Record<ButtonType, boolean>;
  dynamicFormButtonDisabled: Record<ButtonType, boolean>;
  dynamicFormErrors?: string[];
}

const initialState: DynamicFormState = {
  dynamicFormButtonLoading: {
    revokeOAuthTokens: false,
    connectOAuth: false,
  },
  dynamicFormButtonDisabled: {
    revokeOAuthTokens: true,
    connectOAuth: true,
  },
};

const dynamicFormReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(ReduxActionTypes.RESET_DYNAMIC_FORM, () => {
      return initialState;
    })
    .addCase(
      ReduxActionTypes.LOAD_DYNAMIC_FORM_BUTTON_SUCCESS,
      (
        state: DynamicFormState,
        action: ReduxAction<
          LoadButtonPayloadSuccess,
          typeof ReduxActionTypes.LOAD_DYNAMIC_FORM_BUTTON_SUCCESS
        >,
      ) => {
        const { buttonType, disabled } = action.payload;
        state.dynamicFormButtonDisabled[buttonType] = disabled;
      },
    )
    .addCase(
      ReduxActionTypes.DYNAMIC_FORM_BUTTON_CLICK,
      (
        state: DynamicFormState,
        action: ReduxAction<
          ButtonClickPayload,
          typeof ReduxActionTypes.DYNAMIC_FORM_BUTTON_CLICK
        >,
      ) => {
        const buttonType = action.payload.buttonType;
        state.dynamicFormButtonLoading[buttonType] = true;
      },
    )
    .addCase(
      ReduxActionTypes.DYNAMIC_FORM_BUTTON_CLICK_SUCCESS,
      (
        state: DynamicFormState,
        action: ReduxAction<
          LoadButtonPayloadSuccess,
          typeof ReduxActionTypes.DYNAMIC_FORM_BUTTON_CLICK_SUCCESS
        >,
      ) => {
        const buttonType = action.payload.buttonType;
        state.dynamicFormButtonLoading[buttonType] = false;
        state.dynamicFormErrors = [];
      },
    )
    .addCase(
      ReduxActionErrorTypes.DYNAMIC_FORM_BUTTON_CLICK_ERROR,
      (
        state: DynamicFormState,
        action: ReduxAction<ButtonClickErrorPayload>,
      ) => {
        const buttonType = action.payload.buttonType;
        state.dynamicFormButtonLoading[buttonType] = false;
        state.dynamicFormErrors = action.payload.error
          ? [action.payload.error]
          : [];
      },
    ),
);

export default dynamicFormReducer;

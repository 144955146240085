import * as BackendTypes from "../backend-types";

// Moved into its own module because of a circular dependency
const getApiExecuteOnPageLoad = (
  api: undefined | BackendTypes.Api,
): boolean | undefined => {
  return api?.trigger?.application?.options?.executeOnPageLoad;
};

export default getApiExecuteOnPageLoad;

export const isValidUrl = (urlString: string) => {
  try {
    new URL(urlString);
    return true;
  } catch {
    return false;
  }
};

export const isRelativeURL = (url: string) => {
  let isRelativeURL = false;
  try {
    isRelativeURL =
      new URL(document.baseURI).origin ===
      new URL(url, document.baseURI).origin;
  } catch {
    return false;
  }
  return isRelativeURL;
};

import { capitalize } from "lodash";
import { getControlBlockTemplate } from "pages/Editors/ApiEditor/ControlEditor/getControlBlockTemplate";
import * as BackendTypes from "../backend-types";
import {
  BlockType,
  ControlBlock,
  ControlFlowFrontendDSL,
  GenericBlock,
  LoopControlBlock,
  ParallelControl,
} from "../control-flow/types";
import type { BlockExecutionInfo } from "../types";
import type {
  BlockExecutionContext,
  SharedExecutionOutput,
} from "store/slices/apisShared";

function isControlBlock(block: GenericBlock): block is ControlBlock {
  return block.type !== BlockType.STEP;
}

// This is a temporary solution until we have a standalone API for getting execution outputs
class ExecutionEventProcessor {
  private controlFlow: ControlFlowFrontendDSL;

  private blockInfos: Record<string, BlockExecutionInfo> = {};
  private handleUnknownBlockEvent?: (
    event: BackendTypes.ExecutionEvent,
  ) => void;

  private timestamps: Record<string, number> = {};
  private contexts: Record<
    string,
    {
      idxVar: string;
      currIdx: number;
      firstChild?: string; // the first child needs to claim this spot
      // IMPORTANT: we ASSUME that the first child will always be the same.
      // This is true given the available types of blocks we can create
      // but is not future proof.
    }
  > = {};
  private orderedExecutions: SharedExecutionOutput[] = [];

  constructor(
    controlFlow: ControlFlowFrontendDSL,
    blocksToReinitialize?: string[],
    handleUnknownBlockEvent?: (event: BackendTypes.ExecutionEvent) => void,
  ) {
    this.controlFlow = controlFlow;

    // it's important to initialize all the block infos because then we can distinguish between
    // blocks that existed at execution time but did not get run, and blocks that were added by the user
    // after the api ran.
    const blocksToReset =
      blocksToReinitialize ?? Object.keys(controlFlow.blocks);
    for (const blockName of blocksToReset) {
      this.getOrInitializeBlockInfo(blockName);
    }
    this.handleUnknownBlockEvent = handleUnknownBlockEvent;
  }

  // only a few block types can have multiple child executions
  // and only those blocks will create a new context
  private initializeBlockContext(block: GenericBlock) {
    switch (block.type) {
      case BlockType.LOOP: {
        const idxVar = (block as LoopControlBlock).config.variables.index;
        this.contexts[block.name] = {
          idxVar,
          currIdx: -1,
        };
        return;
      }
      case BlockType.PARALLEL: {
        const parallelConfig = block.config as ParallelControl;
        if (parallelConfig.dynamic) {
          const idxVar = "index"; // paralell does not have a user defined var
          this.contexts[block.name] = {
            idxVar,
            currIdx: -1,
          };
        }
        return;
      }
      case BlockType.STREAM: {
        this.contexts[block.name] = {
          idxVar: "index",
          currIdx: -1,
        };
        return;
      }
      default:
      // do nothing
    }
  }

  private clearBlockContext(block: GenericBlock) {
    if (block.name in this.contexts) {
      delete this.contexts[block.name];
    }
  }

  private getOrInitializeBlockInfo(blockName: string): BlockExecutionInfo {
    const blockExecutionInfo =
      this.blockInfos[blockName] ??
      ({
        executions: [],
        numFailed: 0,
        numPassed: 0,
        numStarted: 0,
        numHandled: 0,
      } as BlockExecutionInfo);
    this.blockInfos[blockName] = blockExecutionInfo;
    return blockExecutionInfo;
  }

  private getFullErrorMessage(
    error: BackendTypes.ExecutionError,
    block: GenericBlock,
  ) {
    let message = error.message;
    if (error.formPath) {
      let formName = error.formPath;
      if (isControlBlock(block)) {
        const template = getControlBlockTemplate({
          block,
          apiDSL: this.controlFlow,
        });
        formName =
          template.getComputedFields()[error.formPath]?.formItem.label ??
          formName;
      }
      message = `Error in ${capitalize(formName)}: ${error.message}`;
    }
    return message;
  }

  processEvent(event: BackendTypes.ExecutionEvent) {
    const { end, start } = event;
    const eventName = event.name;
    const block = this.controlFlow.blocks[event.name];
    if (!block) {
      this.handleUnknownBlockEvent?.(event);
      return;
    }

    const blockExecutionInfo = this.getOrInitializeBlockInfo(eventName);

    const parentContext = block.parentId && this.contexts[block.parentId];

    if (start) {
      this.initializeBlockContext(block);
      if (event.timestamp) {
        this.timestamps[block.name] = Date.parse(event.timestamp);
      }
      if (parentContext && parentContext.firstChild == null) {
        parentContext.firstChild = block.name;
      }
      if (parentContext && parentContext.firstChild === block.name) {
        parentContext.currIdx += 1;
      }
      blockExecutionInfo.numStarted = (blockExecutionInfo.numStarted ?? 0) + 1;
    }

    if (end) {
      this.clearBlockContext(block);
      // clear first, because we don't want our own context to be included
      const output = end.output?.result;
      const log = end.output?.stdout ?? [];
      const execution = end.performance?.execution;
      let executionTime: undefined | number =
        execution != null ? Number(execution) : undefined;
      const endTimestamp = Date.parse(event.timestamp);
      if (executionTime == null) {
        const startTimestamp = this.timestamps[block.name];
        if (startTimestamp && endTimestamp) {
          executionTime = endTimestamp - startTimestamp;
        }
      }
      delete this.timestamps[block.name];

      const request =
        end.output?.requestV2?.summary ?? end.output?.request ?? "";
      const fields = event.end?.resolved ?? {};

      const result: SharedExecutionOutput = {
        log,
        errorLog: end.output?.stderr,
        output,
        executionTime: executionTime ?? 0,
        request,
        placeholdersInfo: end.output?.requestV2?.metadata?.placeHoldersInfo,
        fields,
        blockName: block.name,
        endTimestamp,
        executionContext: Object.keys(this.contexts).reduce(
          (accum: BlockExecutionContext, blockName) => {
            accum[blockName] = {
              value: this.contexts[blockName].currIdx,
              label: this.contexts[blockName].idxVar,
            };
            return accum;
          },
          {},
        ),
      } satisfies SharedExecutionOutput;
      const error = end.error;
      if (error) {
        result.error = {
          message: error.message,
          formPath: error.formPath,
          fullErrorMessage: this.getFullErrorMessage(error, block),
          handled: error.handled,
        };
      }
      const hasError = result.error != null;
      this.orderedExecutions.push(result);
      blockExecutionInfo.executions.push(result);
      blockExecutionInfo.numFailed += hasError ? 1 : 0;
      blockExecutionInfo.numHandled +=
        hasError && result.error?.handled ? 1 : 0;
      blockExecutionInfo.numPassed += hasError ? 0 : 1;
    }
  }

  computeLastOutputs() {
    const lastOutputs: Record<string, SharedExecutionOutput> = {};
    for (const [blockName, blockExecutionInfo] of Object.entries(
      this.blockInfos,
    )) {
      const lastExecution =
        blockExecutionInfo.executions[blockExecutionInfo.executions.length - 1];
      if (lastExecution) {
        lastOutputs[blockName] = lastExecution;
      }
    }
    return lastOutputs;
  }

  getOrderedExecutions() {
    return this.orderedExecutions;
  }

  getBlockInfos() {
    return this.blockInfos;
  }
}

export default ExecutionEventProcessor;

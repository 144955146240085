import {
  ThrowControl,
  ThrowControlBlock,
} from "store/slices/apisV2/control-flow/types";
import {
  addBindingsToStringValue,
  removeBindingsFromStringValue,
} from "../../ControlFlow/utils";
import { BaseTemplate, BaseTemplateField } from "./BaseTemplate";
import {
  ControlFlowFormComponentType,
  ControlFlowFormItem,
} from "./FormTemplateTypes";

export default class ThrowTemplate extends BaseTemplate<ThrowControlBlock> {
  private getConfig(): Readonly<ThrowControl> {
    return this.source.config;
  }

  errorField(): BaseTemplateField<ThrowControlBlock, string> {
    const currentConfig = this.getConfig();
    const fieldValue = removeBindingsFromStringValue(currentConfig.error);

    const updateValue = (block: ThrowControlBlock, newValue: string) => {
      block.config.error = addBindingsToStringValue(newValue);
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Error to throw",
      componentType: ControlFlowFormComponentType.EXPRESSION_EDITOR,
      expectedValue: "string",
      exampleData: `An error occurred in ${this.source.name}`,
    };

    return {
      formItem,
      fieldValue,
      updateValue,
    };
  }

  computeFields() {
    return {
      error: this.errorField(),
    };
  }
}

import { DefaultTheme } from "styled-components";
import { theme as defaultTheme } from "legacy/constants/DefaultTheme";
import { theme as newTheme } from "legacy/themes/LightTheme";
import { getPalette } from "./antd/palette";
import { sharedAntdTheme } from "./antd/sharedAntdTheme";
import { colors } from "./colors";
import { textSizes, textWeights } from "./text";

// 10 or 11 elements
type ColorPalette = string[];

type BasicSizes = {
  large: string;
  medium: string;
  small: string;
};

type ExtendedSizes = BasicSizes & {
  extraSmall: string;
  tiny: string;
};

export type Theme = {
  palettes: {
    primary: ColorPalette;
    info: ColorPalette;
    success: ColorPalette;
    error: ColorPalette;
    warning: ColorPalette;
    gray: ColorPalette;
  };
  colors: typeof colors;
  text: {
    fonts: {
      base: string;
      code: string;
    };
    sizes: BasicSizes & {
      heading1: string;
      heading2: string;
      heading3: string;
      heading4: string;
      heading5: string;
    } & typeof textSizes;
    colors: {
      base: string;
      secondary: string;
      inverse: string;
    };
    lineHeights: {
      base: string;
    };
    weights: typeof textWeights;
  };
  border: {
    width: string;
    style: string;
    colors: {
      base: string;
      split: string;
      inverse: string;
    };
  };
  radiuses: {
    base: string;
  };
  shadows: {
    base: string;
  };
  outline: {
    offset: string;
    width: string;
    blurSize: string;
    fade: string;
  };
  heights: BasicSizes;
  paddings: ExtendedSizes;
  margins: ExtendedSizes;
};

export default async function getTheme(): Promise<DefaultTheme> {
  return {
    legacy: {
      ...defaultTheme,
      ...newTheme,
    },
    ...sharedAntdTheme,
    palettes: {
      primary: getPalette(colors.ACCENT_BLUE_500),
      info: getPalette(colors.ACCENT_BLUE_500),
      success: getPalette("#52c41a"),
      error: getPalette("#ff4d4f"),
      warning: getPalette("#faad14"),
      gray: [
        "#222",
        "#323232",
        "#424242",
        "#656565",
        "#828282",
        "#a2a2a2",
        "#b2b2b2",
        "#CACACA",
        "#DADADA",
        "#EDEDED",
        "#FCFCFC",
      ],
    },
    colors,
    text: {
      ...sharedAntdTheme.text,
      sizes: {
        ...sharedAntdTheme.text.sizes,
        ...textSizes,
      },
      weights: textWeights,
    },
    outline: {
      offset: "0 0",
      blurSize: "0",
      width: "2px",
      fade: "20%",
    },
    border: {
      width: "1px",
      style: "solid",
      colors: {
        base: "#e8eaed",
        split: "#f0f0f0",
        inverse: "#fff",
      },
    },
  };
}

import { ScopedDataTreePath } from "legacy/entities/DataTree/dataTreeFactory";
import { DependencyMap } from "legacy/utils/DynamicBindingTypes";

function invertPropertyEdges(
  propertyPath: string,
  incomingEdges: string[],
  currentInverse: DependencyMap,
) {
  incomingEdges.forEach((edge) => {
    const node = currentInverse[edge];
    if (node) {
      node.push(propertyPath);
    } else {
      currentInverse[edge] = [propertyPath];
    }
  });
}

/**
 * Inverts a dependency map.
 * @param dependencies The list of dependencies to invert. If undefined, all dependencies are inverted.
 * @param dependencyMap The dependency map to invert.
 * @param currentInverse An existing dependency map to add the inverted dependencies to.
 * @returns The inverted dependency map.
 */
function invertDependencies<D extends string = string>(
  dependencies: D[] | undefined,
  dependencyMap: DependencyMap<D>,
  currentInverse: DependencyMap<D> = {} as DependencyMap<D>,
): DependencyMap<D> {
  if (dependencies === undefined) {
    // invert the entire dependency map
    for (const propertyPath in dependencyMap) {
      invertPropertyEdges(
        propertyPath,
        dependencyMap[propertyPath],
        currentInverse,
      );
    }
  } else {
    // invert only the specified dependencies
    dependencies.forEach((propertyPath) => {
      const incomingEdges = dependencyMap[propertyPath];
      if (incomingEdges) {
        invertPropertyEdges(propertyPath, incomingEdges, currentInverse);
      }
    });
  }
  return currentInverse;
}

function removeDependencies(
  dependencies: ScopedDataTreePath[],
  dependencyMap: DependencyMap,
  currentInverse: DependencyMap = {},
): DependencyMap {
  dependencies.forEach((propertyPath) => {
    const incomingEdges: Array<string> = dependencyMap[propertyPath];
    if (incomingEdges) {
      incomingEdges.forEach((edge) => {
        const node = currentInverse[edge];
        if (node) {
          const index = node.indexOf(propertyPath);
          if (index > -1) {
            node.splice(index, 1);
          }
        }
      });
    }
    delete currentInverse[propertyPath];
  });
  return currentInverse;
}

export { removeDependencies };
export default invertDependencies;

import { defineAction } from "../../utils/action";
import slice from "./slice";

export const markPageLoadApis = defineAction<string[]>(
  slice.name,
  "markPageLoadApis",
);

export const copyStepOutput = defineAction<{
  apiId: string;
  oldName: string;
  newName: string;
}>(slice.name, "copyStepOutput");

export const clearResponseApi = defineAction<{ id: string }>(
  slice.name,
  "clearResponseApi",
);

/**
 * We need to clear dirty state outside of persistApi's start reducer. This is necessary
 * because the start reducer is called whenever updateApi.success.type is fired, however
 * persistApi itself is throttled. Therefore, we should remove dirty state only when
 * we fire the persistApi and not when we end up throttling it.
 */
export const clearDirtyState = defineAction<{ id: string }>(
  slice.name,
  "clearDirtyState",
);

export const setTestProfile = defineAction<{ apiId: string; profile: string }>(
  slice.name,
  "setTestProfile",
);

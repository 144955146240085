import { CODE_SYMBOL, HANDLING_STREAMING_MESSAGES } from "./shared";

const STREAMING_INTEGRATION_DOC_LINK =
  "https://docs.superblocks.com/applications/streaming-applications/overview#connect-streaming-integrations";

export const STREAM_DOCUMENTATION = `
# Stream Block
The **Stream** block is used to read from data streams. With the Stream block, you can: 
- Read messages from a [Streaming Integration](${STREAMING_INTEGRATION_DOC_LINK}) using the **Trigger** section
- Apply custom business logic to each message using the **Process** section
- Send messages to the ${CODE_SYMBOL}onMessage${CODE_SYMBOL} frontend event handler in Applications

## Trigger
The **Trigger** section of the Stream block is used to connect to your [Streaming Integration](${STREAMING_INTEGRATION_DOC_LINK}). To use this section:
To use this section:
1. Add a block for your [Streaming Integration](${STREAMING_INTEGRATION_DOC_LINK})
2. Select the relevant action to read messages from your [Streaming Integration](${STREAMING_INTEGRATION_DOC_LINK})

The Stream block will execute each time the **Trigger** section receives a **message** from the Streaming integration.
- Each **message** is sent through to any subsequent **Process** blocks, and can be accessed with ${CODE_SYMBOL}message.value${CODE_SYMBOL}
- If there are no **Process** blocks configured, each **message** will be automatically sent to the API's ${CODE_SYMBOL}onMessage${CODE_SYMBOL} event handler (unless you have disabled automatically sending messages to client)

## Process
The **Process** section of the Stream block is used to process each message received in the **Trigger** block. **Processing messages is optional.**

To process messages received from your Streaming Integration:
1. Enable the ${CODE_SYMBOL}Process Messages${CODE_SYMBOL} setting on the Stream block
2. Add any number of logical blocks to your process section

**Notes:**
- You can access the current message using ${CODE_SYMBOL}message.value${CODE_SYMBOL} (or whatever you have renamed the message to)
- Be sure to **return a value from the final block in your process section** - this value will be sent to the ${CODE_SYMBOL}onMessage${CODE_SYMBOL} event handler automatically (unless you have disabled automatically sending messages to client)
${HANDLING_STREAMING_MESSAGES}
`;

import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { PUBLIC_INTEGRATIONS_CDN_URL, getNativeOpenApiPlugin } from './shared';

export const BoxPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Box',
  id: ExtendedIntegrationPluginId.BOX,
  authMethods: {
    oauth2BringYourOwn: {
      authorizationUrl: 'https://account.box.com/api/oauth2/authorize',
      tokenUrl: 'https://api.box.com/oauth2/token',
      revokeTokenUrl: 'https://api.box.com/oauth2/revoke',
      iconUrl: `${PUBLIC_INTEGRATIONS_CDN_URL}/img/box.png`,
      authorizationExtraParams: {
        responseType: 'code'
      }
    }
  },
  serverURL: 'https://api.box.com/2.0'
});

import { WidgetTypes, type WidgetType } from "legacy/constants/WidgetConstants";

const prefix = WidgetTypes.CUSTOM_WIDGET + ":";

/** `id` is the UUID of the custom component from the custom component's config file */
export function customComponentType(id: string) {
  if (typeof id !== "string" || id.length === 0) {
    throw new Error(`id must be a nonempty string: ${JSON.stringify(id)}`);
  }
  return prefix + id;
}

export function isCustomComponentType(type: string): type is WidgetType {
  if (typeof type !== "string") {
    throw new Error(`type must be a string: ${JSON.stringify(type)}`);
  }
  return type.startsWith(prefix);
}

export function customComponentId(type: WidgetType): string {
  if (!isCustomComponentType(type)) {
    throw new Error(`type must be a custom component type: ${type}`);
  }
  return type.slice(prefix.length);
}

import {
  findBlockLocation,
  getBlockChildListLocators,
  getBlockListFromLocator,
} from "./locators";
import {
  BlockType,
  GenericBlock,
  LoopControl,
  VariablesControl,
  ParallelControl,
  TryCatchControl,
  ControlFlowFrontendDSL,
  StreamControl,
} from "./types";

export const getBlockGeneratedVariables = (
  block: GenericBlock,
): { [identifier: string]: string } => {
  const { config, type } = block;

  switch (type) {
    case BlockType.STEP: {
      return {};
    }
    case BlockType.BREAK: {
      return {};
    }
    case BlockType.THROW: {
      return {};
    }
    case BlockType.RETURN: {
      return {};
    }
    case BlockType.WAIT: {
      return {};
    }
    case BlockType.PARALLEL: {
      const controlConfig = config as ParallelControl;
      if (controlConfig.dynamic) {
        return {
          "parallel-dynamic-paths": controlConfig.dynamic.variables.item,
        };
      }
      return {};
    }
    case BlockType.CONDITION: {
      return {};
    }
    case BlockType.LOOP: {
      const controlConfig = config as LoopControl;
      return {
        "loop-index": controlConfig.variables.index,
        "loop-value": controlConfig.variables.item,
      };
    }
    case BlockType.TRY_CATCH: {
      const controlConfig = config as TryCatchControl;
      return {
        "try-catch-error": controlConfig.variables.error,
      };
    }
    case BlockType.VARIABLES: {
      const controlConfig = config as VariablesControl;
      return Object.fromEntries(
        controlConfig.variables.map((variable) => [variable.key, variable.key]),
      );
    }
    case BlockType.SEND: {
      return {};
    }
    case BlockType.STREAM: {
      const controlConfig = config as StreamControl;
      return {
        "stream-item": controlConfig.variables.item,
      };
    }
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(`Unhandled type: ${exhaustiveCheck}`);
    }
  }
};

type VarRefactors = Array<{
  oldName: string;
  newName: string;
  affectedBlockNames: string[];
}>;

const getScopeTypeFromBlock = (block: GenericBlock): "child" | "sibling" => {
  return block.type === BlockType.VARIABLES ? "sibling" : "child";
};

const getAffectedBlocks = (params: {
  controlFlow: ControlFlowFrontendDSL;
  oldName: string;
  originatingBlock: string;
}): string[] => {
  const { controlFlow, oldName, originatingBlock } = params;
  const rootScopeType = getScopeTypeFromBlock(
    controlFlow.blocks[originatingBlock],
  );
  const blocks: string[] = [];
  const dfs = (currBlock: string): void | "IGNORE_RIGHT" => {
    const block = controlFlow.blocks[currBlock];
    const scopeType = getScopeTypeFromBlock(block);

    // the originating block does not get affected by its own variable renames
    // e.g. if Loop2 changes its "index" to "index2", it might still be in scope of a "index" from Loop1
    if (currBlock !== originatingBlock) {
      blocks.push(currBlock);
    }

    let ignoreRight = false;
    const thisBlockVars = new Set(
      Object.values(getBlockGeneratedVariables(block)),
    );
    if (thisBlockVars.has(oldName)) {
      if (scopeType === "child") {
        return; // currBlock is providing "coverage" for oldName. It's ambiguous whether we should refactor here, so dont
      } else {
        ignoreRight = true;
      }
    }

    const innerLocators = getBlockChildListLocators(block);
    innerLocators.forEach((locator) => {
      // e.g. first iteration here could be for if branch, second could be for else
      const blockList = getBlockListFromLocator(controlFlow, locator);
      for (const childBlockName of blockList) {
        const shouldIgnoreSiblings = dfs(childBlockName) === "IGNORE_RIGHT";
        if (shouldIgnoreSiblings) {
          break;
        }
      }
    });

    if (ignoreRight) {
      return "IGNORE_RIGHT";
    }
  };

  if (rootScopeType === "child") {
    dfs(originatingBlock);
  } else {
    const originatingLocation = findBlockLocation(
      controlFlow,
      originatingBlock,
    );
    if (!originatingLocation) {
      return blocks;
    }
    const relevantList = getBlockListFromLocator(
      controlFlow,
      originatingLocation,
    ).slice(originatingLocation.idx);
    for (const blockName of relevantList) {
      const shouldIgnoreSiblings = dfs(blockName) === "IGNORE_RIGHT";
      if (shouldIgnoreSiblings) {
        break;
      }
    }
  }
  return blocks;
};

/*
 *  General approach to variable name refactoring:
 *    In the simple case, if a block-controlled variable changes name, any blocks that use
 *    that variable will have their references refactored. e.g. if a Loop changes its "index" variable
 *    to "index2", then any blocks that use "index" will have their references refactored to "index2".
 *
 *  Some edge cases:
 *    - We will only refactor within blocks that are in scope of the originating block's variables
 *    - If a block has multiple variables in scope of the same name and one is refactored, we will do nothing
 *    - If multiple variables change at the same time and they don't have a static identifier
 *      (only applicable to variable block variables as of this comment),
 *      then we do nothing. e.g. if "foo" and "bar" change to "x" and "y", we don't know which one became which.
 */
export const getGeneratedNameRefactors = (
  prevBlock: GenericBlock,
  nextBlock: GenericBlock,
  controlFlow: ControlFlowFrontendDSL,
): VarRefactors => {
  const prevGeneratedVars = getBlockGeneratedVariables(prevBlock);
  const nextGeneratedVars = getBlockGeneratedVariables(nextBlock);
  if (prevBlock.type !== nextBlock.type) {
    return [];
  }

  if (prevBlock.type !== BlockType.VARIABLES) {
    const refactors: VarRefactors = [];
    Object.keys(prevGeneratedVars).forEach((identifier) => {
      const prevName = prevGeneratedVars[identifier];
      const nextName = nextGeneratedVars[identifier];
      // dont refactor if the name went from falsy to truthy or vice versa
      if (prevName !== nextName && prevName && nextName) {
        refactors.push({
          oldName: prevName,
          newName: nextName,
          affectedBlockNames: getAffectedBlocks({
            controlFlow,
            oldName: prevName,
            originatingBlock: prevBlock.name,
          }),
        });
      }
    });
    return refactors;
  }

  // Special handling for variables
  if (
    Object.keys(prevGeneratedVars).length !==
    Object.keys(nextGeneratedVars).length
  ) {
    return []; // there's not a good way to know what happened here, so give up.
  }

  const prevSet = new Set(Object.values(prevGeneratedVars));
  const nextSet = new Set(Object.values(nextGeneratedVars));
  const inPrevButNotNext = new Set([...prevSet].filter((x) => !nextSet.has(x)));
  const inNextButNotPrev = new Set([...nextSet].filter((x) => !prevSet.has(x)));

  // its only obvious what to do if 1 name changed.
  if (inPrevButNotNext.size !== 1 || inNextButNotPrev.size !== 1) {
    return [];
  }
  const oldName = [...inPrevButNotNext][0];
  const newName = [...inNextButNotPrev][0];

  return [
    {
      oldName,
      newName,
      affectedBlockNames: getAffectedBlocks({
        controlFlow,
        oldName,
        originatingBlock: prevBlock.name,
      }),
    },
  ];
};

import { Toaster } from "legacy/components/ads/Toast";
import { Variant } from "legacy/components/ads/common";
import {
  LOCAL_STORAGE_NO_SPACE_LEFT_ON_DEVICE_MESSAGE,
  LOCAL_STORAGE_QUOTA_EXCEEDED_MESSAGE,
} from "legacy/constants/messages";
import logger from "utils/logger";

const getLocalStorage = () => {
  let storage: Storage | null = null;
  try {
    storage = window.localStorage;
  } catch (e) {
    logger.warn(`local storage disabled: ${e}`);
  }

  const handleError = (e: Error) => {
    let message;
    if (e.name === "QuotaExceededError") {
      message = LOCAL_STORAGE_QUOTA_EXCEEDED_MESSAGE;
    } else if (e.name === "NS_ERROR_FILE_NO_DEVICE_SPACE") {
      message = LOCAL_STORAGE_NO_SPACE_LEFT_ON_DEVICE_MESSAGE;
    }

    if (message) {
      Toaster.show({
        text: message,
        variant: Variant.danger,
      });
    } else {
      throw e;
    }
  };

  const getItem = (key: string): string | null => {
    if (!storage) {
      return null;
    }
    try {
      return storage.getItem(key);
    } catch (e) {
      handleError(e as Error);
    }
    return null;
  };

  const setItem = (key: string, value: string) => {
    if (!storage) {
      return;
    }
    try {
      storage.setItem(key, value);
    } catch (e) {
      handleError(e as Error);
    }
  };

  const getInt = (key: string): number | null => {
    const val = localStorage.getItem(key);
    if (val === null) {
      return val;
    }
    const parsed = parseInt(val);
    return isNaN(parsed) ? null : parsed;
  };

  const getFloat = (key: string): number | null => {
    const val = localStorage.getItem(key);
    if (val === null) {
      return val;
    }
    const parsed = parseFloat(val);
    return isNaN(parsed) ? null : parsed;
  };

  const removeItem = (key: string) => {
    if (!storage) {
      return;
    }
    try {
      storage.removeItem(key);
    } catch (e) {
      handleError(e as Error);
    }
  };

  const isSupported = () => !!window.localStorage;

  return {
    getItem,
    getInt,
    getFloat,
    setItem,
    removeItem,
    isSupported,
  };
};

const localStorage = getLocalStorage();

export default localStorage;

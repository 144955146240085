import { get, isEmpty, set } from "lodash";
import recursiveGenerateTypeDef from "./recursiveGenerateTypeDef";
import type {
  TypeDefinitionNode,
  TypeInfo,
  DefInfoMap,
  TypeDef,
  TypeInfoDocType,
} from "./types";

type DefinitionOptions = {
  doc?: string;
  markdownDoc?: string;
  icon?: string; // icon path
  type?: string | "fn()";
  customFnDef?: string;
  defaultSignature?: string;
  value?: unknown;
};

/**
 *  This is a utility class for creating customized autocomplete suggestions
 *  It was originally made for Control Flow, but should be useful for any situation
 *  where autocomplete suggestions need to be dynamically generated.
 *
 *  Example usage:
 *  const rootDef = ExpandedDefCreator.newDef();
 *  const fooDef = rootDef.addDefinition("foo", { doc: "foo is a variable" })
 *  fooDef.addDefinition("baz", { doc: "baz is a variable" }).suggestExpressionInRoot();
 *  fooDef.addDefinition("bar", { doc: "bar is a function" }).suggestInvocationInRoot();
 *  const dataTree = rootDef.generateTypeDef();
 *
 *  When dataTree gets passed to EntityCompletion/TernServer/PythonCompletion, this results in the following suggestions:
 *  - foo
 *    - .baz
 *    - .bar
 *  - foo.baz
 *  - foo.bar()
 */
export class ExpandedDefCreator {
  private readonly keyPath: string[] = [];
  private readonly rootCreator: ExpandedDefCreator;
  private readonly options: undefined | DefinitionOptions;
  private readonly defaults: {
    icon?: string;
    baseRanks?: Array<string | number>;
  } = {};
  private readonly extraDefs: TypeInfo = {};
  private readonly sortRanks: Array<string | number> = [];

  private shortcutChildren: Array<{
    child: ExpandedDefCreator;
    fnSignature?: string;
  }> = [];
  private hideSuggestion?: boolean;
  private children: { [key: string]: ExpandedDefCreator } = {};
  private value?: unknown;
  private computedDef?: TypeDefinitionNode;

  public static newDef(options?: {
    icon?: string;
    baseRanks?: Array<string | number>;
  }): ExpandedDefCreator {
    const creator = new ExpandedDefCreator(undefined, options);
    return creator;
  }

  private getRootDefaults() {
    return this.rootCreator.defaults;
  }

  private constructor(
    params?: {
      keyPath: string[];
      rootCreator: ExpandedDefCreator;
      options: DefinitionOptions;
      sortRanks: Array<string | number>;
    },
    defaults?: {
      icon?: string;
      baseRanks?: Array<string | number>;
    },
  ) {
    if (defaults) {
      this.defaults = defaults;
    }
    const { keyPath, rootCreator, options, sortRanks } = params ?? {};
    this.keyPath = keyPath ?? [];
    this.rootCreator = rootCreator ?? this;
    this.options = options;
    this.sortRanks = sortRanks ?? [];
  }

  // add the items in typeDef to result (our in-progress Definition) if they weren't
  // already created via child creators.
  private addDerivedTypeDefs(
    typeDef?: TypeDef,
    value?: unknown,
    result: TypeInfo = {},
  ) {
    if (value !== undefined) {
      result["!doc"] ??= { value: value } as TypeInfoDocType;
    }

    if (typeof typeDef === "string" && result["!type"] == null) {
      result["!type"] = typeDef;
    }
    if (typeDef != null && typeof typeDef === "object") {
      for (const [key, childDef] of Object.entries(typeDef)) {
        if (key in result) continue;
        (result as DefInfoMap)[key] = {};
        this.addDerivedTypeDefs(
          childDef as TypeDef,
          get(value, key),
          (result as DefInfoMap)[key],
        );
      }
    }
  }

  private computeDef(typeDef: undefined | TypeDef): TypeDefinitionNode {
    const result = this.options
      ? ({
          "!doc": {
            docString: this.options.doc ?? "",
            expanded: {
              customFnDef: this.options.customFnDef,
              icon: this.options.icon ?? this.getRootDefaults().icon,
              hideSuggestion: this.hideSuggestion,
              sortRanks: (this.getRootDefaults().baseRanks ?? []).concat(
                this.sortRanks,
              ),
              markdownDoc: this.options.markdownDoc,
            },
          },
        } as TypeInfo)
      : {};

    if (this.rootCreator !== this) {
      const defValue = this.value;
      if (defValue !== undefined) {
        if (result["!doc"]) {
          result["!doc"].value = defValue;
        } else {
          result["!doc"] = { value: defValue } as TypeInfoDocType;
        }
      }

      let defType: TypeDef | undefined = this.options?.type;
      if (!defType) {
        const functionInfo = this.options?.customFnDef;
        const typeDefIsUnknown = typeDef === undefined || typeDef === "?";
        if (functionInfo && typeDefIsUnknown) {
          defType = "fn()";
        } else {
          defType = typeDef;
        }
      }

      if (defType !== undefined && typeof defType === "string") {
        result["!type"] = defType;
      }
    }
    for (const [key, child] of Object.entries(this.children)) {
      const childType = (
        typeDef != null && typeof typeDef === "object"
          ? typeDef[key]
          : undefined
      ) as TypeDef;
      (result as DefInfoMap)[key] = child.computeDef(childType);
    }
    this.addDerivedTypeDefs(typeDef, this.value, result);

    // now that child defs have been computed, we can go through and materialize the defs for the shortcut children
    if (result["!doc"]?.expanded && this.shortcutChildren.length > 0) {
      result["!doc"].expanded.expandedShortcuts ??= [];
      const expandedShortcuts = result["!doc"].expanded.expandedShortcuts;

      for (const { child, fnSignature } of this.shortcutChildren) {
        if (child.computedDef) {
          expandedShortcuts.push({
            ...child.computedDef,
            keyPath: child.keyPath,
            fnSignature,
          });
        }
      }
    }

    this.computedDef = result as TypeDefinitionNode;
    return result as TypeDefinitionNode;
  }

  private precomputeValues(rootValue: object) {
    set(rootValue, this.keyPath, this.options?.value);
    for (const child of Object.values(this.children)) {
      child.precomputeValues(rootValue);
    }
    const val = get(rootValue, this.keyPath);
    this.value = val;
  }

  private getBaseDefCreator(): undefined | ExpandedDefCreator {
    const baseDef = this.rootCreator?.children?.[this.keyPath[0]];
    return baseDef;
  }

  private addExpandedShortcut(child: ExpandedDefCreator, fnSignature?: string) {
    this.shortcutChildren.push({ child, fnSignature });
    this.hideSuggestion = true;
  }

  public computeRootDef(): DefInfoMap {
    const rootValue = {};
    const extraDefs = {};
    this.rootCreator.precomputeValues(rootValue);
    const rootType = recursiveGenerateTypeDef(rootValue, extraDefs);

    // the root will not have !doc etc, so we can assert this is a DefInfoMap
    const rootDef = this.rootCreator.computeDef(rootType) as DefInfoMap;

    // Add any extra defs that were generated
    if (!isEmpty(extraDefs)) {
      // instead of spreading we just add the extra defs to the rootDef for performance reasons
      rootDef["!define"] ??= {};
      Object.assign(rootDef["!define"], extraDefs);
    }

    return rootDef;
  }

  public addDefinition(
    name_: string,
    publicOptions?: DefinitionOptions & {
      rank?: string | number | Array<string | number>;
    },
  ) {
    const isFunction =
      publicOptions?.type === "fn()" || !!publicOptions?.customFnDef;
    const name = isFunction ? `${name_}()` : name_;

    const { rank = [name], ...options } = publicOptions ?? {};
    if (isFunction && !options.defaultSignature) {
      options.defaultSignature = "()";
    }
    const newRank = [
      ...this.sortRanks,
      ...(Array.isArray(rank) ? rank : [rank]),
    ];

    const child = new ExpandedDefCreator({
      keyPath: [...this.keyPath, name],
      rootCreator: this.rootCreator,
      options: options,
      sortRanks: newRank,
    });
    this.children[name] = child;

    return child;
  }

  public suggestExpressionInRoot() {
    const baseDef = this.getBaseDefCreator();
    if (baseDef) {
      baseDef.addExpandedShortcut(this);
    }
  }

  // consider allowing overriding options
  public suggestInvocationInRoot() {
    const baseDef = this.getBaseDefCreator();
    if (baseDef) {
      baseDef.addExpandedShortcut(this, this.options?.defaultSignature);
    }
    if (this.options) {
      this.options.type ??= "fn()";
    }
  }
}

import { createReducer } from "@reduxjs/toolkit";
import { ReduxAction } from "legacy/constants/ReduxActionConstants";
import renameApplicationEntitySaga, {
  RenameApplicationEntityPayload,
} from "store/sagas/renameApplicationEntity";

export interface ExplorerReduxState {
  updatingEntity?: string;
  updateEntityError?: string;
}
const initialState: ExplorerReduxState = {};

const explorerReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(
      renameApplicationEntitySaga.start.type,
      (
        state: ExplorerReduxState,
        action: ReduxAction<RenameApplicationEntityPayload>,
      ): ExplorerReduxState => {
        return { ...state, updatingEntity: action.payload.entityId };
      },
    )
    .addCase(renameApplicationEntitySaga.error.type, (state) => ({
      updatingEntity: undefined,
      updateEntityError: state.updatingEntity,
    }))
    .addCase(renameApplicationEntitySaga.success.type, () => ({})),
);

export default explorerReducer;

import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { createImmerReducer } from "../createReducer";

export type LoadingEntitiesState = Record<string, boolean>;

const initialState: LoadingEntitiesState = {};

const loadingEntitiesReducer = createImmerReducer(initialState, (builder) =>
  builder
    .addCase(
      ReduxActionTypes.SET_LOADING_ENTITIES,
      (
        state: LoadingEntitiesState,
        action: ReduxAction<
          Record<string, boolean>,
          typeof ReduxActionTypes.SET_LOADING_ENTITIES
        >,
      ) => {
        const hasStoppedLoading = new Set(Object.keys(state));
        Object.entries(action.payload).forEach(([key, value]) => {
          state[key] = value;
          hasStoppedLoading.delete(key);
        });
        hasStoppedLoading.forEach((key) => {
          delete state[key];
        });
      },
    )
    .addCase(ReduxActionTypes.FETCH_PAGE_INIT, () => initialState),
);

export default loadingEntitiesReducer;

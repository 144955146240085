import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const AsanaPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Asana',
  id: ExtendedIntegrationPluginId.ASANA,
  authMethods: {
    bearer: true
  },
  serverURL: 'https://app.asana.com/api/1.0'
});

import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { PUBLIC_INTEGRATIONS_CDN_URL, getNativeOpenApiPlugin } from './shared';

export const ZoomPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Zoom',
  id: ExtendedIntegrationPluginId.ZOOM,
  authMethods: {
    oauth2BringYourOwn: {
      authorizationUrl: 'https://zoom.us/oauth/authorize',
      tokenUrl: 'https://zoom.us/oauth/token',
      revokeTokenUrl: 'https://zoom.us/oauth/revoke',
      iconUrl: `${PUBLIC_INTEGRATIONS_CDN_URL}/img/zoom.png`,
      authorizationExtraParams: {
        responseType: 'code'
      }
    }
  },
  serverURL: 'https://api.zoom.us/v2'
});

import { Dimension } from "@superblocksteam/shared";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import {
  getApplicableMaxWidth,
  getApplicableMinWidth,
} from "./canvasWidthUtil";
import type {
  FlattenedWidgetLayoutProps,
  WidgetLayoutProps,
} from "../../shared";

export function getWidgetWidth(
  widget: WidgetLayoutProps | FlattenedWidgetLayoutProps,
) {
  const parentColumnSpace =
    widget.parentColumnSpace ?? GridDefaults.DEFAULT_GRID_COLUMNS;
  if (
    (widget.width.mode === "fitContent" ||
      widget.width.mode === "fillParent") &&
    widget.dynamicWidgetLayout?.width != null
  ) {
    const minDynamicWidth = GridDefaults.FILL_PARENT_DEFAULT_MIN_WIDTH_PX;
    return Math.max(widget.dynamicWidgetLayout.width.value, minDynamicWidth);
  }

  let widthPx = Dimension.toPx(widget.width, parentColumnSpace).value;
  const minWidth = getApplicableMinWidth(widget);
  const maxWidth = getApplicableMaxWidth(widget);

  if (minWidth) {
    const minWidthPx = Dimension.toPx(minWidth, parentColumnSpace).value;
    widthPx = Math.max(widthPx, minWidthPx);
  }
  if (maxWidth) {
    const maxWidthPx = Dimension.toPx(maxWidth, parentColumnSpace).value;
    widthPx = Math.min(widthPx, maxWidthPx);
  }

  return widthPx;
}

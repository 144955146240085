import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const GroqPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Groq',
  id: ExtendedIntegrationPluginId.GROQ,
  authMethods: {
    bearer: true
  },
  serverURL: 'https://api.groq.com/openai',
  isStreamable: true
});

import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const CoherePlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Cohere',
  id: ExtendedIntegrationPluginId.COHERE,
  authMethods: {
    bearer: true
  },
  serverURL: 'https://api.cohere.com/v1',
  isStreamable: true
});

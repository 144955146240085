import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const CircleCIPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'CircleCI',
  id: ExtendedIntegrationPluginId.CIRCLECI,
  authMethods: {
    apiKeyForm: [{ label: 'Token', header: 'Circle-Token', key: 'token' }]
  },
  serverURL: 'https://circleci.com/api/v2'
});

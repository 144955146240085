import { colors as appColors } from "../../styles/colors";
import defaultStyles from "./defaultStyles";
import { AppTheme } from "./types-legacy";

const colors = {
  ...appColors,

  PURPLE_DARKER: "#0c659c",
};

export const theme: AppTheme = {
  appColors: {
    main: {
      theme: colors.ACCENT_BLUE_500,
    },
    app: {
      editor: {
        background: appColors.GREY_25,
      },
    },
    buttons: {
      variants: {
        primary: {
          normal: {
            border: colors.ACCENT_BLUE_500,
            content: colors.WHITE,
            background: colors.ACCENT_BLUE_500,
          },
          hover: {
            border: colors.PURPLE_DARKER,
            content: colors.WHITE,
            background: colors.PURPLE_DARKER,
          },
          active: {
            border: colors.PURPLE_DARKER,
            content: colors.WHITE,
            background: colors.PURPLE_DARKER,
          },
        },
        secondary: {
          normal: {
            border: colors.ACCENT_BLUE_500,
            content: colors.ACCENT_BLUE_500,
            background: appColors.NONE,
          },
          hover: {
            border: colors.PURPLE_DARKER,
            content: colors.PURPLE_DARKER,
            background: colors.WHITE,
          },
          active: {
            border: colors.PURPLE_DARKER,
            content: colors.PURPLE_DARKER,
            background: appColors.NONE,
          },
        },
        tertiary: {
          normal: {
            border: appColors.NONE,
            content: colors.ACCENT_BLUE_500,
            background: appColors.NONE,
          },
          hover: {
            border: appColors.NONE,
            content: colors.PURPLE_DARKER,
            background: appColors.NONE,
          },
          active: {
            border: appColors.NONE,
            content: colors.PURPLE_DARKER,
            background: appColors.NONE,
          },
        },
        error: {
          normal: {
            border: appColors.NONE,
            content: colors.ACCENT_BLUE_500,
            background: appColors.NONE,
          },
          hover: {
            border: appColors.NONE,
            content: colors.PURPLE_DARKER,
            background: appColors.NONE,
          },
          active: {
            border: appColors.NONE,
            content: colors.PURPLE_DARKER,
            background: appColors.NONE,
          },
        },
      },
      lightning: {
        normal: {
          icon: appColors.GREY_300,
          background: colors.WHITE,
        },
        active: {
          icon: appColors.GREY_400,
          background: appColors.GREY_100,
        },
      },
    },
    textField: {
      normal: {
        background: colors.WHITE,
        border: appColors.GREY_100,
        text: appColors.GREY_800,
      },
      active: {
        background: colors.WHITE,
        border: appColors.GREY_100,
        text: appColors.GREY_800,
      },
      error: {
        border: appColors.DANGER,
      },
      placeholder: colors.GREY_200,
      cursor: appColors.GREY_300,
    },
    autocomplete: {
      normal: {
        text: appColors.GREY_800,
        background: colors.WHITE,
      },
      active: {
        text: appColors.GREY_800,
        background: appColors.SUBTLE_BLUE_SOLID,
      },
    },
    evalPane: {
      background: colors.WHITE,
    },
    table: {
      background: appColors.NONE,
      border: colors.GREY_100,
      text: colors.GREY_800,
    },
    container: {
      selected: {
        border: appColors.GREY_100,
      },
    },
  },
  appStyles: defaultStyles,
};

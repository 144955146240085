import { DefaultTheme } from "styled-components";

export interface CommonComponentProps {
  isLoading?: boolean; //default false
  cypressSelector?: string;
  className?: string;
  disabled?: boolean; //default false
}

export type ThemeProp = {
  theme: DefaultTheme;
};

export enum Classes {
  ICON = "cs-icon",
  APP_ICON = "cs-app-icon",
  TEXT = "cs-text",
  SPINNER = "cs-spinner",
}

export enum Variant {
  success = "success",
  info = "info",
  warning = "warning",
  danger = "danger",
}

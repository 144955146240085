import {
  ConditionControlBlock,
  ConditionControl,
  ConditionSection,
  BlockType,
} from "store/slices/apisV2/control-flow/types";
import { BLOCK_HELP_CONTENT } from "../../ControlFlow/common-block-type-info";
import {
  addBindingsToStringValue,
  removeBindingsFromStringValue,
} from "../../ControlFlow/utils";
import { BaseTemplate, BaseTemplateField } from "./BaseTemplate";
import {
  ControlFlowFormComponentType,
  ControlFlowFormItem,
  FormComponentType,
} from "./FormTemplateTypes";

const CONDITION_DATA_FORMAT = "boolean";

export default class ConditionTemplate extends BaseTemplate<ConditionControlBlock> {
  private getConfig(): Readonly<ConditionControl> {
    return this.source.config;
  }

  conditionField(): BaseTemplateField<ConditionControlBlock, string> {
    const currentConfig = this.getConfig();
    const fieldValue = removeBindingsFromStringValue(
      currentConfig.if.condition,
    );

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "If condition",
      componentType: ControlFlowFormComponentType.EXPRESSION_EDITOR,
      expectedValue: CONDITION_DATA_FORMAT,
      controlFlowTooltip:
        BLOCK_HELP_CONTENT[BlockType.CONDITION].fieldHelpContent.condition,
    };

    const updateValue = (block: ConditionControlBlock, newValue: string) => {
      block.config.if.condition = addBindingsToStringValue(newValue);
    };

    return {
      formItem,
      fieldValue,
      updateValue,
    };
  }

  elseIfField(): BaseTemplateField<
    ConditionControlBlock,
    Array<ConditionSection>
  > {
    const currentConfig = this.getConfig();
    const fieldValue = currentConfig.elseIf
      ? currentConfig.elseIf.map((conditionConfig: ConditionSection) => {
          return {
            condition: removeBindingsFromStringValue(conditionConfig.condition),
            blocks: conditionConfig.blocks,
          };
        })
      : [];

    const updateValue = (
      block: ConditionControlBlock,
      newValue: Array<Partial<ConditionSection>>,
    ) => {
      const newElseIf = newValue.map(({ condition, blocks }) => {
        return {
          blocks: blocks ?? [],
          condition: addBindingsToStringValue(condition ?? ""),
        };
      });
      block.config.elseIf = newElseIf;
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Else if",
      componentType: ControlFlowFormComponentType.EXPRESSION_EDITOR_LIST,
      valuePath: "condition",
      addLabel: "Else if",
      expectedValue: CONDITION_DATA_FORMAT,
      controlFlowTooltip:
        BLOCK_HELP_CONTENT[BlockType.CONDITION].fieldHelpContent.condition,
      getShowConfirmOnDelete: (index: number) => {
        const value = fieldValue[index];
        return value.blocks != null && value.blocks.length > 0;
      },
      confirmTitle:
        "Deleting this condition branch will delete all its blocks.",
    };

    return {
      formItem,
      fieldValue,
      updateValue,
    };
  }

  showElseField(): BaseTemplateField<ConditionControlBlock, boolean> {
    const currentConfig = this.getConfig();
    const fieldValue = currentConfig.else != null;

    const updateValue = (block: ConditionControlBlock, newValue: boolean) => {
      if (newValue === true) {
        block.config.else = block.config.else || [];
      } else {
        delete block.config.else;
      }
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Show else branch",
      componentType: FormComponentType.SWITCH,
      initialValue: fieldValue,
      confirmTitle: "Removing the else branch will delete all its blocks.",
      showConfirm: currentConfig.else != null && currentConfig.else.length > 0,
      controlFlowTooltip:
        BLOCK_HELP_CONTENT[BlockType.CONDITION].fieldHelpContent.showElse,
    };

    return {
      formItem,
      fieldValue,
      updateValue,
    };
  }

  computeFields() {
    return {
      "if.condition": this.conditionField(),
      elseIf: this.elseIfField(),
      showElse: this.showElseField(),
    };
  }
}

import { KeyRotation } from "legacy/api/SigningKeyRotationApi";
import { Slice } from "store/utils/slice";
import { EntitiesStateWithMeta } from "store/utils/types";
import { AgentWithHealth } from "./client";

export type AgentMeta = {
  organizationId?: string;
  keyRotations?: KeyRotation[];
};

export type AgentState = EntitiesStateWithMeta<AgentWithHealth, AgentMeta>;

export default new Slice<AgentState>("agents", {
  entities: {},
  loading: {},
  errors: {},
  meta: {},
});

import { OrgBriefDto, SUPERBLOCKS_ORG_NAME } from "@superblocksteam/shared";
import { Modal, Popover, Tooltip } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";
import { PrimaryButton } from "components/ui/Button";
import { ConfirmModalClass } from "components/ui/Modal";
import { Spinner } from "components/ui/Spinner";
import { HeaderButton } from "components/ui/headers/Button";
import SearchComponent from "legacy/components/designSystems/default/SearchComponent";
import { scrollbarLightCss } from "legacy/constants/DefaultTheme";
import { User } from "legacy/constants/userConstants";
import { selectOnlyOrganization } from "store/slices/organizations";
import { callServer, HttpMethod } from "store/utils/client";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { sendErrorUINotification } from "utils/notification";

type Org = {
  id: string;
  name: string;
};

const StyledSearchComponentWrapper = styled.div`
  .bp5-input-group {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 32px;
    span:first-child {
      margin: 7px;
    }
    input {
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
`;

const OrgListStyles = styleAsClass`
  width: 280px;
  ${scrollbarLightCss}
`;

const ListItem = styleAsClass`
  padding: 8px 12px;
  font-size: 12px;
  line-height: 16px;
  height: 32px;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background-color: ${colors.GREY_100};
  }
`;

const ButtonWrapper = styled.div`
  margin-left: 10px;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const getAllOrgs = () => {
  return callServer<OrgBriefDto[]>({
    method: HttpMethod.Get,
    url: `v1/management/orgs`,
  });
};

const switchUserOrg = (organizationId: string) => {
  return callServer<User>({
    method: HttpMethod.Post,
    url: `v1/management/switch-org/organization/${organizationId}`,
  });
};

const OrgSwitcher = () => {
  const [allOrgs, setAllOrgs] = useState<OrgBriefDto[]>([]);
  const [isAllOrgsLoading, setIsAllOrgsLoading] = useState(false);
  const organization = useSelector(selectOnlyOrganization);

  const fetchAllOrgs = useCallback(async () => {
    try {
      setIsAllOrgsLoading(true);
      const orgs = await getAllOrgs();
      setAllOrgs(orgs);
    } catch (error: any) {
      sendErrorUINotification({
        message: `Failed to fetch all orgs. ${error?.message}`,
      });
    }
    setIsAllOrgsLoading(false);
  }, []);

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const onSearch = useCallback((value: string) => {
    setSearchKeyword(value);
  }, []);

  const filteredOrgs: Org[] = useMemo(() => {
    return allOrgs?.filter((org) =>
      org.name.toLocaleLowerCase().includes(searchKeyword.toLocaleLowerCase()),
    );
  }, [allOrgs, searchKeyword]);

  const superblocksOrg = useMemo(
    () => allOrgs.find((org) => org.name === SUPERBLOCKS_ORG_NAME),
    [allOrgs],
  );

  const onSwitch = useCallback(async (org: Org) => {
    try {
      await switchUserOrg(org.id);
      window.location.reload();
    } catch (error: any) {
      sendErrorUINotification({
        message: `Failed to switch org to ${org.name}. ${error?.message}`,
      });
    }
  }, []);

  const onReset = useCallback(() => {
    if (superblocksOrg?.id) {
      onSwitch(superblocksOrg);
    }
  }, [onSwitch, superblocksOrg]);

  const renderOrgList = useCallback(
    (index: number, org: Org) => {
      return (
        <div
          className={ListItem}
          onClick={() => {
            Modal.confirm({
              title: `Are you sure you want to switch to ${org.name}?`,
              icon: <></>,
              okText: "Confirm",
              okType: "primary",
              onOk: () => {
                onSwitch(org);
              },
              zIndex: 1040,
              className: `${ConfirmModalClass}`,
            });
          }}
        >
          {org.name}
        </div>
      );
    },
    [onSwitch],
  );

  const switchOrgContent = (
    <div>
      <StyledRow style={{ fontSize: "12px" }}>
        <div>
          <b>Current Org</b>: {organization.name}
        </div>
        <ButtonWrapper>
          <Tooltip title={`Go back to ${SUPERBLOCKS_ORG_NAME}`}>
            <PrimaryButton onClick={onReset}>Reset</PrimaryButton>
          </Tooltip>
        </ButtonWrapper>
      </StyledRow>
      <StyledSearchComponentWrapper>
        <SearchComponent
          dataTest="orgs-search"
          onSearch={onSearch}
          value={""}
          placeholder={"Search Orgs"}
        />
      </StyledSearchComponentWrapper>
      <Spinner spinning={isAllOrgsLoading}>
        <Virtuoso
          style={{ height: "600px" }}
          totalCount={filteredOrgs.length}
          itemContent={renderOrgList}
          data={filteredOrgs}
          className={OrgListStyles}
        />
      </Spinner>
    </div>
  );

  return (
    <Popover
      trigger="click"
      content={switchOrgContent}
      destroyTooltipOnHide
      onOpenChange={(open) => {
        if (!open) {
          setSearchKeyword("");
        }
      }}
    >
      <HeaderButton onClick={() => fetchAllOrgs()}>Switch Org</HeaderButton>
    </Popover>
  );
};

export default OrgSwitcher;

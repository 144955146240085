import { GeneratedTheme } from "legacy/themes";
import type { Typographies } from "@superblocksteam/shared";

export const DEFAULT_CALLOUT_WIDGET_TITLE_TEXT_STYLE_VARIANT: keyof Typographies =
  "label";
export const DEFAULT_CALLOUT_WIDGET_DESC_TEXT_STYLE_VARIANT: keyof Typographies =
  "body3";
export const DEFAULT_CALLOUT_WIDGET_CTA_TEXT_STYLE_VARIANT: keyof Typographies =
  "buttonLabel";

export enum CalloutType {
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  DANGER = "DANGER",
}

export const CTA_THEME_COLOR_NAMES: {
  [key in CalloutType]: `colors.${keyof GeneratedTheme["colors"]}`;
} = {
  [CalloutType.INFO]: "colors.info",
  [CalloutType.SUCCESS]: "colors.success",
  [CalloutType.WARNING]: "colors.warning",
  [CalloutType.DANGER]: "colors.danger",
};

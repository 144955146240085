import {
  Agent,
  ClientMethods,
  ISocketClient,
  Organization,
  ServerMethods,
  connectISocket,
  serverWsPath,
} from "@superblocksteam/shared";
import AuthProvider from "auth/auth0";
import { SUPERBLOCKS_UI_SOCKET_ENDPOINT_BASE_URL } from "env";
import { createRequestHandlers } from "./handlers";

const baseURL = SUPERBLOCKS_UI_SOCKET_ENDPOINT_BASE_URL || "/api/";
const wsPath = `${baseURL}${serverWsPath}`;
const wsPathParsed = new URL(wsPath, window.location.href);
wsPathParsed.protocol = wsPathParsed.protocol === "https:" ? "wss:" : "ws:";
// If we are running locally, the craco dev server will not proxy the websocket
if (wsPathParsed.origin === "ws://localhost:3000") {
  wsPathParsed.port = "8080";
}
const wsUrl = wsPathParsed.href;

export type StdISocketRPCClient = ISocketClient<ServerMethods>;

export async function connectToISocketRPCServer(
  agents: Agent[],
  organization: Organization,
): Promise<StdISocketRPCClient> {
  if (!AuthProvider.ready()) {
    throw new Error("AuthProvider not ready");
  }
  const token = await AuthProvider.generateToken();
  const requestHandlers = createRequestHandlers(agents, organization);
  const authorization = `Bearer ${token}`;
  return await connectISocket<ServerMethods, ClientMethods>(
    wsUrl,
    authorization,
    requestHandlers,
    [],
    {
      connectionTimeoutInSeconds: 6 * 60, // 6 minutes
      noResponseTimeoutInSeconds: 5 * 60, // 5 minutes
    },
  );
}

import { PostApiCreateRequestBody } from "@superblocksteam/schemas";
import { v4 as uuidv4 } from "uuid";
import { fastClone } from "utils/clone";
import { ApiDto, ActionDto, ApiTriggerType } from "../types";

function getIdMapper() {
  const idMappings: Record<string, string> = {};
  return (oldId: string): string => {
    idMappings[oldId] = idMappings[oldId] ?? uuidv4();
    return idMappings[oldId];
  };
}

function remapActionChildren(
  children: Record<string, string>,
  getRemappedId: (old: string) => string,
) {
  const newChildren = Object.keys(children).reduce(
    (newChildren: Record<string, string>, childActionId) => {
      const newChildActionId = getRemappedId(childActionId);
      newChildren[newChildActionId] = getRemappedId(children[childActionId]);
      return newChildren;
    },
    {},
  );
  return newChildren;
}

// this function is used to clone in app api
export function createDuplicateApiPayload(
  originalApi: ApiDto,
  params: {
    newName: string;
  },
) {
  const { newName } = params;
  const clonedApi = fastClone(originalApi);

  clonedApi.name = newName;
  if (clonedApi?.actions) clonedApi.actions.name = newName;

  const getRemappedId = getIdMapper();

  if (clonedApi?.actions) {
    clonedApi.actions.triggerActionId = getRemappedId(
      clonedApi?.actions?.triggerActionId,
    );
    const newActions = Object.keys(clonedApi.actions.actions).reduce(
      (newActionDtos: Record<string, ActionDto>, actionId: string) => {
        const actionDto = clonedApi?.actions
          ? clonedApi.actions.actions[actionId]
          : ({} as ActionDto);
        const newActionId = getRemappedId(actionId);
        actionDto.id = newActionId;
        const children = actionDto.children;
        if (children != null) {
          actionDto.children = remapActionChildren(children, getRemappedId);
        }
        newActionDtos[newActionId] = actionDto;
        return newActionDtos;
      },
      {},
    );
    clonedApi.actions.actions = newActions;
  }

  const newApi: PostApiCreateRequestBody = {
    name: clonedApi.name ?? "",
    applicationId: clonedApi.applicationId ?? "",
    organizationId: clonedApi.organizationId ?? "",
    triggerType: clonedApi.triggerType ?? ApiTriggerType.UI,
    actions: clonedApi.actions as any,
  };

  return newApi;
}

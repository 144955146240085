import React from "react";
import styled from "styled-components";
import logo from "assets/images/logo.svg";

const StyledLogo = styled.img`
  cursor: pointer;
`;

export const Logo = () => {
  return <StyledLogo src={logo} alt="Superblocks" data-test="brand-logo" />;
};

import { ENTITY_TYPE } from "utils/dataTree/constants";
import type { DataTreeDef } from "./dataTreeTypeDefCreator";

export type PublicDef = {
  "!name"?: string;
  "!url"?: string;
  "!doc"?: string;
  "!type"?: string;
  "!effects"?: string[];
  [key: string]: string | PublicDef | undefined | string[];
};

// Convert tern defs to defs that can be used by the autocomplete
// That means doc becomes docString
export const convertDef = (def: PublicDef): DataTreeDef => {
  const newDef: DataTreeDef = {};
  for (const key in def) {
    if (key === "!doc") {
      newDef[key] = {
        docString: def[key] as string,
        value: def[key] as string,
        entityType: ENTITY_TYPE.STATE_VAR,
      };
    } else if (typeof def[key] === "object") {
      newDef[key] = convertDef(def[key] as PublicDef);
    } else {
      newDef[key] = def[key];
    }
  }
  return newDef;
};

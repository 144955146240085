import React from "react";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import { Logo } from "components/app/Header/logo";
import Link from "components/ui/Link";
import { flushErrors } from "legacy/actions/errorActions";
import StyledHeader from "legacy/components/designSystems/default/StyledHeader";
import { LegacyNamedColors } from "legacy/constants/LegacyNamedColors";
import { HOME_URL } from "legacy/constants/routes";
import { User } from "legacy/constants/userConstants";
import { getSafeCrash } from "legacy/selectors/errorSelectors";
import {
  getCurrentUser,
  getIsSuperUser,
} from "legacy/selectors/usersSelectors";
import OrgSwitcher from "pages/components/OrgSwitcher";
import type { AppState } from "store/types";

const StyledPageHeader = styled(StyledHeader)`
  background: ${LegacyNamedColors.BALTIC_SEA};
  height: ${({ theme }) => theme.legacy.headerHeight};
  color: white;
  flex-direction: row;
  position: fixed;
  top: 0;
  z-index: 10;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
`;

const HeaderSection = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

type ErrorPageHeaderProps = {
  user?: User;
  flushErrors?: any;
  safeCrash: boolean;
};

const ErrorPageHeader = (props: ErrorPageHeaderProps) => {
  const { flushErrors, safeCrash } = props;
  const isSuperUser = useSelector(getIsSuperUser);

  return (
    <StyledPageHeader>
      <HeaderSection>
        <Link
          data-test="app-home"
          to={HOME_URL}
          onClick={() => {
            if (safeCrash) flushErrors();
          }}
        >
          <Logo />
        </Link>
        {isSuperUser && <OrgSwitcher />}
      </HeaderSection>
    </StyledPageHeader>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: getCurrentUser(state),
  safeCrash: getSafeCrash(state),
});

export default connect(mapStateToProps, {
  flushErrors,
})(ErrorPageHeader);

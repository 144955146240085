import { get } from "lodash";
import { TextStyleWithVariant } from "legacy/themes";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { SB_CUSTOM_TEXT_STYLE } from "legacy/themes/typographyConstants";
import {
  extractPixels,
  getLineHeightInPixels,
  getTextCssClassFromVariant,
} from "legacy/themes/utils";
import type {
  TextStyleBlock,
  Typographies,
} from "@superblocksteam/shared/src/types";

// Creates a string of general purpose and typography class names
export const createStyleClassName = (params: {
  textStyleVariant: string | undefined;
  isLoading?: boolean;
  isDisabled?: boolean;
  type: "input" | "label";
}) => {
  const className = [];
  className.push(getTextCssClassFromVariant(params.textStyleVariant));

  if (params.isLoading) {
    className.push("bp5-skeleton");
  }

  if (params.isDisabled) {
    className.push(CLASS_NAMES.DISABLED_MODIFIER);
  }

  if (params.type === "label") {
    className.push(CLASS_NAMES.ELLIPSIS_TEXT);
  }

  return className.join(" ");
};

// Uses typography fallback when a custom typography is deleted
export const getFontSizeInPxFromTextStyle = <PropType = any>({
  nestedProps,
  typographies,
  defaultTextStyleVariant,
  textStyleVariant,
}: {
  nestedProps: PropType;
  typographies: Typographies;
  defaultTextStyleVariant: keyof Typographies;
  textStyleVariant: string | undefined;
}) => {
  const fontSizeStylePropertyValue = getStylePropertyBasedOnVariant({
    nestedProps,
    typographies,
    textStyleVariant,
    defaultTextStyleVariant,
    propName: "fontSize",
  });

  return extractPixels(fontSizeStylePropertyValue);
};

// Uses typography fallback when a custom typography is deleted
export const getLineHeightInPxFromTextStyle = ({
  nestedProps,
  typographies,
  defaultTextStyleVariant,
  textStyleVariant,
}: {
  nestedProps: undefined | TextStyleBlock | TextStyleWithVariant;
  typographies: Typographies;
  defaultTextStyleVariant: keyof Typographies;
  textStyleVariant: string | undefined;
}) => {
  const lineHeightStylePropertyValue = getStylePropertyBasedOnVariant({
    nestedProps,
    typographies,
    textStyleVariant,
    defaultTextStyleVariant,
    propName: "lineHeight",
  });

  const fontSize = getFontSizeInPxFromTextStyle({
    nestedProps,
    typographies,
    textStyleVariant,
    defaultTextStyleVariant,
  });

  return getLineHeightInPixels(lineHeightStylePropertyValue ?? 1, fontSize);
};

export const getIconSizeBasedOnFontSize = (fontSize: number) => {
  // Legacy version had hard coded values that were roughly equal to the line height
  // but now we let users set line height, which could make the line height much larger
  // than the font size. We don't want to make the icon that large or it would look wrong
  // So we're setting it to fontSize plus 14%. The range for icon size to font size in the hard coded
  // version is 6-20%
  return Math.round(fontSize * 1.14);
};

export const getIconGapBasedOnTextStyle = (fontSize: number) => {
  return {
    gap: `${Math.round(Math.max(fontSize * 0.22, 4))}px`,
  };
};

// Uses typography fallback when a custom typography is deleted
export const getStylePropertyBasedOnVariant = <PropType>({
  nestedProps,
  typographies,
  textStyleVariant,
  propName,
  defaultTextStyleVariant,
}: {
  nestedProps: PropType;
  typographies: Typographies;
  textStyleVariant: string | undefined;
  propName: keyof TextStyleBlock;
  defaultTextStyleVariant: keyof Typographies;
}) => {
  let overwriteProp;
  if (textStyleVariant === SB_CUSTOM_TEXT_STYLE) {
    overwriteProp = get(nestedProps, propName);
  }

  return (
    overwriteProp ??
    get(
      typographies,
      `${textStyleVariant}.${propName}`,
      typographies?.[defaultTextStyleVariant]?.[propName],
    )
  );
};

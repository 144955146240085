import { ApiTriggerType } from "@superblocksteam/shared";
import {
  BlockType,
  BreakControlBlock,
  ConditionControlBlock,
  ControlBlock,
  ControlFlowFrontendDSL,
  LoopControlBlock,
  ParallelControlBlock,
  ReturnControlBlock,
  SendControlBlock,
  StreamControlBlock,
  ThrowControlBlock,
  TryCatchControlBlock,
  VariablesControlBlock,
  WaitControlBlock,
} from "store/slices/apisV2/control-flow/types";
import { FormTemplateQuotas } from "./FormTemplates/BaseTemplate";
import BreakTemplate from "./FormTemplates/BreakTemplate";
import ConditionTemplate from "./FormTemplates/ConditionTemplate";
import LoopTemplate from "./FormTemplates/LoopTemplate";
import ParallelTemplate from "./FormTemplates/ParallelTemplate";
import ReturnTemplate from "./FormTemplates/ReturnTemplate";
import SendTemplate from "./FormTemplates/SendTemplate";
import StreamTemplate from "./FormTemplates/StreamTemplate";
import ThrowTemplate from "./FormTemplates/ThrowTemplate";
import TryCatchTemplate from "./FormTemplates/TryCatchTemplate";
import VariablesTemplate from "./FormTemplates/VariablesTemplate";
import WaitTemplate from "./FormTemplates/WaitTemplate";

export function getControlBlockTemplate(params: {
  block: ControlBlock;
  apiDSL: ControlFlowFrontendDSL;
  quotas?: FormTemplateQuotas;
  triggerType?: ApiTriggerType;
}) {
  const { block, apiDSL, quotas, triggerType } = params;
  const extras = {
    controlFlow: apiDSL,
    quotas,
    triggerType,
  };
  const type = block.type;
  switch (type) {
    case BlockType.BREAK:
      return new BreakTemplate(block as BreakControlBlock);
    case BlockType.RETURN:
      return new ReturnTemplate(block as ReturnControlBlock);
    case BlockType.WAIT:
      return new WaitTemplate(block as WaitControlBlock, extras);
    case BlockType.THROW:
      return new ThrowTemplate(block as ThrowControlBlock);
    case BlockType.VARIABLES:
      return new VariablesTemplate(block as VariablesControlBlock);
    case BlockType.TRY_CATCH:
      return new TryCatchTemplate(block as TryCatchControlBlock);
    case BlockType.LOOP:
      return new LoopTemplate(block as LoopControlBlock, extras);
    case BlockType.CONDITION:
      return new ConditionTemplate(block as ConditionControlBlock);
    case BlockType.PARALLEL:
      return new ParallelTemplate(block as ParallelControlBlock, extras);
    case BlockType.STREAM:
      return new StreamTemplate(block as StreamControlBlock, extras);
    case BlockType.SEND:
      return new SendTemplate(block as SendControlBlock);
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(`Unhandled action case: ${exhaustiveCheck}`);
    }
  }
}

import { get } from "lodash";
import {
  type PropertyPaneConfig,
  type PropertyPaneControlConfig,
} from "legacy/constants/PropertyControlConstants";
import { getPropertyName } from "legacy/pages/Editor/PropertyPane/getPropertyName";
import { EntityWithBindings } from "legacy/utils/DynamicBindingTypes";
import { isPropertyPaneControlConfig } from "legacy/utils/PropertyControlUtils";
import { Flags } from "store/slices/featureFlags";
import { getDottedPathTo } from "utils/dottedPaths";

type Options =
  | {
      omitHidden: false;
    }
  | {
      omitHidden?: true;
      featureFlags: Flags;
    };

export const getAllPathsFromPropertyConfig = (
  entity: EntityWithBindings,
  entityConfig: readonly PropertyPaneConfig[],
  derivedProperties: Record<string, true>,
  options: Options = { omitHidden: false },
): {
  bindingPaths: Record<string, true>;
  triggerPaths: Record<string, true>;
} => {
  const featureFlags = options.omitHidden ? options.featureFlags : undefined;
  const omitHidden = featureFlags !== undefined && options.omitHidden;

  const bindingPaths: Record<string, true> = derivedProperties;
  const triggerPaths: Record<string, true> = {};

  const processConfig = (controlConfig: PropertyPaneConfig) => {
    let isSectionHidden = false;
    if (omitHidden && controlConfig.hidden) {
      isSectionHidden = controlConfig.hidden(entity, "", featureFlags);
    }
    if (isSectionHidden) return;

    if (!isPropertyPaneControlConfig(controlConfig)) {
      controlConfig.children?.forEach(processConfig);
      return;
    }

    // We don't want to include properties that are not for this entity
    // If the control config is for a different entity, skip it
    const representsThisEntity = !controlConfig.getTargetWidgetId;
    if (!representsThisEntity) return;

    const propertyName = getPropertyName(controlConfig, entity as any);
    const basePath = propertyName;

    if (
      !omitHidden ||
      !controlConfig.hidden?.(entity, basePath, featureFlags)
    ) {
      if (controlConfig.isTriggerProperty) {
        triggerPaths[propertyName] = true;
      } else if (controlConfig.isBindProperty) {
        bindingPaths[propertyName] = true;
      }
    }

    if (controlConfig.panelConfig) {
      // Note: `isSectionHidden` will always be `false` if the property is not a table's widget column (or if entity is not an object that contains an `id` field)
      const panelPropertyPath = getPropertyName(controlConfig, entity as any);
      const widgetPanelPropertyValues = get(entity, panelPropertyPath);
      if (widgetPanelPropertyValues) {
        const isArray = Array.isArray(widgetPanelPropertyValues);
        Object.values(widgetPanelPropertyValues).forEach(
          (widgetPanelPropertyValue: any, index) => {
            controlConfig?.panelConfig?.children
              .map((child) => child as PropertyPaneControlConfig)
              .forEach((panelColumnConfig) => {
                let isSectionHidden = false;
                if (
                  omitHidden &&
                  panelColumnConfig.hidden &&
                  widgetPanelPropertyValue
                ) {
                  isSectionHidden = panelColumnConfig.hidden(
                    entity,
                    `${basePath}${getDottedPathTo(
                      widgetPanelPropertyValue?.id,
                    )}`,
                    featureFlags,
                  );
                }

                if (!isSectionHidden) {
                  panelColumnConfig.children
                    ?.map((child) => child as PropertyPaneControlConfig)
                    .forEach((panelColumnControlConfig) => {
                      const name = getPropertyName(
                        panelColumnControlConfig,
                        entity as any,
                      );
                      const isArrayOrHasId =
                        isArray || widgetPanelPropertyValue?.id; // tables columns have id

                      const intermediatePath = isArrayOrHasId
                        ? getDottedPathTo(
                            isArray ? index : widgetPanelPropertyValue.id,
                          )
                        : "";

                      const panelPropertyPath = `${basePath}${intermediatePath}${getDottedPathTo(
                        name,
                      )}`;
                      let isControlHidden = false;
                      if (omitHidden && panelColumnControlConfig.hidden) {
                        isControlHidden = panelColumnControlConfig.hidden(
                          entity,
                          panelPropertyPath,
                          featureFlags,
                        );
                      }
                      if (!isControlHidden) {
                        if (
                          panelColumnControlConfig.isBindProperty &&
                          !panelColumnControlConfig.isTriggerProperty
                        ) {
                          bindingPaths[panelPropertyPath] = true;
                        } else if (panelColumnControlConfig.isTriggerProperty) {
                          triggerPaths[panelPropertyPath] = true;
                        }
                      }
                    });
                }
              });
          },
        );
      }
    }
  };

  entityConfig.forEach(processConfig);

  return { bindingPaths, triggerPaths };
};

import {
  ApplicationScope,
  createTriggerStepRenamer,
} from "@superblocksteam/shared";
import { isArray, get, set } from "lodash";
import {
  StepDef,
  isValidStepDef,
  ValidMultiStepDef,
} from "legacy/constants/ActionConstants";
import { ApiInfo } from "legacy/constants/ApiConstants";
import { extractJsEvaluationPairs } from "legacy/workers/evaluationUtils";
import { ScopedFakeDataTree } from "store/helpers/refactoring";
import { fastClone } from "utils/clone";

export async function createDuplicatePartialApiInfo(
  originalApiInfo: Partial<ApiInfo>,
  params: {
    newId: string;
    originalApiName: string;
    newApiName: string;
    fakeDataTree: ScopedFakeDataTree;
  },
): Promise<Partial<ApiInfo>> {
  const { newId, originalApiName, newApiName, fakeDataTree } = params;
  const newInfo = fastClone(originalApiInfo);
  newInfo.id = newId;

  const updates: Array<{ propertyName: string; propertyValue: unknown }> = [];
  const renameInTriggerStep = createTriggerStepRenamer({
    oldName: originalApiName,
    newName: newApiName,
    dataTree: fakeDataTree[ApplicationScope.PAGE],
    updateStepIds: true,
    addUpdate: (payload) => updates.push(payload),
    extractEvaluationPairs: extractJsEvaluationPairs,
  });

  await Promise.all(
    (newInfo.dynamicTriggerPathList ?? []).map(async ({ key }) => {
      const trigger: ValidMultiStepDef | undefined = get(newInfo, key);
      if (!isArray(trigger)) {
        return;
      }
      await Promise.all(
        trigger.map(async (step: StepDef, index) => {
          if (!isValidStepDef(step)) return;
          await renameInTriggerStep(step, key, index);
        }),
      );
    }),
  );

  updates.forEach((update) => {
    const { propertyName, propertyValue } = update;
    set(newInfo, propertyName, propertyValue);
  });

  return newInfo;
}

import { createReducer } from "@reduxjs/toolkit";
import {
  restartEvaluation,
  stopEvaluation,
} from "legacy/actions/evaluationActions";
import { hasUpdatedEntityNames } from "../../actions/evaluationActions";

const initialState = 0;

const nameIteratorReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(hasUpdatedEntityNames, (state) => state + 1)
    // Clear tracker
    .addCase(restartEvaluation, () => 0)
    .addCase(stopEvaluation, () => 0),
);

export default nameIteratorReducer;

import { AppStyles } from "./types-legacy";

const defaultFontSize = 12;

export default {
  fontFamily: "var(--font-family)",
  fontSize: defaultFontSize,
  label: {
    text: {
      fontSize: defaultFontSize,
      fontWeight: "normal",
    },
    heading: {
      fontSize: 16,
      fontWeight: "bold",
    },
    sub: {
      fontSize: 11,
      fontWeight: "normal",
    },
  },
  textField: {
    fontSize: defaultFontSize,
    fontWeight: "normal",
  },
  autocomplete: {
    fontSize: defaultFontSize,
    fontWeight: "normal",
  },
  dropdown: {
    fontSize: defaultFontSize,
    fontWeight: "normal",
  },
  propertyPane: {
    widgetName: {
      fontSize: defaultFontSize,
      fontWeight: "bold",
    },
  },
  table: {
    header: {
      fontSize: 14,
      fontWeight: "normal",
    },
    row: {
      fontSize: 14,
      fontWeight: "normal",
    },
  },
} as AppStyles;

import { AlertFormItem } from "@superblocksteam/shared";
import { computeParallelBlocksInScope } from "store/slices/apisV2/control-flow/computeParallelBlocksInScope";
import {
  WaitControlBlock,
  WaitControl,
} from "store/slices/apisV2/control-flow/types";
import {
  addBindingsToStringValue,
  removeBindingsFromStringValue,
} from "../../ControlFlow/utils";
import {
  BaseTemplateField,
  BaseTemplateWithExtras,
  ExtraTemplateProps,
} from "./BaseTemplate";
import { FormComponentType, ControlFlowFormItem } from "./FormTemplateTypes";

export default class WaitTemplate extends BaseTemplateWithExtras<WaitControlBlock> {
  private getConfig(): Readonly<WaitControl> {
    return this.source.config;
  }

  private parallelBlocksInScope: string[];

  constructor(source: WaitControlBlock, extras: ExtraTemplateProps) {
    super(source, extras);
    this.parallelBlocksInScope = computeParallelBlocksInScope(
      source.name,
      extras.controlFlow,
    );
  }

  waitField(): BaseTemplateField<WaitControlBlock, string> {
    const currentConfig = this.getConfig();
    const parallelOptions = this.parallelBlocksInScope.map(
      (parallelBlockName) => {
        const block = this.controlFlow.blocks[parallelBlockName];
        return {
          key: block.name,
          value: `'${block.name}'`,
          displayName: block.name,
        };
      },
    );
    if (parallelOptions.length) {
      const fieldValue = removeBindingsFromStringValue(currentConfig.condition);
      const updateValue = (block: WaitControlBlock, newValue: string) => {
        block.config.condition = addBindingsToStringValue(newValue);
      };
      const formItem: ControlFlowFormItem = {
        ...this.getBaseFormItem(),
        label: "Wait for parallel block",
        componentType: FormComponentType.DROPDOWN,
        options: parallelOptions,
        subHeading:
          "Wait for the above parallel block to finish execution before continuing",
      };
      return {
        formItem,
        fieldValue,
        updateValue,
      };
    }

    const alertFormItem: AlertFormItem = {
      ...this.getBaseFormItem(),
      label: "alert",
      componentType: FormComponentType.ALERT,
      type: "warning",
      showIcon: true,
      messageTemplate: `No Parallel Blocks are in scope to wait for.

Create a Parallel Block above this Wait Block so there is something that can be waited on.`,
    };

    return {
      formItem: alertFormItem,
      fieldValue: "",
      updateValue: () => {
        // do nothing
      },
    };
  }

  computeFields() {
    return {
      wait: this.waitField(),
    };
  }
}

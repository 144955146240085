import { ExtendedIntegrationPluginId, Plugin, SUPERBLOCKS_OPENAPI_TENANT_KEYWORD } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const ZendeskPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Zendesk',
  id: ExtendedIntegrationPluginId.ZENDESK,
  authMethods: {
    basic: true
  },
  serverURL: `https://${SUPERBLOCKS_OPENAPI_TENANT_KEYWORD}.zendesk.com`,
  tenantInput: {
    label: 'Zendesk tenant',
    placeholder: 'acme',
    subtitle: 'Your organization subdomain (e.g. https://<tenant>.zendesk.com)'
  }
});

import { memoize } from "lodash";
import { iconData } from "../../constants/materialIcons";
import { AppIconWithMetaType } from "../constants/IconConstants";

export const getAvailableIcons = memoize(
  (): AppIconWithMetaType => {
    const result: Record<string, any> = {};

    Object.keys(iconData).forEach((key: any) => {
      result[key] = "<Icon name='" + key + "' />";
    });
    return result;
  },
  () => "constant",
);

import { DynamicPath } from "legacy/utils/DynamicBindingTypes";

export enum PluginType {
  API = "API",
  DB = "DB",
}

interface ActionConfig {
  timeoutInMillisecond?: number;
}

interface Property {
  key: string;
  value: string;
}

interface BodyFormData {
  editable: boolean;
  mandatory: boolean;
  description: string;
  key: string;
  value?: string;
  type: string;
}

interface ApiActionConfig extends ActionConfig {
  headers: Property[];
  httpMethod: string;
  path?: string;
  body?: JSON | string | Record<string, any> | null;
  queryParameters?: Property[];
  bodyFormData?: BodyFormData[];
}

interface QueryActionConfig extends ActionConfig {
  body?: string;
}
interface StoredDatasource {
  id: string;
}

interface BaseAction {
  id: string;
  name: string;
  organizationId: string;
  pageId: string;
  collectionId?: string;
  pluginId: string;
  executeOnLoad: boolean;
  dynamicBindingPathList: DynamicPath[];
  isValid: boolean;
  invalids: string[];
  cacheResponse: string;
  confirmBeforeExecute?: boolean;
  eventData?: any;
  bindings: string[];
}

interface BaseApiAction extends BaseAction {
  pluginType: PluginType.API;
  actionConfiguration: ApiActionConfig;
}

interface StoredDatasourceApiAction extends BaseApiAction {
  datasource: StoredDatasource;
}

type ApiAction = StoredDatasourceApiAction;

interface QueryAction extends BaseAction {
  pluginType: PluginType.DB;
  actionConfiguration: QueryActionConfig;
  datasource: StoredDatasource;
}

export type Action = ApiAction | QueryAction;

import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const PerplexityPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Perplexity',
  id: ExtendedIntegrationPluginId.PERPLEXITY,
  authMethods: {
    bearer: true
  },
  serverURL: 'https://api.perplexity.ai',
  isStreamable: true
});

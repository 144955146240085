import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const StripePlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Stripe',
  id: ExtendedIntegrationPluginId.STRIPE,
  authMethods: {
    bearer: true
  },
  serverURL: 'https://api.stripe.com/'
});

import {
  SendControl,
  SendControlBlock,
} from "store/slices/apisV2/control-flow/types";
import {
  addBindingsToStringValue,
  removeBindingsFromStringValue,
} from "../../ControlFlow/utils";
import { BaseTemplate, BaseTemplateField } from "./BaseTemplate";
import {
  ControlFlowFormComponentType,
  ControlFlowFormItem,
} from "./FormTemplateTypes";

export default class SendTemplate extends BaseTemplate<SendControlBlock> {
  private getConfig(): Readonly<SendControl> {
    return this.source.config;
  }

  messageField(): BaseTemplateField<SendControlBlock, string> {
    const currentConfig = this.getConfig();
    const fieldValue = removeBindingsFromStringValue(currentConfig.message);

    const updateValue = (block: SendControlBlock, newValue: string) => {
      block.config.message = addBindingsToStringValue(newValue);
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Message to send",
      componentType: ControlFlowFormComponentType.EXPRESSION_EDITOR,
    };

    return {
      formItem,
      fieldValue,
      updateValue,
    };
  }

  computeFields() {
    return {
      message: this.messageField(),
    };
  }
}

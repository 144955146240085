import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const GoogleAnalyticsPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Google Analytics',
  id: ExtendedIntegrationPluginId.GOOGLE_ANALYTICS,
  authMethods: {
    oauth2BringYourOwn: {
      authorizationUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
      tokenUrl: 'https://oauth2.googleapis.com/token',
      revokeTokenUrl: 'https://oauth2.googleapis.com/revoke',
      iconUrl: 'https://integrations.superblocks.com/img/google-analytics.png',
      authorizationExtraParams: {
        responseType: 'code'
      }
    }
  },
  serverURL: 'https://analytics.googleapis.com/analytics/v3'
});

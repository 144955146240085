import { useEffect } from "react";
import { useAppDispatch } from "store/helpers";
import {
  pauseAllTimers,
  restartAllPausedTimers,
} from "store/slices/application/timers/timerActions";

export function usePauseTimersOnTabSleep(): void {
  const dispatch = useAppDispatch();

  useEffect((): (() => void) => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        dispatch(pauseAllTimers());
      } else {
        dispatch(restartAllPausedTimers());
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return (): void => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [dispatch]);
}

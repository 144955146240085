import { combineReducers } from "redux";

import appReducer from "./appReducer";
import canvasWidgetsReducer from "./canvasWidgetsReducer";
import embedMetaReducer from "./embedMetaReducer";
import metaReducer from "./metaReducer";
import navigationEntityReducer from "./navigationEntityReducer";
import pageListReducer from "./pageListReducer";

const entityReducer = combineReducers({
  canvasWidgets: canvasWidgetsReducer,
  pageList: pageListReducer,
  meta: metaReducer,
  embedPropsMeta: embedMetaReducer,
  navigationEntityMeta: navigationEntityReducer,
  app: appReducer,
});

export type EntitiesState = ReturnType<typeof entityReducer>;

export default entityReducer;

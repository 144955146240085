import { createReducer } from "@reduxjs/toolkit";
import {
  startEvaluation,
  stopEvaluation,
} from "legacy/actions/evaluationActions";

export type EvaluationType = "ui" | "headless" | "unknown";

const initialState: EvaluationType = "unknown";

const evaluationDependencyReducer = createReducer(
  initialState as EvaluationType,
  (builder) =>
    builder
      .addCase(
        startEvaluation,
        (state, action) => action.payload.evaluationType,
      )
      .addCase(stopEvaluation, () => "unknown" as const),
);

export default evaluationDependencyReducer;

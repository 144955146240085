import { Dimension, Padding } from "@superblocksteam/shared";

type PaddingPreset = {
  value: Padding;
  label: string;
  shortLabel?: string;
};

type PaddingPresetName = "none" | "small" | "medium" | "large";
type PaddingPresetNameWithClassic = PaddingPresetName | "classic";

export const BASE_THEME_EXISTING_APPS_PADDING: Padding = {
  top: Dimension.px(11),
  bottom: Dimension.px(11),
  left: Dimension.px(16),
  right: Dimension.px(16),
};

const PADDING_PRESETS_V1_THEME: Record<
  PaddingPresetNameWithClassic,
  PaddingPreset
> = {
  none: {
    value: {
      top: Dimension.px(0),
      bottom: Dimension.px(0),
      left: Dimension.px(0),
      right: Dimension.px(0),
    },
    label: "None",
    shortLabel: "None",
  },
  small: {
    value: {
      top: Dimension.px(5),
      bottom: Dimension.px(5),
      left: Dimension.px(5),
      right: Dimension.px(5),
    },
    label: "Small (0.5x)",
    shortLabel: "S (0.5x)",
  },
  medium: {
    value: {
      top: Dimension.px(11),
      bottom: Dimension.px(11),
      left: Dimension.px(11),
      right: Dimension.px(11),
    },
    label: "Medium (1x)",
    shortLabel: "M (1x)",
  },
  large: {
    value: {
      top: Dimension.px(23),
      bottom: Dimension.px(23),
      left: Dimension.px(23),
      right: Dimension.px(23),
    },
    label: "Large (2x)",
    shortLabel: "L (2x)",
  },
  classic: {
    value: BASE_THEME_EXISTING_APPS_PADDING,
    label: `Classic`,
  },
};

const PADDING_PRESETS_V2_THEME = PADDING_PRESETS_V1_THEME;

const PADDING_PRESETS_V3_THEME: Record<PaddingPresetName, PaddingPreset> = {
  none: {
    value: {
      top: Dimension.px(0),
      bottom: Dimension.px(0),
      left: Dimension.px(0),
      right: Dimension.px(0),
    },
    label: "None (0px)",
    shortLabel: "None (0px)",
  },
  small: {
    value: {
      top: Dimension.px(6),
      bottom: Dimension.px(6),
      left: Dimension.px(6),
      right: Dimension.px(6),
    },
    label: "Small (6px)",
    shortLabel: "S (6px)",
  },
  medium: {
    value: {
      top: Dimension.px(12),
      bottom: Dimension.px(12),
      left: Dimension.px(12),
      right: Dimension.px(12),
    },
    label: "Medium (12px)",
    shortLabel: "M (12px)",
  },
  large: {
    value: {
      top: Dimension.px(24),
      bottom: Dimension.px(24),
      left: Dimension.px(24),
      right: Dimension.px(24),
    },
    label: "Large (24px)",
    shortLabel: "L (24px)",
  },
};

const PADDING_PRESETS_V4_THEME = PADDING_PRESETS_V3_THEME;
const PADDING_PRESETS_V5_THEME = PADDING_PRESETS_V3_THEME;

export const PADDING_PRESETS_BY_THEME_VERSION: Record<
  number,
  Record<PaddingPresetName, PaddingPreset>
> = {
  1: PADDING_PRESETS_V1_THEME,
  2: PADDING_PRESETS_V2_THEME,
  3: PADDING_PRESETS_V3_THEME,
  4: PADDING_PRESETS_V4_THEME,
  5: PADDING_PRESETS_V5_THEME,
};

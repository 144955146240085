import { createSelector } from "reselect";
import { ROOT } from "store/utils/types";
import { defaults, Flag, Flags } from "./models/Flags";
import slice from "./slice";

export const selectFlags = createSelector(
  slice.selector,
  (state) => state?.entities?.[ROOT],
);

export const selectFlagById = createSelector(
  [selectFlags, (_: unknown, flagId: Flag) => flagId],
  (state: Flags, flagId: Flag) => state?.[flagId] ?? defaults[flagId],
);

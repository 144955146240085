import { ApiTriggerType } from "@superblocksteam/shared";
import { produce } from "immer";
import computeControlFlowVarsInScope from "store/slices/apisV2/control-flow/computeControlFlowVarsInScope";
import {
  ControlFlowFormTemplate,
  ControlFlowFormItem,
  FormSectionMeta,
  ControlFlowFormSection,
} from "./FormTemplateTypes";
import type { ControlFlowFrontendDSL } from "store/slices/apisV2/control-flow/types";

export interface TypedFormTemplate<T> {
  // get the form template to render
  getFormTemplate: () => ControlFlowFormTemplate;

  // called when the form is changed by the user, and we need to normalize it back to
  // the original data model
  formatNewValueBeforeUpdate: (formData: Record<string, unknown>) => T;

  // mapping the original data model to flattened record
  getFormValues: () => Record<string, unknown>;
}

export type BaseTemplateField<T, V = any> = {
  formItem: ControlFlowFormItem;
  fieldValue: V;
  updateValue: (copiedSource: T, newValue: V) => void; // only called if value changes
  expectedValue?: string; // "Array<Object>"" | "string" etc.
  exampleData?: any; // example data to show in the editor. Ex: ["a", "b" , "c"] or 10 or "myValue" etc.
  sectionMeta?: FormSectionMeta;
};

// This doesn't need to be a class, but it's helpful for getting the interface right.
// Can refactor to be functional if desired later. This is likely temporary anyway.
export abstract class BaseTemplate<T> implements TypedFormTemplate<T> {
  /* Note: currently these field keys are mapped from the backend and need to match the path in a dot notation */
  protected abstract computeFields(): Record<string, BaseTemplateField<T>>;

  // implementation:
  protected source: T;
  private computedFields: undefined | Record<string, BaseTemplateField<T>>;
  private formValues: undefined | Record<string, unknown>;
  private formTemplate: undefined | ControlFlowFormTemplate;

  constructor(source: T) {
    this.source = source;
  }

  public getComputedFields(): Record<string, BaseTemplateField<T>> {
    if (this.computedFields == null) {
      this.computedFields = this.computeFields();
    }
    return this.computedFields;
  }

  private computeFormValues(): Record<string, unknown> {
    const formValues: Record<string, unknown> = {};
    Object.entries(this.getComputedFields()).forEach(([fieldKey, fieldDef]) => {
      formValues[fieldKey] = fieldDef.fieldValue;
    });
    return formValues;
  }

  private computeTemplate(): ControlFlowFormTemplate {
    const mainItems: ControlFlowFormItem[] = [];
    const sections: Record<string, ControlFlowFormSection> = {};

    Object.entries(this.getComputedFields()).forEach(
      ([key, { formItem, sectionMeta }]) => {
        if (sectionMeta) {
          sections[sectionMeta.name] ??= { ...sectionMeta, items: [] };
          sections[sectionMeta.name].items.push({
            ...formItem,
            name: key,
          });
        } else {
          mainItems.push({
            ...formItem,
            name: key,
          });
        }
      },
    );

    return {
      sections: [
        {
          name: "main",
          items: mainItems,
        },
        ...Object.values(sections).map((section) => {
          return section;
        }),
      ],
    };
  }

  protected getBaseFormItem(): { name: string; startVersion: string } {
    return {
      name: "placeholder", // placeholder gets set to unique key in computeTemplate
      startVersion: "0.0.1",
    };
  }

  public getFormTemplate(): ControlFlowFormTemplate {
    if (this.formTemplate == null) {
      this.formTemplate = this.computeTemplate();
    }
    return this.formTemplate;
  }

  public getFormValues(): Record<string, unknown> {
    if (this.formValues == null) {
      this.formValues = this.computeFormValues();
    }
    return this.formValues;
  }

  public formatNewValueBeforeUpdate(newFormData: Record<string, unknown>): T {
    const computedFields = this.getComputedFields();
    const formValues = this.getFormValues();

    const newValue = produce(this.source, (draft: T) => {
      Object.entries(newFormData).forEach(([fieldKey, newFormValue]) => {
        const fieldDef = computedFields[fieldKey];
        const oldFormValue = formValues[fieldKey];
        if (oldFormValue !== newFormValue) {
          fieldDef.updateValue(draft, newFormValue);
        }
      });
    });
    return newValue;
  }
}

export interface FormTemplateQuotas {
  poolMaxSize?: number;
}

export interface ExtraTemplateProps {
  quotas?: FormTemplateQuotas;
  controlFlow: ControlFlowFrontendDSL;
  triggerType?: null | undefined | ApiTriggerType;
}

export abstract class BaseTemplateWithExtras<T> extends BaseTemplate<T> {
  protected controlFlow: ControlFlowFrontendDSL;
  protected quotas: FormTemplateQuotas | undefined;
  protected triggerType: null | undefined | ApiTriggerType;

  constructor(source: T, extras: ExtraTemplateProps) {
    super(source);
    const { controlFlow, quotas, triggerType } = extras;
    this.controlFlow = controlFlow;
    this.quotas = quotas;
    this.triggerType = triggerType;
  }

  protected getCurrentScopedVariables(block: T & { name: string }) {
    const currentVariables = computeControlFlowVarsInScope(
      block.name,
      this.controlFlow,
    );
    return currentVariables;
  }
}

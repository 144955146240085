import { decodeBytestrings } from "@superblocksteam/shared";
import { ExecutionContextDto } from "../types";

export function decodeBytestringsExecutionContextDto(
  context: ExecutionContextDto | undefined,
): void {
  if (context === undefined) return;
  for (const key in context.outputs) {
    context.outputs[key].output = decodeBytestrings(
      context.outputs[key].output,
      false,
    );
  }
}

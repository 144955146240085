import React from "react";
import styled from "styled-components";
import ResourceSigningError from "assets/images/errors/resource-signing-error.svg";
import ErrorComponent from "components/app/Error/ErrorComponent";

const Wrapper = styled.div`
  text-align: center;
  .bold-text {
    font-weight: ${(props) => props.theme.legacy.fontWeights[3]};
    font-size: 24px;
  }
  .page-unavailable-img {
    width: 35%;
  }
  .button-position {
    margin: auto;
  }
  height: 100%;
`;

const PageVerificationFailed = () => {
  return (
    <Wrapper>
      <ErrorComponent
        title="Unable to verify code signature"
        errorMessage={
          <div style={{ maxWidth: 520 }}>
            The signature for this Application’s code is incorrect. The code may
            have been tampered with or corrupted since being signed. Please
            contact your organization administrator or see our{" "}
            <a
              href={
                "https://docs.superblocks.com/on-premise-agent/manage/troubleshooting"
              }
              target="_blank"
              rel="noreferrer"
            >
              Troubleshooting Guide.
            </a>
          </div>
        }
        handleButtonClick={() => window.location.replace("/")}
        buttonText="Return home"
        image={ResourceSigningError}
      />
    </Wrapper>
  );
};

export default PageVerificationFailed;

import { ApplicationScope, Plugin } from "@superblocksteam/shared";
import { resolveDataTreePath } from "legacy/entities/DataTree/DataTreeHelpers";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { ScopeSpecificDataTree } from "utils/dataTree/MergedDataTree";
import { extractBindingsByBlockFromControlFlow } from "../control-flow/bindings";
import { getCachedControlFlowDSL } from "../control-flow/control-flow-selectors";
import { ControlFlowPluginInfo } from "../control-flow/convert-block-to-frontend";
import type { Binding, ApiDtoWithPb } from "../slice";

interface Input {
  api: ApiDtoWithPb;
  scope: ApplicationScope;
  plugins: Record<string, Plugin>;
  pluginInfo: ControlFlowPluginInfo;
  userAccessibleDataTree: ScopeSpecificDataTree;
  userAccessibleDataTreeKeys: string[];
  dataTree: DataTree;
}

interface Output {
  bindingsByBlock: Record<string, Binding[]>;
  allBindings: Binding[];
}

export async function calculateBindings({
  api,
  scope,
  plugins,
  pluginInfo,
  userAccessibleDataTree,
  userAccessibleDataTreeKeys,
  dataTree,
}: Input): Promise<Output> {
  const controlFlow = getCachedControlFlowDSL(api.apiPb, pluginInfo);
  const userBindingsByBlock = await extractBindingsByBlockFromControlFlow({
    controlFlow,
    identifiers: userAccessibleDataTreeKeys,
    plugins,
    dataTree: userAccessibleDataTree,
  });

  const bindingsByBlock = Object.fromEntries(
    Object.entries(userBindingsByBlock).map(([blockName, bindings]) => [
      blockName,
      bindings
        .map((str) => ({
          str,
          dataTreePath: resolveDataTreePath(str, scope, dataTree),
        }))
        .filter((b) => b.dataTreePath) as Binding[],
    ]),
  );

  const allBindings = Object.values(bindingsByBlock).flat();

  return { bindingsByBlock, allBindings };
}

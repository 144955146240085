import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const StabilityAiPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Stability AI',
  id: ExtendedIntegrationPluginId.STABILITYAI,
  authMethods: {
    bearer: true
  },
  serverURL: 'https://api.stability.ai',
  isStreamable: true
});

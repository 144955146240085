class Node {
  children: { [key: string]: Node } = {};
  isEndOfKey = false;
}

function insert(root: Node, key: string[]): void {
  let node = root;
  for (const part of key) {
    if (!node.children[part]) {
      node.children[part] = new Node();
    }
    node = node.children[part];
  }
  node.isEndOfKey = true;
}

export function splitKey(key: string): string[] {
  const parts: string[] = [];
  let partStartIndex = 0;

  for (let i = 0; i < key.length; i++) {
    if (key[i] === "." || key[i] === "[") {
      if (i !== partStartIndex) {
        parts.push(key.slice(partStartIndex, i));
      }
      if (key[i] === "[") {
        const closingBracketIndex = key.indexOf("]", i);
        // normalize the quote characters
        parts.push(key.slice(i, closingBracketIndex + 1).replaceAll("'", '"'));
        i = closingBracketIndex;
      }
      partStartIndex = i + 1;
    }
  }

  if (partStartIndex !== key.length) {
    parts.push(key.slice(partStartIndex));
  }

  return parts;
}

function getCondensedKeysFromNode(node: Node, currentPath: string[]): string[] {
  const keys: string[] = [];

  if (node.isEndOfKey) {
    keys.push(currentPath.join("."));
    return keys;
  }

  const childrenKeys = Object.keys(node.children);

  // If the current node has a single child and the child isn't an end of a key
  if (childrenKeys.length === 1 && !node.children[childrenKeys[0]].isEndOfKey) {
    return getCondensedKeysFromNode(
      node.children[childrenKeys[0]],
      currentPath.concat(childrenKeys[0]),
    );
  }

  // Otherwise, generate keys recursively for all children
  for (const part in node.children) {
    keys.push(
      ...getCondensedKeysFromNode(
        node.children[part],
        currentPath.concat(part),
      ),
    );
  }

  return keys;
}

export function condenseKeys(keys: string[]): string[] {
  const root = new Node();

  for (const key of keys) {
    insert(root, splitKey(key));
  }

  return getCondensedKeysFromNode(root, []).map((key) =>
    key.replaceAll(".[", "["),
  );
}

import { ApplicationScope } from "@superblocksteam/shared";
import { createDraft, finishDraft } from "immer";
import { isEqual } from "lodash";
import { call, select, take } from "redux-saga/effects";

import {
  getExistingPageNames,
  getExistingWidgetNames,
} from "legacy/selectors/sagaSelectors";
import { validateName } from "legacy/utils/helpers";

import { selectAllApiUnionNames } from "store/slices/apisShared/selectors";
import { GeneratorReturnType } from "store/utils/types";
import { Action, PayloadActionWithMeta } from "../../../utils/action";
import { callSagas, createSaga, SagaActionMeta } from "../../../utils/saga";
import * as BackendTypes from "../backend-types";
import { selectCachedControlFlowById } from "../control-flow/control-flow-selectors";
import { convertDSLToBackendBlocks } from "../control-flow/dsl-converters";
import {
  RefactoredType,
  refactorEntityName,
} from "../control-flow/refactor-entity-name";
import {
  type PersistApiPayload,
  persistV2ApiSaga,
} from "../sagas/persistV2Api";
import { updateV2ApiSaga } from "../sagas/updateV2Api";
import { selectV2ApiById } from "../selectors";
import slice, { type ApiDtoWithPb } from "../slice";
import { getV2ApiId } from "../utils/getApiIdAndName";

interface RefactorNamePayload {
  apiId: string;
  stepId?: string;
  oldName: string;
  newName: string;
  scope: ApplicationScope;
  namespace?: string;
  skipSave?: boolean;
}

export function* getUpdatedApi({
  api,
  apiId,
  oldName,
  newName,
  scope,
  namespace,
}: {
  api: NonNullable<ReturnType<typeof selectV2ApiById>>;
  apiId: string;
  oldName: string;
  newName: string;
  scope: ApplicationScope;
  namespace?: string;
}) {
  const draft = createDraft(api);

  if (draft.name === oldName && scope === ApplicationScope.PAGE) {
    draft.name = newName;
    draft.apiPb.metadata.name = newName;
  }

  const _controlFlow: ReturnType<typeof selectCachedControlFlowById> =
    yield select((state) => selectCachedControlFlowById(state, apiId));
  if (_controlFlow) {
    const controlFlowDraft = createDraft(_controlFlow);
    yield call(refactorEntityName, controlFlowDraft, {
      prevName: oldName,
      newName,
      refactoredType: RefactoredType.ENTITY,
      namespace,
    });
    const controlFlow = finishDraft(controlFlowDraft);
    if (!isEqual(controlFlow, _controlFlow)) {
      draft.apiPb.blocks = convertDSLToBackendBlocks(controlFlow);
    }
  }

  // Bug in Immer types, finishDraft() should return the type of the original, it doesn't
  return finishDraft(draft) as typeof api;
}

function* refactorNameInV2ApiInternal({
  apiId,
  newName,
  oldName,
  scope,
  namespace,
  skipSave = false,
}: RefactorNamePayload) {
  if (oldName === newName) {
    // no-op
    return;
  }
  const api: ReturnType<typeof selectV2ApiById> = yield select(
    selectV2ApiById,
    apiId,
  );
  if (!api) {
    return;
  }
  let existingWidgetNames: string[] = yield select(getExistingWidgetNames);
  existingWidgetNames = existingWidgetNames.filter(
    // widgets are already renamed; this gets around "Name is already taken"
    (widget) => widget !== newName,
  );
  const existingPageNames: string[] = yield select(getExistingPageNames);
  const existingApiNames: string[] = yield select(selectAllApiUnionNames);
  const nameError = validateName(newName, [
    ...existingWidgetNames,
    ...existingPageNames,
    ...existingApiNames,
  ]);
  if (nameError) {
    throw new Error(nameError);
  }

  const newApi: GeneratorReturnType<typeof getUpdatedApi> = yield call(
    getUpdatedApi,
    {
      api,
      apiId,
      oldName,
      newName,
      scope,
      namespace,
    },
  );

  if (skipSave || isEqual(newApi, api)) {
    return;
  }

  yield callSagas([updateV2ApiSaga.apply({ apiPb: newApi.apiPb })]);

  const result: PayloadActionWithMeta<
    BackendTypes.Api | Error,
    SagaActionMeta<BackendTypes.Api>
  > = yield take((action: Action) => {
    if (
      action.type === persistV2ApiSaga.success.type ||
      action.type === persistV2ApiSaga.error.type
    ) {
      const castedAction = action as PayloadActionWithMeta<
        ApiDtoWithPb,
        SagaActionMeta<PersistApiPayload>
      >;

      return castedAction.meta.args.api.metadata.id === getV2ApiId(api);
    }

    return false;
  });

  if (result.payload instanceof Error) {
    throw result.payload;
  }
}

export const refactorNameInV2ApiSaga = createSaga(
  refactorNameInV2ApiInternal,
  "refactorNameInV2ApiSaga",
  {
    sliceName: slice.name,
    keySelector: (payload) => payload.apiId,
  },
);

slice.saga(refactorNameInV2ApiSaga, {});

import { ThemeMode, Typographies } from "@superblocksteam/shared";
import { createSelector } from "reselect";
import { generateTypographies } from "legacy/themes/generateTypographies";
import type { GeneratedTheme, UserAccessibleTheme } from "legacy/themes/types";
import type { AppState } from "store/types";

export const selectGeneratedTheme = (state: AppState): GeneratedTheme =>
  state.legacy.ui.theme.generatedTheme;

export const selectStoredThemeTypographies = (
  state: AppState,
): Typographies | undefined => state.legacy.ui.theme.storedTypographies;

export const selectGeneratedThemeTypographies = (
  state: AppState,
): GeneratedTheme["typographies"] => {
  const theme = state.legacy.ui.theme.generatedTheme;
  if (theme.typographies) return theme.typographies;

  // this ONLY happens if the outer app is on a pre-typographies version and the inner iframe is not. it could be removed after GA
  return generateTypographies(theme.typographies, {
    colors: theme.colors,
    defaultFontFamily: theme.fontFamily,
  });
};

export const selectGeneratedThemeFontFamily = (
  state: AppState,
): GeneratedTheme["fontFamily"] =>
  state.legacy.ui.theme.generatedTheme.fontFamily;

export const selectAvailableFonts = (
  state: AppState,
): GeneratedTheme["availableFonts"] => {
  return state.legacy.ui.theme.generatedTheme.availableFonts;
};

export const selectOverrideThemeMode = (
  state: AppState,
): ThemeMode | undefined => state.legacy.ui.theme.overrideThemeMode;

export const getUserAvailableTheme = createSelector(
  selectGeneratedTheme,
  (generatedTheme) => {
    return {
      colors: generatedTheme.colors,
      mode: generatedTheme.mode,
      fontFamily: generatedTheme.fontFamily,
      padding: generatedTheme.padding,
      borderRadius: generatedTheme.borderRadius,
    } as UserAccessibleTheme;
  },
);

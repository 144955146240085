import {
  GetApiCommitsResponseBody,
  GetApplicationCommitsResponseBody,
  VersionedEntityType,
} from "@superblocksteam/shared";
import { call, select } from "redux-saga/effects";
import { getCurrentBranch } from "legacy/selectors/editorSelectors";
import { createSaga } from "../../../utils/saga";
import { getCommits } from "../client";
import slice from "../slice";

function* fetchCommitsSaga({
  entityId,
  entityType,
}: {
  entityId: string;
  entityType: VersionedEntityType;
}): Generator<
  any,
  GetApplicationCommitsResponseBody | GetApiCommitsResponseBody,
  any
> {
  const branch: ReturnType<typeof getCurrentBranch> =
    yield select(getCurrentBranch);
  const result: GetApplicationCommitsResponseBody | GetApiCommitsResponseBody =
    yield call(getCommits, entityId, entityType, branch?.name);
  return result;
}

const fetchCommits = createSaga(fetchCommitsSaga, fetchCommitsSaga.name, {
  sliceName: slice.name,
});
export { fetchCommits as fetchCommitsSaga };

slice.saga(fetchCommits, {
  start(state) {
    state.loading.commits = true;
    state.loading.autosaves = true;
  },
  success(state, { payload }) {
    state.entity = { autosaves: {}, commits: {} };
    for (const commit of payload.commits) {
      state.entity.commits[commit.commitId] = commit;
    }
    for (const autosave of payload.autosaves) {
      state.entity.autosaves[autosave.commitId] = autosave;
    }
    state.loading.commits = false;
    state.loading.autosaves = false;
  },
  error(state, { payload }) {
    state.error = { autosaves: payload, commits: payload };
    state.loading.commits = false;
    state.loading.autosaves = false;
  },
});

import {
  Agent,
  ApiToSign,
  ApiToVerify,
  AppToSign,
  AppToVerify,
  ClientMethods,
  MethodHandlers,
  Organization,
  ServerMethods,
  Signature,
} from "@superblocksteam/shared";
import { Api } from "store/slices/apisV2/backend-types";
import { orgIsCloud } from "store/slices/organizations/utils";
import { signResource, verifyResources } from "utils/resource-signing";

export function createRequestHandlers(
  agents: Agent[],
  organization: Organization,
): MethodHandlers<ClientMethods, ServerMethods, unknown> {
  if (orgIsCloud(organization)) {
    return {
      v1: {
        signing: {
          signApplication: [
            () => {
              throw new Error("Signing not implemented");
            },
          ],
          signApis: [
            () => {
              throw new Error("Signing not implemented");
            },
          ],
          verifyApplication: [
            () => {
              throw new Error("Signing not implemented");
            },
          ],
          verifyApi: [
            () => {
              throw new Error("Signing not implemented");
            },
          ],
        },
      },
    };
  }
  const requestHandlers: MethodHandlers<ClientMethods, ServerMethods, unknown> =
    {
      v1: {
        signing: {
          signApplication: [
            async ({
              branchName,
              toSign,
            }: {
              branchName: string;
              toSign: AppToSign;
            }) => {
              return await signResource({
                agents,
                organization,
                branchName,
                resource: {
                  literal: {
                    data: toSign.rootHash,
                  },
                },
              });
            },
          ],

          signApis: [
            async ({
              branchName,
              toSign,
            }: {
              branchName: string;
              toSign: ApiToSign[];
            }) => {
              const signatures: Signature[] = [];
              for (const { apiPb } of toSign) {
                const resp = await signResource({
                  agents,
                  organization,
                  branchName,
                  resource: { api: apiPb as Api },
                });
                signatures.push(resp.signature);
              }
              return { signatures };
            },
          ],
          verifyApplication: [
            async ({
              branchName,
              toVerify,
            }: {
              branchName: string;
              toVerify: AppToVerify;
            }) => {
              try {
                await verifyResources({
                  agents,
                  organization,
                  branchName,
                  resources: [
                    {
                      literal: {
                        data: toVerify.rootHash,
                        signature: toVerify.signature,
                      },
                    },
                  ],
                });
                return { ok: true };
              } catch {
                return { ok: false };
              }
            },
          ],

          verifyApi: [
            async ({
              branchName,
              toVerify,
            }: {
              branchName: string;
              toVerify: ApiToVerify[];
            }) => {
              try {
                await verifyResources({
                  agents,
                  organization,
                  branchName,
                  resources: toVerify.map(({ apiPb }) => ({
                    api: apiPb as Api,
                  })),
                });
                return { ok: true };
              } catch {
                return { ok: false };
              }
            },
          ],
        },
      },
    };
  return requestHandlers;
}

import {
  DATA_DOMAIN_HEADER,
  SUPERBLOCKS_AUTHORIZATION_HEADER,
  SUPERBLOCKS_EMBED_HEADER,
} from "@superblocksteam/shared";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import AuthProvider from "auth/auth0";
import TokenProvider from "auth/token";
import {
  SUPERBLOCKS_UI_API_ENDPOINT_BASE_URL,
  SUPERBLOCKS_UI_DATA_DOMAIN,
} from "env";
import {
  API_REQUEST_HEADERS,
  API_STATUS_CODES,
  ERROR_CODES,
  REQUEST_TIMEOUT_MS,
} from "legacy/constants/ApiConstants";
import { convertObjectToQueryParams } from "legacy/utils/Utils";
import { isOnEmbedRoute } from "utils/embed/messages";
import { ERROR_500, SERVER_API_TIMEOUT_ERROR } from "../constants/messages";
import { ApiResponse } from "./ApiResponses";
import type { ActionApiResponse } from "./ActionAPI";

//TODO(abhinav): Refactor this to make more composable.
const apiRequestConfig = {
  baseURL: SUPERBLOCKS_UI_API_ENDPOINT_BASE_URL || "/api/",
  timeout: REQUEST_TIMEOUT_MS,
  headers: API_REQUEST_HEADERS,
  withCredentials: true,
};

const axiosInstance: AxiosInstance = axios.create();

const axiosConnectionAbortedCode = "ECONNABORTED";
const executeActionRegex = /actions\/execute/;
const timeoutErrorRegex = /timeout of (\d+)ms exceeded/;

axiosInstance.interceptors.request.use((config: any) => {
  return { ...config, timer: performance.now() };
});

const makeExecuteActionResponse = (response: any): ActionApiResponse => ({
  ...response.data,
  clientMeta: {
    size: response.headers["content-length"],
    duration: Number(performance.now() - response.config.timer).toFixed(),
  },
});

const is404orAuthPath = () => {
  const pathName = window.location.pathname;
  return /^\/404/.test(pathName) || /^\/user\/\w+/.test(pathName);
};

const getHeaders = (prevHeaders: any, token?: string) => {
  const authorizationJwt = TokenProvider.getToken();
  return {
    headers: {
      ...prevHeaders,
      [SUPERBLOCKS_EMBED_HEADER]: isOnEmbedRoute() ? "true" : "false",
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      // always pass sb jwt when token is present
      ...(token && authorizationJwt
        ? { [SUPERBLOCKS_AUTHORIZATION_HEADER]: `Bearer ${authorizationJwt}` }
        : {}),
      [DATA_DOMAIN_HEADER]: SUPERBLOCKS_UI_DATA_DOMAIN,
    },
  };
};

axiosInstance.interceptors.response.use(
  (response: any): any => {
    if (response.config.url.match(executeActionRegex)) {
      return makeExecuteActionResponse(response);
    }
    // Do something with response data
    return response.data;
  },
  function (error: any) {
    // Return if the call was cancelled via cancel token
    if (axios.isCancel(error)) {
      return;
    }
    // Return modified response if action execution failed
    if (error.config && error.config.url.match(executeActionRegex)) {
      return makeExecuteActionResponse(error.response);
    }
    // Return error if any timeout happened in other api calls
    if (
      error.code === axiosConnectionAbortedCode &&
      error.message &&
      error.message.match(timeoutErrorRegex)
    ) {
      return Promise.reject({
        ...error,
        message: SERVER_API_TIMEOUT_ERROR,
        code: ERROR_CODES.REQUEST_TIMEOUT,
      });
    }

    if (error.response) {
      if (error.response.status === API_STATUS_CODES.SERVER_ERROR) {
        return Promise.reject({
          ...error,
          code: ERROR_CODES.SERVER_ERROR,
          message: ERROR_500,
        });
      }

      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      if (
        !is404orAuthPath() &&
        error.response.status === API_STATUS_CODES.REQUEST_UNAUTHORIZED
      ) {
        return Promise.reject({
          code: ERROR_CODES.REQUEST_UNAUTHORIZED,
          message: "Unauthorized. Redirecting to login page...",
          show: false,
          errorType: error.response.data?.responseMeta?.error?.superblocksError,
          errorMessage: error.response.data?.responseMeta?.error?.message,
        });
      }
      // responseMeta is set by our internal APIs, for example /execute.
      // This wraps any errors in a 200 status code
      if (error.response.data?.responseMeta) {
        return Promise.resolve(error.response.data);
      }
      // Fall back to throwing error if none of the above are caught
      if (typeof error.response.data === "string") {
        return Promise.reject({
          code: error.response.status,
          message: error.response.data,
        });
      }
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error", error.message);
    }
    console.log(error.config);
    return Promise.resolve(error);
  },
);

class Api {
  static get<T extends ApiResponse>(
    url: string,
    queryParams?: any,
    config: Partial<AxiosRequestConfig> = {},
  ): Promise<T> {
    if (AuthProvider.ready()) {
      return AuthProvider.generateToken().then((token) =>
        axiosInstance.get(url + convertObjectToQueryParams(queryParams), {
          ...apiRequestConfig,
          ...config,
          // We're passing through Global Accelerator to access the regional caches
          // as this is the only cached endpoint from the UI currently.
          // We must pass the data domain header to ensure the correct upstream server
          // is hit.
          ...getHeaders(
            { ...apiRequestConfig.headers, ...config?.headers },
            token,
          ),
        }),
      );
    } else {
      return axiosInstance.get(url + convertObjectToQueryParams(queryParams), {
        ...apiRequestConfig,
        ...config,
        ...getHeaders({ ...apiRequestConfig.headers, ...config?.headers }),
      });
    }
  }

  static post<T extends ApiResponse>(
    url: string,
    body?: any,
    queryParams?: any,
    config: Partial<AxiosRequestConfig> = {},
  ): Promise<T> {
    if (AuthProvider.ready()) {
      return AuthProvider.generateToken().then((token) =>
        axiosInstance.post(
          url + convertObjectToQueryParams(queryParams),
          body,
          {
            ...apiRequestConfig,
            ...config,
            ...getHeaders(apiRequestConfig.headers, token),
          },
        ),
      );
    } else {
      return axiosInstance.post(
        url + convertObjectToQueryParams(queryParams),
        body,
        {
          ...apiRequestConfig,
          ...config,
          ...getHeaders(apiRequestConfig.headers),
        },
      );
    }
  }

  static put<T extends ApiResponse>(
    url: string,
    body?: any,
    queryParams?: any,
    config: Partial<AxiosRequestConfig> = {},
  ): Promise<T> {
    return AuthProvider.generateToken().then((token) =>
      axiosInstance.put(url + convertObjectToQueryParams(queryParams), body, {
        ...apiRequestConfig,
        ...config,
        ...getHeaders(apiRequestConfig.headers, token),
      }),
    );
  }

  static delete<T extends ApiResponse>(
    url: string,
    queryParams?: any,
    config: Partial<AxiosRequestConfig> = {},
  ): Promise<T> {
    return AuthProvider.generateToken().then((token) =>
      axiosInstance.delete(url + convertObjectToQueryParams(queryParams), {
        ...apiRequestConfig,
        ...config,
        ...getHeaders(apiRequestConfig.headers, token),
      }),
    );
  }
}

export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

export default Api;

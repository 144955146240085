import {
  ENVIRONMENT_PRODUCTION,
  Organization,
  Profile,
  ViewMode,
} from "@superblocksteam/shared";
import { createCachedSelector } from "re-reselect";
import { matchPath } from "react-router";
import {
  APPLICATIONS_URL,
  INTEGRATIONS_URL,
  SCHEDULED_JOB_URL,
  WORKFLOW_URL,
} from "legacy/constants/routes";
import {
  getAppProfilesInCurrentMode,
  getAppViewMode,
  getProfileForTest,
} from "legacy/selectors/applicationSelectors";
import { getEnvironment } from "store/slices/application/selectors";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";

const profilesSelector = (state: any) =>
  selectFlagById(state, Flag.ENABLE_PROFILES);

export const selectCurrentProfileForApi = createCachedSelector(
  selectOnlyOrganization,
  profilesSelector,
  (state, pathname) => {
    const workflowMatch = matchPath(
      { path: WORKFLOW_URL, end: false },
      pathname,
    );
    const jobMatch = matchPath(
      { path: SCHEDULED_JOB_URL, end: false },
      pathname,
    );
    const integrationsMatch = matchPath(
      { path: INTEGRATIONS_URL, end: false },
      pathname,
    );
    if (integrationsMatch) {
      return {
        key: ENVIRONMENT_PRODUCTION,
      };
    }

    const apiId =
      (workflowMatch?.params as Record<string, string>)?.apiId ??
      (jobMatch?.params as Record<string, string>)?.apiId;
    return apiId ? getProfileForTest(state, apiId) : undefined;
  },
  getAppProfilesInCurrentMode,
  getAppViewMode,
  getEnvironment,
  (state: any, pathname: string, environment?: string) => {
    const appMatch = matchPath(
      { path: APPLICATIONS_URL, end: false },
      pathname,
    );
    return appMatch != null;
  },
  (state: any, pathname: string, environment?: string) => environment,
  (
    organization: Organization | undefined,
    enableProfiles,
    profileForTest,
    appProfiles,
    appViewMode,
    currEnvironment,
    isApp,
    argEnvironment,
  ) => {
    // get profile given mode
    let profile: Partial<Profile> | undefined;
    let mode: ViewMode | undefined;
    let inAppProfileSwitch = false;
    if (isApp) {
      profile = appProfiles?.selected;
      mode = appViewMode;
      if (appProfiles?.selected?.id !== appProfiles?.default?.id) {
        inAppProfileSwitch = true;
      }
    } else {
      // workflows, scheduled jobs, datasource test
      profile = profileForTest;
      mode = ViewMode.EDITOR;
    }
    const environmentIfNoProfile = argEnvironment ?? currEnvironment;
    const appOrApiKey = enableProfiles ? profile?.key : undefined;
    const keyToMatch = appOrApiKey ?? environmentIfNoProfile;
    const profileId =
      organization?.profiles?.find((p) => {
        return p.key === keyToMatch;
      })?.id ?? "";

    return {
      profile,
      profileId,
      profileKey: keyToMatch,
      mode,
      inAppProfileSwitch,
      appOrApiProfileLoaded: Boolean(profile?.key),
    };
  },
)(
  (state: any, pathname: string, environment?: string) =>
    `${pathname}-${environment}`,
);

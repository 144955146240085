import { Agent, Organization } from "@superblocksteam/shared";
import { HttpCallOptions, callHelper } from "store/utils/client";
import { noActiveAgentMessage } from "utils/error/error";
import { orgIsOnPremise } from "../organizations/utils";

type ParametersExceptFirst<F> = F extends (arg0: any, ...rest: infer R) => any
  ? R
  : never;

export type CallOrchestratorPayload = HttpCallOptions & {
  agents: Agent[];
  organization: Organization;
};

// baseUrl gets ignored if on premise and agents are supplied
export async function callOrchestrator<T>(
  payload: CallOrchestratorPayload,
  ...restOfArgs: ParametersExceptFirst<typeof callHelper>
): Promise<
  | T
  | void
  | {
      systemError: string;
    }
> {
  const { agents, organization, ...rest } = payload;
  const isOnPremise = orgIsOnPremise(organization);

  if (isOnPremise && !agents.length) {
    console.warn(
      `No active agent of type ${organization.agentType} found for organization ${organization.id}`,
    );
    return {
      systemError: noActiveAgentMessage(organization.agentType),
    };
  }
  const result = await callHelper<T>(
    {
      ...rest,
      baseUrl: isOnPremise ? agents[0].url : rest.baseUrl,
    },
    ...restOfArgs,
  );

  return result;
}

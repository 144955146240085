import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { PUBLIC_INTEGRATIONS_CDN_URL, getNativeOpenApiPlugin } from './shared';

export const DropboxPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Dropbox',
  id: ExtendedIntegrationPluginId.DROPBOX,
  authMethods: {
    oauth2BringYourOwn: {
      authorizationUrl: 'https://www.dropbox.com/oauth2/authorize',
      tokenUrl: 'https://www.dropbox.com/oauth2/token',
      revokeTokenUrl: 'https://api.dropboxapi.com/2/auth/token/revoke',
      iconUrl: `${PUBLIC_INTEGRATIONS_CDN_URL}/img/dropbox.png`,
      authorizationExtraParams: {
        stateConfigExclude: ['datasource-auth-state'],
        responseType: 'code'
      }
    }
  },
  serverURL: 'https://content.dropboxapi.com'
});

import { IApiV3Dto } from "@superblocksteam/shared";
import { ApiTriggerType } from "store/slices/apis/types";
import type { ApiDtoWithPb } from "../slice";

// making this a fn in case need to refactor to use apiPb's id
export const getV2ApiId = (api: ApiDtoWithPb | IApiV3Dto): string => {
  return api.apiPb?.metadata?.id ?? api?.id ?? "";
};

export const getV2ApiName = (api: ApiDtoWithPb): string => {
  if (api.triggerType !== ApiTriggerType.UI) {
    return api.name ?? api.apiPb?.metadata?.name ?? api?.name ?? "";
  }
  return api.apiPb?.metadata?.name ?? api?.name ?? "";
};

export enum WidgetTypes {
  PAGE_WIDGET = 'PAGE_WIDGET',
  SECTION_WIDGET = 'SECTION_WIDGET',
  BUTTON_WIDGET = 'BUTTON_WIDGET',
  TEXT_WIDGET = 'TEXT_WIDGET',
  IMAGE_WIDGET = 'IMAGE_WIDGET',
  INPUT_WIDGET = 'INPUT_WIDGET',
  CONTAINER_WIDGET = 'CONTAINER_WIDGET',
  DATE_PICKER_WIDGET = 'DATE_PICKER_WIDGET',
  TABLE_WIDGET = 'TABLE_WIDGET',
  DROP_DOWN_WIDGET = 'DROP_DOWN_WIDGET',
  CHECKBOX_WIDGET = 'CHECKBOX_WIDGET',
  RADIO_GROUP_WIDGET = 'RADIO_GROUP_WIDGET',
  TABS_WIDGET = 'TABS_WIDGET',
  MODAL_WIDGET = 'MODAL_WIDGET',
  SLIDEOUT_WIDGET = 'SLIDEOUT_WIDGET',
  RICH_TEXT_EDITOR_WIDGET = 'RICH_TEXT_EDITOR_WIDGET',
  CHART_WIDGET = 'CHART_WIDGET',
  FORM_WIDGET = 'FORM_WIDGET',
  FORM_BUTTON_WIDGET = 'FORM_BUTTON_WIDGET',
  MAP_WIDGET = 'MAP_WIDGET',
  CANVAS_WIDGET = 'CANVAS_WIDGET',
  ICON_WIDGET = 'ICON_WIDGET',
  FILE_PICKER_WIDGET = 'FILE_PICKER_WIDGET',
  VIDEO_WIDGET = 'VIDEO_WIDGET',
  SKELETON_WIDGET = 'SKELETON_WIDGET',
  GRID_WIDGET = 'GRID_WIDGET',
  CODE_WIDGET = 'CODE_WIDGET',
  PDF_VIEWER_WIDGET = 'PDF_VIEWER_WIDGET',
  IFRAME_WIDGET = 'IFRAME_WIDGET',
  DIFF_WIDGET = 'DIFF_WIDGET',
  CHAT_WIDGET = 'CHAT_WIDGET',
  CUSTOM_WIDGET = 'CUSTOM_WIDGET',
  UNREGISTERED_CUSTOM_WIDGET = 'UNREGISTERED_CUSTOM_WIDGET',
  SWITCH_WIDGET = 'SWITCH_WIDGET',
  CALLOUT_WIDGET = 'CALLOUT_WIDGET',
  MENU_WIDGET = 'MENU_WIDGET',
  LINK_WIDGET = 'LINK_WIDGET'
}

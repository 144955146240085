import { createReducer } from "@reduxjs/toolkit";
import { renameWidgets } from "legacy/actions/controlActions";
import { stopEvaluation } from "legacy/actions/evaluationActions";
import {
  createPageSuccess,
  deletePageInit,
  switchCurrentPage,
  updateCurrentRoute,
  updateResponsiveCanvasMetadata,
  updateScrollbarWidth,
} from "legacy/actions/pageActions";
import { PageListPayload } from "legacy/constants/ReduxActionConstants";
import { PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import { fetchPageListSuccess } from "store/slices/application/applicationActions";
import logger from "../../../utils/logger";

type ResponsiveCanvasMetadataState = {
  unscaledWidth: number;
  canvasScaleFactor: number;
  scaledWidth: number;
  scrollbarWidth: number;
};

const initialState: PageListReduxState = {
  pages: [],
  responsiveCanvasMetadata: {
    unscaledWidth: Number.MAX_SAFE_INTEGER,
    canvasScaleFactor: 1,
    scaledWidth: Number.MAX_SAFE_INTEGER,
    scrollbarWidth: 0,
  },
};

const pageListReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(stopEvaluation, () => initialState)
    .addCase(deletePageInit, (state, action) => {
      const pages = [
        ...state.pages.filter((page) => page.pageId !== action.payload.pageId),
      ];
      return {
        ...state,
        pages,
      };
    })
    .addCase(fetchPageListSuccess, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    })
    .addCase(createPageSuccess, (state, action) => {
      const { pageId, pageName } = action.payload;
      return { ...state, pages: [...state.pages, { pageId, pageName }] };
    })
    .addCase(updateCurrentRoute, (state, action) => {
      if (!action.payload) {
        return state;
      }
      if (action.payload && "pageId" in action.payload.routeDef) {
        return {
          ...state,
          currentPageId: action.payload?.routeDef?.pageId,
        };
      }
      return state;
    })
    .addCase(switchCurrentPage, (state, action) => ({
      ...state,
      currentPageId: action.payload.id,
    }))
    .addCase(renameWidgets, (state, action) => {
      const match = action.payload.updates.find(
        (update) =>
          update.widgetId === PAGE_WIDGET_ID &&
          update.propertyName === "widgetName",
      );
      if (match) {
        const currentPage = state.pages.find(
          (page) => page.pageId === state.currentPageId,
        );
        if (currentPage) {
          currentPage.pageName = match.propertyValue as string;
        }
      }
    })
    .addCase(updateScrollbarWidth, (state, action) => {
      state.responsiveCanvasMetadata.scrollbarWidth =
        action.payload.scrollbarWidth;
    })
    .addCase(updateResponsiveCanvasMetadata, (state, action) => {
      const payload = action.payload;
      const unscaledWidth =
        payload.unscaledWidth ?? state.responsiveCanvasMetadata.unscaledWidth;
      const canvasScaleFactor =
        payload.canvasScaleFactor ??
        state.responsiveCanvasMetadata.canvasScaleFactor;
      if (!payload.unscaledWidth && !payload.canvasScaleFactor) {
        logger.warn(
          "Redux action UPDATE_RESPONSIVE_CANVAS_METADATA has been triggered, but the state doesn't change.",
        );
      }
      return {
        ...state,
        responsiveCanvasMetadata: {
          ...state.responsiveCanvasMetadata,
          unscaledWidth,
          canvasScaleFactor,
          scaledWidth: unscaledWidth / canvasScaleFactor,
        },
      };
    }),
);

export interface PageListReduxState {
  pages: PageListPayload;
  applicationId?: string;
  currentPageId?: string;
  responsiveCanvasMetadata: ResponsiveCanvasMetadataState;
}

export default pageListReducer;

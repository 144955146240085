import { createCachedSelector } from "re-reselect";
import { ApiDto } from "store/slices/apis/types";
import { selectV2UserAccessibleScope } from "store/slices/apisV2/control-flow/control-flow-scope-selectors";
import { type ApiDtoWithPb } from "store/slices/apisV2/slice";
import { ApiScope } from "utils/dataTree/scope";
import { selectUserAccessibleScope, selectApiById } from "../../apis/selectors";
import { selectV2ApiById } from "../../apisV2/selectors";

export const selectUserAccessibleScopeForApiUnion = createCachedSelector(
  selectApiById,
  selectV2ApiById,
  selectUserAccessibleScope,
  selectV2UserAccessibleScope,
  (
    apiV1: undefined | ApiDto,
    apiV2: undefined | ApiDtoWithPb,
    v1Scope: ApiScope | undefined,
    v2Scope: ApiScope | undefined,
  ): ApiScope | undefined => {
    if (apiV2) {
      return v2Scope;
    } else if (apiV1) {
      return v1Scope;
    }
  },
)(
  (state, apiId: string | undefined, actionId: string | undefined) =>
    `${apiId}_${actionId}`,
);

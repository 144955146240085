import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getEditorBasePath } from "hooks/store/useGetEditorPath";
import {
  EditorRoute,
  HomeModalRoutes,
  HOME_MODAL_URL,
} from "legacy/constants/routes";
import { getDemoApplication } from "legacy/selectors/applicationSelectors";

const DemoLink = ({ children }: { children: React.ReactNode }) => {
  const app = useSelector(getDemoApplication);
  const navigate = useNavigate();
  const appUrl = app
    ? getEditorBasePath(EditorRoute.EditApplication, { applicationId: app.id })
    : HOME_MODAL_URL(HomeModalRoutes.DEMO_VIDEO);
  return (
    <div
      onClick={() => {
        navigate({ pathname: appUrl });
      }}
    >
      {children}
    </div>
  );
};
export default DemoLink;

import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const MistralPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Mistral',
  id: ExtendedIntegrationPluginId.MISTRAL,
  authMethods: {
    bearer: true
  },
  serverURL: 'https://api.mistral.ai/v1',
  isStreamable: true
});

// Color Names generated from http://chir.ag/projects/name-that-color
export const LegacyNamedColors = {
  WHITE: "#FFFFFF",
  GEYSER_LIGHT: "#D0D7DD",
  ATHENS_GRAY: "#EBEFF2",
  MYSTIC: "#E1E8ED",
  BINDING_COLOR: "#ffcc99",
  BINDING_COLOR_LT: "#d4b9ea",
  BLACK: "#000000",
  BLACK_PEARL: "#040627",
  SHARK: "#21282C",
  SLATE_GRAY: "#768896",
  HIT_GRAY: "#A1ACB3",
  MERCURY: "#E8E8E8",

  JUNGLE_GREEN: "#24BA91",
  JUNGLE_GREEN_DARKER: "#30A481",
  ERROR_RED: "#E22C2C",
  OXFORD_BLUE: "#2E3D49",
  FRENCH_PASS: "#BBE8FE",
  JAFFA: "#F2994A",
  ATHENS_GRAY_DARKER: "#F8F9FA",
  POMEGRANATE: "#F44336",
  BALTIC_SEA: "#1A191C",
  THUNDER: "#231F20",

  // Superblocks Colors
  PURPLE: "#29ADE9",
  RED_LIGHT: "#FF8E8F",
  RED: "#FF4D4F",
  GREEN: "#51C51A",

  GRAY_LIGHT: "#E5E5E5",
  GRAY: "#B2B2B2",
  GRAY_DARK: "#959595",
  GRAY_DARKER: "#3C4149",
} as const;

export type LegacyNamedColor =
  (typeof LegacyNamedColors)[keyof typeof LegacyNamedColors];

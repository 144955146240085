import { fastClone } from "utils/clone";
import * as BackendTypes from "../backend-types";

export function createDuplicateV2ApiPayload(
  originalApi: BackendTypes.Api,
  params: {
    newId: string;
    newName: string;
  },
) {
  const { newId, newName } = params;
  const newApi = fastClone(originalApi);

  if (newApi.metadata.timestamps) {
    delete newApi.metadata.timestamps;
  }
  newApi.metadata.id = newId;
  newApi.metadata.name = newName;
  return newApi;
}

const hardCodedPromptActions = {
  connectData: [
    {
      properties: ["tableData", "tableHeader"],
    },
    {
      action: "set",
      property: "tableData",
      value: "{{ creditCardApplications.response }}",
    },
    {
      action: "set",
      property: "tableHeader",
      value: "Credit Card Applications",
    },
  ],
  formatTable: [
    {
      properties: [
        "isDownloadable",
        "isSearchable",
        "defaultSort",
        "column.business_name.isFrozen",
        "column.application_timestamp.isFrozen",
        "column.industry_category.columnType",
        "column.status.columnType",
        "column.application_timestmap.columnType",
        "column.application_timestamp.outputFormat",
      ],
    },
    {
      action: "set",
      property: "isDownloadable",
      value: false,
    },
    {
      action: "set",
      property: "isSearchable",
      value: false,
    },
    {
      action: "set",
      property: "defaultSort",
      value: {
        asc: false,
        column: "application_timestamp",
      },
    },
    {
      action: "set",
      column: "business_name",
      property: "isFrozen",
      value: true,
    },
    {
      action: "set",
      column: "application_timestamp",
      property: "isFrozen",
      value: true,
    },
    {
      action: "set",
      column: "industry_category",
      property: "columnType",
      value: "tags",
    },
    {
      action: "set",
      column: "status",
      property: "columnType",
      value: "tags",
    },
    {
      action: "set",
      column: "application_timestamp",
      property: "columnType",
      value: "date",
    },
    {
      action: "set",
      column: "application_timestamp",
      property: "outputFormat",
      value: "YYYY MMM Do",
    },
  ],
  flagTransaction: [
    {
      properties: [
        "column.flagTransaction.label",
        "column.flagTransaction.isEnabled",
      ],
    },
    {
      action: "set",
      column: "flagTransaction",
      property: "label",
      value: "Flag Transaction",
    },
    {
      action: "set",
      column: "flagTransaction",
      property: "columnType",
      value: "button",
    },
    {
      action: "set",
      column: "flagTransaction",
      property: "buttonLabel",
      value: "Flag",
    },
    {
      action: "set",
      column: "flagTransaction",
      property: "isDisabled",
      value:
        "{{ !Global.user.groups.some(group => group.name === 'RiskOps') }}",
    },
  ],
};

export default hardCodedPromptActions;

import { ApiTriggerType, ApplicationScope } from "@superblocksteam/shared";
import { get } from "lodash";
import { call, take, select } from "redux-saga/effects";
import { evaluateActionBindings } from "legacy/sagas/EvaluationsShared";
import { fastClone } from "utils/clone";
import { callSagas, createSaga } from "../../../utils/saga";
import { selectV2UserAccessibleScope } from "../control-flow/control-flow-scope-selectors";
import { selectCachedControlFlowById } from "../control-flow/control-flow-selectors";
import {
  BlockType,
  VariableEntry,
  VariablesControlBlock,
} from "../control-flow/types";
import {
  selectV2ApiBindingsByBlockById,
  selectV2ApiBlockBindingsByBlock,
  selectV2ApiById,
  selectV2ApiMetaById,
  selectV2ApiVariableBindingsByBlock,
} from "../selectors";
import slice, { StepRunTestData, TestDataType } from "../slice";
import { getTriggerTypeFromApi } from "../utils/api-utils";
import { condenseKeys } from "../utils/condense-keys";
import {
  extractDataFromScope,
  getV2ApiBlockDepsSaga,
} from "./getV2ApiBlockDeps";
import { getV2ApiToComponentDepsSaga } from "./getV2ApiToComponentDeps";

const MAX_DATA_SIZE_BYTES = 150000;

const WORKFLOW_GLOBAL_KEYS = ["body", "params"];
const WORKFLOW_GLOBAL_KEYS_TO_PATHS: Record<string, string> = {
  body: "trigger.workflow.parameters.body",
  params: "trigger.workflow.parameters.query",
};

const processTestData = (
  data: unknown,
  variableName: string,
): number | string | boolean => {
  if (typeof data === "number" || typeof data === "boolean") {
    return data;
  }
  if (typeof data === "string") {
    // if string starts with {{ and ends with }}, remove them
    if (data.startsWith("{{") && data.endsWith("}}")) {
      return data.slice(2, -2);
    }
    // if it starts and ends with quotes just return it
    if (
      (data.startsWith('"') && data.endsWith('"')) ||
      (data.startsWith("'") && data.endsWith("'"))
    ) {
      return data;
    }
  }
  // special case for index.value (remove once backend is updated)
  if (variableName === "index.value" && data == null) {
    return 0;
  }

  const parsedData = JSON.stringify(data, null, 2);

  // if the data is too big, just return the variable name
  if (parsedData != null && parsedData.length > MAX_DATA_SIZE_BYTES) {
    return variableName;
  }
  return parsedData;
};

function* getV2ApiTestDataInternal({
  apiId,
  blockName,
  computeDependencies,
  computeForHiddenForm,
}: {
  apiId: string;
  blockName: string;
  computeDependencies?: boolean;
  computeForHiddenForm?: boolean;
}) {
  let apiMeta: ReturnType<typeof selectV2ApiMetaById> = yield select(
    selectV2ApiMetaById,
    apiId,
  );
  if (!apiMeta?.showTestDataForBlock?.[blockName] && !computeForHiddenForm) {
    return;
  }

  const api: ReturnType<typeof selectV2ApiById> = yield select((state) =>
    selectV2ApiById(state, apiId),
  );

  if (!api) {
    return;
  }
  const triggerType = getTriggerTypeFromApi(api.apiPb);

  const testData = fastClone(apiMeta?.testDataForBlock?.[blockName]) ?? {};
  const cachedTestDataForBlock =
    fastClone(apiMeta?.cachedTestDataForBlock?.[blockName]) ?? {};
  // compute internal and external dependencies
  if (computeDependencies) {
    yield callSagas([
      ...(triggerType === ApiTriggerType.UI
        ? [getV2ApiToComponentDepsSaga.apply({ apiIdsToAnalyze: [apiId] })]
        : []),
      getV2ApiBlockDepsSaga.apply({
        apiIdsToAnalyze: [apiId],
        forceEvaluation: true,
      }),
    ]);
  } else {
    apiMeta = yield select(selectV2ApiMetaById, apiId);
    if (
      (apiMeta?.needsBindingExtraction || apiMeta?.extractingBindings) &&
      triggerType === ApiTriggerType.UI
    ) {
      yield take([
        getV2ApiToComponentDepsSaga.error.type,
        getV2ApiToComponentDepsSaga.success.type,
      ]);
    }
    apiMeta = yield select(selectV2ApiMetaById, apiId);
    if (apiMeta?.extractingBlockBindings) {
      yield take([
        getV2ApiBlockDepsSaga.success.type,
        getV2ApiBlockDepsSaga.error.type,
      ]);
    }
  }
  // Compute external binding test data
  const bindingsByBlockId: ReturnType<typeof selectV2ApiBindingsByBlockById> =
    yield select(selectV2ApiBindingsByBlockById, apiId);
  const externalBindings = (bindingsByBlockId?.[blockName] ?? []).map(
    (b) => b.str,
  );
  const values: unknown[] = yield call(
    evaluateActionBindings,
    externalBindings,
    ApplicationScope.PAGE, // TODO(API_SCOPE)
  );
  const rawBindingValues = Array(
    Math.max(externalBindings.length, values.length),
  )
    .fill(undefined)
    .reduce((accum: Record<string, unknown>, _, i) => {
      accum[externalBindings[i]] = values[i];
      return accum;
    }, {});

  externalBindings.forEach((binding) => {
    if (!binding) return;
    if (testData[binding]?.isDirty) {
      // just reset initial value, leave user-entered value
      testData[binding].initialValue = processTestData(
        rawBindingValues[binding],
        binding,
      );
    } else {
      testData[binding] = {
        initialValue: processTestData(rawBindingValues[binding], binding),
        type: TestDataType.EXTERNAL,
        isDirty: cachedTestDataForBlock[binding]?.value != null,
        value: cachedTestDataForBlock[binding]?.value ?? undefined,
      };
    }
  });

  // Compute block output binding test data
  const blockBindingsByBlock: ReturnType<
    typeof selectV2ApiBlockBindingsByBlock
  > = yield select(selectV2ApiBlockBindingsByBlock, apiId);
  const blockBindings = blockBindingsByBlock?.[blockName] ?? [];

  if (blockBindings.length) {
    const accessibleScope: ReturnType<typeof selectV2UserAccessibleScope> =
      yield select(selectV2UserAccessibleScope, apiId, blockName);
    const scopeValues = extractDataFromScope(
      accessibleScope?.v2ComputedScope ?? {},
    );
    blockBindings.forEach((binding) => {
      if (testData[binding]?.isDirty) {
        testData[binding].initialValue = processTestData(
          get(scopeValues, binding),
          binding,
        );
      } else {
        testData[binding] = {
          type: TestDataType.BLOCK_OUTPUT,
          isDirty: cachedTestDataForBlock[binding]?.value != null,
          value: cachedTestDataForBlock[binding]?.value ?? undefined,
          initialValue: processTestData(get(scopeValues, binding), binding),
        };
      }
    });
  }

  // Compute variables test data
  const variableBindingsByBlock: ReturnType<
    typeof selectV2ApiVariableBindingsByBlock
  > = yield select(selectV2ApiVariableBindingsByBlock, apiId);
  const variableBindings = variableBindingsByBlock?.[blockName] ?? [];
  if (variableBindings.length) {
    const controlFlow: ReturnType<typeof selectCachedControlFlowById> =
      yield select((state) => selectCachedControlFlowById(state, apiId));
    if (controlFlow) {
      const allVariablesByKey = Object.values(controlFlow.blocks).reduce(
        (accum, block) => {
          if (block.type === BlockType.VARIABLES) {
            const variablesBlock = block as VariablesControlBlock;
            variablesBlock.config.variables.forEach((variable) => {
              accum[variable.key] = variable;
            });
          }
          return accum;
        },
        {} as Record<string, VariableEntry>,
      );
      variableBindings.forEach((binding) => {
        const variable = allVariablesByKey[binding.split(".")[0]];
        if (testData[binding]?.isDirty) {
          testData[binding].initialValue = processTestData(
            variable?.value,
            binding,
          );
        } else {
          testData[binding] = {
            initialValue: processTestData(variable?.value, binding),
            type: TestDataType.VARIABLE,
            isDirty: cachedTestDataForBlock[binding]?.value != null,
            value: cachedTestDataForBlock[binding]?.value ?? undefined,
          };
        }
      });
    }
  }
  // in workflows, add body and params
  if (triggerType === ApiTriggerType.WORKFLOW) {
    WORKFLOW_GLOBAL_KEYS.forEach((key) => {
      if (testData?.[key]?.isDirty) {
        testData[key].initialValue = processTestData(
          get(api.apiPb, WORKFLOW_GLOBAL_KEYS_TO_PATHS[key]),
          key,
        );
      } else {
        testData[key] = {
          initialValue: processTestData(
            get(api.apiPb, WORKFLOW_GLOBAL_KEYS_TO_PATHS[key]),
            key,
          ),
          type: TestDataType.EXTERNAL,
          isDirty: cachedTestDataForBlock[key]?.value != null,
          value: cachedTestDataForBlock[key]?.value ?? undefined,
        };
      }
    });
  }

  // Delete any fields from testData that are no longer part of any bindings and move them to the cached data
  const allBindings = [
    ...externalBindings,
    ...blockBindings,
    ...variableBindings,
    ...(triggerType === ApiTriggerType.WORKFLOW ? WORKFLOW_GLOBAL_KEYS : []),
  ];

  Object.keys(testData).forEach((key) => {
    if (!allBindings.includes(key)) {
      cachedTestDataForBlock[key] = testData[key];
      delete testData[key];
    }
  });

  // This will merge keys like a.b.c and a.b into a.b
  // it will leave siblings (i.e. a.b.c and a.b.d) separate
  const condensedKeys = condenseKeys(Object.keys(testData));
  const finalTestData = condensedKeys.reduce((accum, key) => {
    accum[key] = testData[key];
    return accum;
  }, {} as StepRunTestData);

  return { testData: finalTestData, cachedTestData: cachedTestDataForBlock };
}

export const getV2ApiTestDataSaga = createSaga(
  getV2ApiTestDataInternal,
  "getV2ApiTestData",
  {
    sliceName: slice.name,
    keySelector: (payload) => `${payload.apiId}_${payload.blockName}`,
  },
);

slice.saga(getV2ApiTestDataSaga, {
  start(state, { payload, callId }) {
    //
  },
  success(state, { payload, meta }) {
    const { apiId, blockName } = meta.args;
    if (state.meta[apiId] && payload) {
      const { testData, cachedTestData } = payload;
      state.meta[apiId].testDataForBlock = {
        ...state.meta[apiId].testDataForBlock,
        [blockName]: testData,
      };
      state.meta[apiId].cachedTestDataForBlock = {
        ...state.meta[apiId].testDataForBlock,
        [blockName]: cachedTestData,
      };
    }
  },
  error(state, { meta }) {
    //
  },
});

import { Dimension } from "@superblocksteam/shared";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import { WidgetLayoutProps } from "../../shared";
import {
  getApplicableMaxHeight,
  getApplicableMinHeight,
} from "./canvasHeightUtil";

export function getWidgetHeight(widget: WidgetLayoutProps) {
  const parentRowSpace =
    widget.parentRowSpace ?? GridDefaults.DEFAULT_GRID_ROW_HEIGHT;

  if (
    (widget.height.mode === "fitContent" ||
      widget.height.mode === "fillParent") &&
    widget.dynamicWidgetLayout?.height != null
  ) {
    return widget.dynamicWidgetLayout.height.value;
  }

  let heightPx = Dimension.toPx(widget.height, parentRowSpace).value;

  const minHeight = getApplicableMinHeight(widget);
  const maxHeight = getApplicableMaxHeight(widget);

  if (minHeight) {
    const minHeightPx = Dimension.toPx(minHeight, parentRowSpace).value;
    heightPx = Math.max(heightPx, minHeightPx);
  }
  if (maxHeight) {
    const maxHeightPx = Dimension.toPx(maxHeight, parentRowSpace).value;
    heightPx = Math.min(heightPx, maxHeightPx);
  }
  return heightPx;
}

import { AdditionalScopeProps, DefInfoMap } from "autocomplete/types";
import {
  findBlockLocation,
  getPriorSiblingsFromLocator,
} from "store/slices/apisV2/control-flow/locators";
import {
  ControlFlowFrontendDSL,
  GenericBlock,
  BlockType,
} from "store/slices/apisV2/control-flow/types";
import getBlockVars from "./getBlockVars";
import type { EnrichedExecutionResult } from "store/slices/apisV2";

// Only assign vars to the block's available vars in scope
// if it's not already been assigned, as we assign by moving backwards through the scope
// chain, so if it's already assigned that means it's been overwritten
const assignUpstreamScopeVars = (
  downstreamScopeVars: DefInfoMap,
  upstreamScopeVars: DefInfoMap,
) => {
  const updatedVarsInBlockScope = { ...downstreamScopeVars };
  Object.keys(upstreamScopeVars).forEach((key) => {
    if (!updatedVarsInBlockScope[key]) {
      updatedVarsInBlockScope[key] = upstreamScopeVars[key];
    }
  });
  return updatedVarsInBlockScope;
};

const getVarsInScopeFromParentBlock = (
  blockName: GenericBlock["name"],
  params: {
    controlFlow: ControlFlowFrontendDSL;
    executionResult?: EnrichedExecutionResult;
  },
  reverseDepth: number,
): DefInfoMap => {
  const { controlFlow, executionResult } = params;
  const varsInScope: DefInfoMap = {};
  const block = controlFlow.blocks[blockName];
  if (!block.parentId) {
    throw new Error(`Cannot find parent of block ${blockName}`);
  }
  const parentBlock = controlFlow.blocks[block.parentId];

  const blockLocation = findBlockLocation(controlFlow, block.name);
  if (!blockLocation || blockLocation.parentId == null) {
    throw new Error(`Invalid block location found for ${blockName}`);
  }

  const blockVars = getBlockVars(parentBlock, {
    childLocation: blockLocation,
    executionResult,
    initialRanks: [reverseDepth, 0],
  });
  Object.keys(blockVars).forEach((key) => {
    varsInScope[key] = blockVars[key];
  });

  return varsInScope;
};

const getVarsInScopeFromUpstreamSiblingBlock = (
  block: GenericBlock,
  params: { executionResult?: EnrichedExecutionResult },
  reverseDepth: number,
  siblingRank: number,
): DefInfoMap => {
  const varsInScope: DefInfoMap = {};
  const { executionResult } = params;
  // TODO: What do we set the values to for some of these
  // given they're local scope and not defined until runtime

  const blockVars = getBlockVars(block, {
    executionResult,
    initialRanks: [reverseDepth, siblingRank],
  });
  Object.keys(blockVars).forEach((key) => {
    varsInScope[key] = blockVars[key];
  });

  return varsInScope;
};

const getVarsInScopeFromUpstreamSiblingBlocks = (
  blockName: string,
  params: {
    controlFlow: ControlFlowFrontendDSL;
    executionResult?: EnrichedExecutionResult;
  },
  reverseDepth: number,
): DefInfoMap => {
  const { controlFlow } = params;
  let varsInScope: DefInfoMap = {};
  // Find all siblings of this block that are upstream and get their vars
  // Start from index 0 of the parent block's children to ensure correct overwrite order

  const blockLocation = findBlockLocation(controlFlow, blockName);
  if (!blockLocation) {
    throw new Error(
      `Cannot find location of block ${blockName} from DSL ${JSON.stringify(
        controlFlow,
      )}`,
    );
  }
  const upstreamSiblingBlocks = getPriorSiblingsFromLocator(
    controlFlow,
    blockLocation,
  );

  upstreamSiblingBlocks.forEach((siblingBlockId, i) => {
    const siblingBlockVars = getVarsInScopeFromUpstreamSiblingBlock(
      controlFlow.blocks[siblingBlockId],
      params,
      reverseDepth,
      upstreamSiblingBlocks.length - i,
    );
    varsInScope = assignUpstreamScopeVars(siblingBlockVars, varsInScope);
  });

  return varsInScope;
};

export const computeBlockScope = (
  blockName: string,
  params: {
    controlFlow: ControlFlowFrontendDSL;
    executionResult?: EnrichedExecutionResult;
    additionalScopeProps?: AdditionalScopeProps;
  },
): DefInfoMap => {
  const { controlFlow } = params;
  let varsInScope: DefInfoMap = {};

  if (!controlFlow?.blocks?.[blockName]) {
    // block might be undefined during renames
    return varsInScope;
  }

  // Start by traversing up the tree and getting all vars from upstream siblings in the
  // same parent block. Vars with the same name already defined will not get added because
  // they are overridden in the more specific scope farther down the scope chain
  let continueInScopeChain = true;
  let currentBlockName = blockName;
  let reverseDepth = 0;
  while (continueInScopeChain) {
    const currentBlock = controlFlow.blocks[currentBlockName];
    if (currentBlock == null) {
      break; // this could happen when a block gets deleted but we're still inspecting it
    }
    // Get vars from blocks with the same parent that are upstream of this block
    varsInScope = assignUpstreamScopeVars(
      varsInScope,
      getVarsInScopeFromUpstreamSiblingBlocks(
        currentBlockName,
        params,
        reverseDepth,
      ),
    );

    // Now get vars in scope from parent control blocks like Loops and TryCatch (catch branch)
    // If there is none, that means we've reached the root and have already
    // added upstream siblings, so we can stop
    if (currentBlock.parentId) {
      // Now get the parent's vars
      reverseDepth += 1;
      varsInScope = assignUpstreamScopeVars(
        varsInScope,
        getVarsInScopeFromParentBlock(currentBlock.name, params, reverseDepth),
      );
      currentBlockName = currentBlock.parentId;
    } else {
      continueInScopeChain = false;
    }
  }

  // If the block is a variable block and additionalScopeProps.variableIndex is defined,
  // add all the previous variables from the same block to the scope
  if (
    params.additionalScopeProps?.variableIndex != null &&
    controlFlow.blocks[blockName].type === BlockType.VARIABLES
  ) {
    const variableBlock = controlFlow.blocks[blockName];
    const variableBlockVars = getBlockVars(variableBlock, {
      executionResult: params.executionResult,
      initialRanks: [reverseDepth, 0],
      additionalScopeProps: params.additionalScopeProps,
    });
    Object.keys(variableBlockVars).forEach((key) => {
      varsInScope[key] = variableBlockVars[key];
    });
  }

  return varsInScope;
};

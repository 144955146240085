import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const SendGridPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'SendGrid',
  id: ExtendedIntegrationPluginId.SENDGRID,
  authMethods: {
    bearer: true
  },
  serverURL: 'https://api.sendgrid.com'
});

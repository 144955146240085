import {
  ReturnControl,
  ReturnControlBlock,
} from "store/slices/apisV2/control-flow/types";
import {
  addBindingsToStringValue,
  removeBindingsFromStringValue,
} from "../../ControlFlow/utils";
import { BaseTemplate, BaseTemplateField } from "./BaseTemplate";
import {
  ControlFlowFormComponentType,
  ControlFlowFormItem,
} from "./FormTemplateTypes";

export default class ReturnTemplate extends BaseTemplate<ReturnControlBlock> {
  private getConfig(): Readonly<ReturnControl> {
    return this.source.config;
  }

  dataField(): BaseTemplateField<ReturnControlBlock, string> {
    const currentConfig = this.getConfig();
    const fieldValue = removeBindingsFromStringValue(currentConfig.data);

    const updateValue = (block: ReturnControlBlock, newValue: string) => {
      block.config.data = addBindingsToStringValue(newValue);
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Data to return",
      componentType: ControlFlowFormComponentType.EXPRESSION_EDITOR,
      subHeading:
        "This data will be returned in the API response and execution will not continue",
    };

    return {
      formItem,
      fieldValue,
      updateValue,
    };
  }

  computeFields() {
    return {
      data: this.dataField(),
    };
  }
}

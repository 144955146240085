import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import ErrorComponent from "components/app/Error/ErrorComponent";
import envs from "env";
import { SUPPORT_EMAIL_ADDRESS } from "legacy/constants/routes";
import { SessionStorageKey } from "legacy/utils/sessionStorage";

const Wrapper = styled.div`
  text-align: center;
  .bold-text {
    font-weight: ${(props) => props.theme.legacy.fontWeights[3]};
    font-size: 24px;
  }
  .page-unavailable-img {
    width: 35%;
  }
  .button-position {
    margin: auto;
  }
  height: 100%;
`;

const PageRefreshRequired = () => {
  const currentVersion = envs.get("SUPERBLOCKS_UI_VERSION");
  const previousVersion = useMemo(() => {
    return sessionStorage.getItem(SessionStorageKey.REFRESH_PREVIOUS_VERSION);
  }, []);
  const refreshing = currentVersion !== previousVersion;

  useEffect(() => {
    if (refreshing) {
      // We only want to try the refresh once to avoid getting stuck in a loop
      // in case the refresh doesn't solve the version mismatch for some reason.
      sessionStorage.setItem(
        SessionStorageKey.REFRESH_PREVIOUS_VERSION,
        currentVersion,
      );
      window.location.reload();
    }
  }, [refreshing, currentVersion]);

  return (
    <Wrapper>
      <ErrorComponent
        title={refreshing ? "Refreshing..." : "Refresh required"}
        errorMessage={
          refreshing
            ? `The page is being refreshed to apply the latest updates.`
            : `Superblocks has been updated. If this error persists, please contact ${SUPPORT_EMAIL_ADDRESS}`
        }
        handleButtonClick={() => window.location.reload()}
        buttonText={refreshing ? "Refresh Now" : "Refresh"}
      />
    </Wrapper>
  );
};

export default PageRefreshRequired;

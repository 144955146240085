export function omitGlobal<T>(obj?: T) {
  const target: Record<string, unknown> = {};
  for (const _k in obj) {
    const k = _k;

    if (k === "ENTITY_TYPE") continue;
    if (k === "skippedEvaluation") continue;
    if (k === "bindingPaths") continue;
    if (k === "evaluatedValues") continue;
    if (k === "global") continue;
    if (k === "validationMessages") continue;
    if (k === "store") continue;
    if (k === "invalidProps") continue;
    if (k === "mode") continue;
    // Hiding multi-page related fields
    if (k === "routes") continue;
    if (k === "currentRoute") continue;
    target[_k] = obj[_k];
  }

  return target as any;
}

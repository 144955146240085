import { createVariableNames } from "store/slices/apisV2/control-flow/createVariableName";
import {
  LoopControlBlock,
  LoopControl,
  LoopType,
  BlockType,
} from "store/slices/apisV2/control-flow/types";
import { BLOCK_HELP_CONTENT } from "../../ControlFlow/common-block-type-info";
import {
  makeStringValidJsVarName,
  addBindingsToStringValue,
  removeBindingsFromStringValue,
  UNUSED_VARIABLE_TO_HIDE,
} from "../../ControlFlow/utils";
import { BaseTemplateField, BaseTemplateWithExtras } from "./BaseTemplate";
import {
  ControlFlowFormComponentType,
  ControlFlowFormItem,
  FormComponentType,
} from "./FormTemplateTypes";
export default class LoopTemplate extends BaseTemplateWithExtras<LoopControlBlock> {
  getConfig(): Readonly<LoopControl> {
    return this.source.config;
  }

  typeField(): BaseTemplateField<LoopControlBlock, string> {
    const currentConfig = this.getConfig();
    const fieldValue = currentConfig.type;

    const updateValue = (block: LoopControlBlock, newValue_: string) => {
      const currentVariables = this.getCurrentScopedVariables(block);

      const newValue = newValue_ as LoopType;
      block.config.type = newValue;

      const oldVariableNames = [
        block.config.variables.item ?? "item",
        block.config.variables.index ?? "index",
      ];
      const [item, index] = createVariableNames(
        oldVariableNames,
        currentVariables,
      );
      block.config.variables = {
        item,
        index,
      };
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Loop type",
      componentType: FormComponentType.DROPDOWN,
      options: [
        {
          key: LoopType.FOREACH,
          value: LoopType.FOREACH,
          displayName: "For each",
          subText: "Loop over each item in a list",
        },
        {
          key: LoopType.FOR,
          value: LoopType.FOR,
          displayName: "For",
          subText: "Loop for a fixed number of iterations",
        },
        {
          key: LoopType.WHILE,
          value: LoopType.WHILE,
          displayName: "While",
          subText: "Loop while a condition is true",
        },
      ],
    };

    return {
      formItem,
      fieldValue,
      updateValue,
    };
  }

  rangeField(): BaseTemplateField<LoopControlBlock, string> {
    const currentConfig = this.getConfig();
    const fieldValue = removeBindingsFromStringValue(currentConfig.range);

    const updateValue = (block: LoopControlBlock, newValue: string) => {
      block.config.range = addBindingsToStringValue(newValue);
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: getRangeLabel(currentConfig.type), // "Array or number",
      controlFlowTooltip: getRangeTooltip(currentConfig.type),
      componentType: ControlFlowFormComponentType.EXPRESSION_EDITOR,
      expectedValue: getRangeExpectedValue(currentConfig.type),
      exampleData: getRangeExampleData(currentConfig.type),
    };

    return {
      formItem,
      fieldValue,
      updateValue,
    };
  }

  variablesField({
    includeItem,
    includeIndex,
  }: {
    includeItem: boolean;
    includeIndex: boolean;
  }): BaseTemplateField<LoopControlBlock, Record<string, string>> {
    const currentConfig = this.getConfig();

    const fieldValue: Record<string, string> = {};
    if (includeItem) {
      fieldValue.item = currentConfig.variables.item;
    } else {
      // if we are not including item, we use UNUSED_VARIABLE_TO_HIDE as a placeholder, so the UI will hide it while still sending the item variable, to avoid causing a 400 on backend.
      // this is because backend always expect an item, ideally this should be fixed on backend proto check.
      fieldValue.item = UNUSED_VARIABLE_TO_HIDE;
    }
    if (includeIndex) fieldValue.index = currentConfig.variables.index;

    const updateValue = (
      block: LoopControlBlock,
      newValue: Record<string, string>,
    ) => {
      const newVars: Record<string, string> = {};
      Object.keys(newValue).forEach((key) => {
        newVars[key] = makeStringValidJsVarName(newValue[key]);
      });
      block.config.variables = newVars as LoopControl["variables"];
    };

    const formItem: ControlFlowFormItem = {
      ...this.getBaseFormItem(),
      label: "Loop variables",
      componentType: ControlFlowFormComponentType.LOCAL_VARIABLES_LIST,
    };

    return {
      formItem,
      fieldValue,
      updateValue,
    };
  }

  computeFields() {
    const baseFields: {
      type: BaseTemplateField<LoopControlBlock, string>;
      range: BaseTemplateField<LoopControlBlock, string>;
      variables?: BaseTemplateField<LoopControlBlock, Record<string, string>>;
    } = {
      type: this.typeField(),
      range: this.rangeField(),
    };

    // while and for loops do not have an item variable
    if ([LoopType.FOR, LoopType.WHILE].includes(this.getConfig().type)) {
      return {
        ...baseFields,
        variables: this.variablesField({
          includeItem: false,
          includeIndex: true,
        }),
      };
    } else {
      return {
        ...baseFields,
        variables: this.variablesField({
          includeItem: true,
          includeIndex: true,
        }),
      };
    }
  }
}

const getRangeLabel = (type: LoopControl["type"]) => {
  switch (type) {
    case LoopType.FOR:
      return "Number of iterations";
    case LoopType.FOREACH:
      return "List of items";
    case LoopType.WHILE:
      return "Condition";
    case LoopType.UNSPECIFIED:
      throw new Error("Invalid loop type");
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(
        `Unhandled form case: ${JSON.stringify(exhaustiveCheck)}`,
      );
    }
  }
};

const getRangeTooltip = (type: LoopControl["type"]) => {
  switch (type) {
    case LoopType.FOR:
      return BLOCK_HELP_CONTENT[BlockType.LOOP].fieldHelpContent.forIterations;
    case LoopType.FOREACH:
      return BLOCK_HELP_CONTENT[BlockType.LOOP].fieldHelpContent
        .forEachIterations;
    case LoopType.WHILE:
      return BLOCK_HELP_CONTENT[BlockType.LOOP].fieldHelpContent.whileCondition;
    case LoopType.UNSPECIFIED:
      throw new Error("Invalid loop type");
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(
        `Unhandled form case: ${JSON.stringify(exhaustiveCheck)}`,
      );
    }
  }
};

const getRangeExpectedValue = (type: LoopControl["type"]) => {
  switch (type) {
    case LoopType.FOR:
      return "number";
    case LoopType.FOREACH:
      return "Array<any>";
    case LoopType.WHILE:
      return "boolean";
    case LoopType.UNSPECIFIED:
      throw new Error("Invalid loop type");
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(
        `Unhandled form case: ${JSON.stringify(exhaustiveCheck)}`,
      );
    }
  }
};

const getRangeExampleData = (type: LoopControl["type"]) => {
  switch (type) {
    case LoopType.FOR:
      return "5";
    case LoopType.FOREACH:
      return `[
  { "city": "New York" },
  { "city": "San Francisco" }
]`;
    case LoopType.WHILE:
      return undefined;
    case LoopType.UNSPECIFIED:
      throw new Error("Invalid loop type");
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(
        `Unhandled form case: ${JSON.stringify(exhaustiveCheck)}`,
      );
    }
  }
};

import { createReducer } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import referenceAnalysisReducer, {
  ReferenceAnalysisState,
} from "analysis/referenceAnalysisReducer";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import apiPaneReducer, { APIPaneReduxState } from "./apiPaneReducer";
import appViewReducer, { AppViewReduxState } from "./appViewReducer";
import { CanvasSelectionState } from "./canvasSelectionReducer";
import canvasSelectionReducer from "./canvasSelectionReducer";
import widgetDraggingReducer, {
  WidgetDragResizeState,
} from "./dragResizeReducer";
import dynamicFormReducer, { DynamicFormState } from "./dynamicFormReducer";
import editorPreferencesReducer, {
  EditorPreferencesState,
} from "./editorPreferencesReducer";
import editorReducer, { EditorReduxState } from "./editorReducer";
import errorReducer, { ErrorReduxState } from "./errorReducer";
import explorerReducer, { ExplorerReduxState } from "./explorerReducer";
import globalReducer, { GlobalState } from "./global";
import modalReducer, { ModalState } from "./modalReducer";
import orgReducer, { OrgReduxState } from "./orgReducer";
import propertyPaneReducer, {
  PropertyPaneReduxState,
} from "./propertyPaneReducer";
import themeReducer, { ThemeState } from "./themeReducer";
import usersReducer, { UsersReduxState } from "./usersReducer";

export interface UiState {
  editor: EditorReduxState;
  propertyPane: PropertyPaneReduxState;
  apiPane: APIPaneReduxState;
  errors: ErrorReduxState;
  appView: AppViewReduxState;
  orgs: OrgReduxState;
  users: UsersReduxState;
  widgetDragResize: WidgetDragResizeState;
  explorer: ExplorerReduxState;
  editorPreferences: EditorPreferencesState;
  theme: ThemeState;
  modal: ModalState;
  globalStates: GlobalState;
  referenceAnalysis: ReferenceAnalysisState;
  canvasSelection: CanvasSelectionState;
  isSplitPaneDragging: boolean;
  isAiAssistantDragging: boolean;
  dynamicForm: DynamicFormState;
}

export default combineReducers({
  editor: editorReducer,
  errors: errorReducer,
  propertyPane: propertyPaneReducer,
  appView: appViewReducer,
  orgs: orgReducer,
  users: usersReducer,
  widgetDragResize: widgetDraggingReducer,
  explorer: explorerReducer,
  theme: themeReducer,
  editorPreferences: editorPreferencesReducer,
  apiPane: apiPaneReducer,
  modal: modalReducer,
  globalStates: globalReducer,
  referenceAnalysis: referenceAnalysisReducer,
  canvasSelection: canvasSelectionReducer,
  isSplitPaneDragging: createReducer(false, (builder) =>
    builder.addCase(
      ReduxActionTypes.SET_SPLIT_PANE_DRAGGING,
      (
        state,
        action: ReduxAction<
          boolean,
          typeof ReduxActionTypes.SET_SPLIT_PANE_DRAGGING
        >,
      ) => {
        return action.payload;
      },
    ),
  ),
  isAiAssistantDragging: createReducer(false, (builder) =>
    builder.addCase(
      ReduxActionTypes.SET_AI_ASSISTANT_DRAGGING,
      (
        state: boolean,
        action: ReduxAction<
          boolean,
          typeof ReduxActionTypes.SET_AI_ASSISTANT_DRAGGING
        >,
      ) => {
        return action.payload;
      },
    ),
  ),
  dynamicForm: dynamicFormReducer,
});

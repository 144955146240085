import { Slice } from "store/utils/slice";
import type { CommitDto } from "@superblocksteam/shared";
import type { EntityState } from "store/utils/types";

type Commits = {
  autosaves: Record<string, CommitDto>;
  commits: Record<string, CommitDto>;
};

type CommitState = EntityState<Commits>;

export default new Slice<CommitState>("commits", {
  entity: {
    autosaves: {},
    commits: {},
  },
  loading: {} as any,
  error: {} as any,
});

import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationScope } from "@superblocksteam/shared";
import { set } from "lodash";
import { stopEvaluation } from "legacy/actions/evaluationActions";
import { initCanvasLayout } from "legacy/actions/pageActions";
import { AppStateVarScoped } from "store/slices/application/stateVars/StateConstants";
import { toScopedEntity } from "store/utils/scope";
import {
  fetchApplicationSuccess,
  doneRefactoringAppConfiguration,
} from "../applicationActions";

const initialState: {
  [ApplicationScope.GLOBAL]: Record<string, AppStateVarScoped>;
  [ApplicationScope.APP]: Record<string, AppStateVarScoped>;
  [ApplicationScope.PAGE]: Record<string, AppStateVarScoped>;
} = {
  [ApplicationScope.GLOBAL]: {},
  [ApplicationScope.APP]: {},
  [ApplicationScope.PAGE]: {},
};

export type StateVarMap = typeof initialState;

interface RenameStateVarUpdate {
  stateVarId: string;
  propertyName: string;
  propertyValue: unknown;
  scope: ApplicationScope;
}

interface RenameStateVarsPayload {
  updates: Array<RenameStateVarUpdate>;
}

export const renameStateVars = createAction(
  "RENAME_STATE_VARS",
  (updates: RenameStateVarsPayload["updates"]) => ({
    payload: {
      updates,
    },
  }),
);

export const stateVarsSlice = createSlice({
  name: "stateVars",
  initialState,
  reducers: {
    overwriteScopedStateVars: (
      state,
      action: PayloadAction<{
        scope: ApplicationScope;
        stateVars: Record<string, AppStateVarScoped>;
      }>,
    ) => {
      state[action.payload.scope] = action.payload.stateVars;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(initCanvasLayout, (state, action) => {
        if (action.payload.stateVarMap) {
          state[ApplicationScope.PAGE] = toScopedEntity<AppStateVarScoped>(
            action.payload.stateVarMap,
            ApplicationScope.PAGE,
          );
        }
      })
      .addCase(fetchApplicationSuccess, (state, action) => {
        if (action.payload.configuration?.dsl?.stateVars?.stateVarMap) {
          state[ApplicationScope.APP] = toScopedEntity<AppStateVarScoped>(
            action.payload.configuration.dsl.stateVars.stateVarMap,
            ApplicationScope.APP,
          );
        }
      })
      .addCase(doneRefactoringAppConfiguration, (state, action) => {
        if (action.payload?.dsl?.stateVars?.stateVarMap) {
          state[ApplicationScope.APP] = toScopedEntity<AppStateVarScoped>(
            action.payload.dsl.stateVars.stateVarMap,
            ApplicationScope.APP,
          );
        }
      })
      .addCase(renameStateVars, (state, action) => {
        action.payload.updates.forEach(
          ({ stateVarId, propertyName, propertyValue, scope }) => {
            set(state[scope][stateVarId], propertyName, propertyValue);
          },
        );
      })
      .addCase(stopEvaluation, () => initialState);
  },
});

export const { overwriteScopedStateVars } = stateVarsSlice.actions;

import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const AirtablePlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Airtable',
  id: ExtendedIntegrationPluginId.AIRTABLE,
  authMethods: {
    bearer: true
  },
  serverURL: 'https://api.airtable.com'
});

import { switchCurrentPage } from "legacy/actions/pageActions";
import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { createImmerReducer } from "legacy/reducers/createReducer";
import { ENTITY_TYPE } from "utils/dataTree/constants";
import {
  setAllReferencesModalOpenPath,
  setReferenceAnalysisModalOpen,
  setReferenceAnalysisState,
} from "./actions";

export type ReferenceAnalysisMeta = {
  lastSuccessfulWrite: number; // what was lastSuccessfulWrite at the time of the analysis
  computedTime: number;
};
export interface ReferenceAnalysisState {
  referenceGraphJson?: object; // can rehydrate to a graph via Graphlib json.read
  usageGraphJson?: object; // can rehydrate to a graph via Graphlib json.read
  meta?: ReferenceAnalysisMeta;
  entityModalOpenType?: null | ENTITY_TYPE; // todo support "all"
  allReferencesModalOpenPath?: null | string; // path in the form of SCOPE.ENTITY_NAME
}

const initialState: ReferenceAnalysisState = {};

const referenceAnalysisReducer = createImmerReducer(initialState, (builder) =>
  builder
    .addCase(
      setReferenceAnalysisState.type,
      (
        state: ReferenceAnalysisState,
        action: ReduxAction<
          ReferenceAnalysisState,
          typeof ReduxActionTypes.SET_REFERENCE_ANALYSIS_STATE
        >,
      ) => {
        state.referenceGraphJson = action.payload.referenceGraphJson;
        state.usageGraphJson = action.payload.usageGraphJson;
        state.meta = action.payload.meta;
      },
    )
    .addCase(
      setReferenceAnalysisModalOpen.type,
      (
        state: ReferenceAnalysisState,
        action: ReduxAction<
          null | ENTITY_TYPE,
          typeof ReduxActionTypes.SET_REFERENCE_ANALYSIS_MODAL_OPEN
        >,
      ) => {
        state.entityModalOpenType = action.payload;
      },
    )
    .addCase(
      switchCurrentPage.type,
      (
        state: ReferenceAnalysisState,
        action: ReduxAction<boolean, typeof switchCurrentPage.type>,
      ) => {
        // reset state
        state.referenceGraphJson = undefined;
        state.usageGraphJson = undefined;
        state.meta = undefined;
        state.entityModalOpenType = null;
      },
    )
    .addCase(
      setAllReferencesModalOpenPath.type,
      (
        state: ReferenceAnalysisState,
        action: ReduxAction<
          null | string,
          typeof ReduxActionTypes.SET_ALL_REFERENCES_MODAL_OPEN_PATH
        >,
      ) => {
        state.allReferencesModalOpenPath = action.payload;
      },
    ),
);

export default referenceAnalysisReducer;

import { createReducer } from "@reduxjs/toolkit";
import { UpdateEmbedPropMetaPropertiesPayload } from "legacy/actions/embeddingActions";
import {
  EmbedProperty,
  EmbedPropertyMetaType,
} from "legacy/constants/EmbeddingConstants";
import {
  ReduxActionTypes,
  ReduxAction,
} from "legacy/constants/ReduxActionConstants";

type EmbedPropsMetaState = Record<EmbedProperty["id"], EmbedPropertyMetaType>;

const initialState: EmbedPropsMetaState = {};

// NOTE: When the onChange handlers need to run, use updateEmbedMetaPropSaga
const embedMetaReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(
      ReduxActionTypes.UPDATE_EMBED_PROPERTY_META,
      (
        state,
        {
          payload: { updates },
        }: ReduxAction<
          UpdateEmbedPropMetaPropertiesPayload,
          typeof ReduxActionTypes.UPDATE_EMBED_PROPERTY_META
        >,
      ) => {
        return updates;
      },
    )
    .addCase(
      ReduxActionTypes.DELETE_EMBED_PROPERTY,
      (
        state,
        action: ReduxAction<
          { id: EmbedProperty["id"] },
          typeof ReduxActionTypes.DELETE_EMBED_PROPERTY
        >,
      ) => {
        delete state[action.payload.id];
      },
    ),
);

export default embedMetaReducer;

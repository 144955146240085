module.exports = {
  sharedAntdTheme: {
    text: {
      fonts: {
        base: "var(--font-family)",
        code: "var(--font-monospace)",
      },
      colors: {
        base: "rgba(0, 0, 0, 0.85)",
        secondary: "rgba(0, 0, 0, 0.45)",
        inverse: "#fff",
      },
      sizes: {
        small: "12px",
        medium: "14px",
        large: "16px",
        heading1: "38px",
        heading2: "30px",
        heading3: "24px",
        heading4: "20px",
        heading5: "16px",
      },
      lineHeights: {
        base: "1.5715",
      },
    },
    outline: {
      offset: "0 0",
      blurSize: "0",
      width: "2px",
      fade: "20%",
    },
    border: {
      width: "1px",
      style: "solid",
      colors: {
        base: "#e8eaed",
        split: "#f0f0f0",
        inverse: "#fff",
      },
    },
    radiuses: {
      base: "4px",
    },
    shadows: {
      base: "0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)",
    },
    heights: {
      large: "40px",
      medium: "32px",
      small: "24px",
    },
    paddings: {
      large: "24px",
      medium: "16px",
      small: "12px",
      extraSmall: "8px",
      tiny: "4px",
    },
    margins: {
      large: "24px",
      medium: "16px",
      small: "12px",
      extraSmall: "8px",
      tiny: "4px",
    },
  },
};

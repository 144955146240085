import { ApiTriggerType } from "@superblocksteam/shared";
import { call } from "redux-saga/effects";
import { ActionDto } from "store/slices/apis/types";
import { EnrichedExecutionResult } from "../types";
import { executionResponseManipulators } from "../utils/execution-response";
import { getGeneratedNameRefactors } from "./block-generated-variables";
import {
  LOOP_DEFAULT_RANGE_VALUES,
  createBlankControlBlock,
  createNewStepBlock,
} from "./block-generation";
import computeControlFlowVarsInScope from "./computeControlFlowVarsInScope";
import {
  deleteBlockTree,
  moveBlocks,
  renameBlock as renameBlockManipulator,
} from "./control-flow-manipulation";
import {
  findBlockLocation,
  getBlockListFromLocator,
  BlockPositionLocator,
} from "./locators";
import { RefactoredType, refactorEntityName } from "./refactor-entity-name";
import {
  ControlFlowFrontendDSL,
  StepConfig,
  GenericBlock,
  BlockType,
  LoopControl,
} from "./types";

export interface MutatorActions {
  renameBlock: (payload: {
    prevBlockName: GenericBlock["name"];
    updatedBlockName: GenericBlock["name"];
  }) => Promise<void>;
  updateBlockConfig: (payload: { updatedBlock: GenericBlock }) => Promise<void>;
  dropBlock: (payload: {
    droppedNames: string[];
    location: BlockPositionLocator;
  }) => Promise<void>;
  updateDSL: (payload: {
    newControlFlowDSL: ControlFlowFrontendDSL;
  }) => Promise<void>;
  addNewControlBlock: (payload: {
    controlType: Exclude<BlockType, BlockType.STEP>;
    location: BlockPositionLocator;
    triggerType: null | undefined | ApiTriggerType;
  }) => Promise<string>;
  addNewStepBlock: (payload: {
    actionConfig: ActionDto;
    location: BlockPositionLocator;
  }) => Promise<string>;
  setActionConfig: (payload: {
    blockName: string;
    config: any;
  }) => Promise<void>;
  deleteBlock: (payload: { blockName: string }) => Promise<void>;
}

export type MutationPayloadMap = {
  [k in keyof MutatorActions]: {
    payload: Parameters<MutatorActions[k]>[0];
    type: k;
  };
};

export type MutatorActionPayload<T extends keyof MutatorActions> = Parameters<
  MutatorActions[T]
>[0];

export type MutationReturnType<T extends keyof MutatorActions> = Awaited<
  ReturnType<MutatorActions[T]>
>;

type MutationReturnMap = {
  [k in keyof MutatorActions]: MutationReturnType<k>;
};

export type MutationReturnWithMeta<T> = T extends void
  ? void
  : {
      result: T;
      hydrateBlockId?: string;
    };

export type MutationReturnUnion = MutationReturnMap[keyof MutationReturnMap];

type Drafts = {
  controlFlow: ControlFlowFrontendDSL;
  enrichedExecutionResult: undefined | EnrichedExecutionResult;
};

export function* renameBlock(
  drafts: Drafts,
  payload: MutatorActionPayload<"renameBlock">,
): Generator<any, MutationReturnWithMeta<void>, any> {
  const { controlFlow, enrichedExecutionResult } = drafts;
  const { prevBlockName, updatedBlockName } = payload;
  const blockLocation = findBlockLocation(controlFlow, prevBlockName);

  if (!blockLocation) {
    throw new Error(`Block with name ${prevBlockName} not found`);
  }

  enrichedExecutionResult &&
    executionResponseManipulators.renameBlock(enrichedExecutionResult, {
      oldName: prevBlockName,
      newName: updatedBlockName,
    });

  renameBlockManipulator({
    prevBlockName,
    updatedBlockName,
    location: blockLocation,
    state: controlFlow,
  });

  yield call(refactorEntityName, controlFlow, {
    prevName: prevBlockName,
    newName: updatedBlockName,
    refactoredType: RefactoredType.BLOCK,
  });
}

export function* updateBlockConfig(
  drafts: Drafts,
  payload: MutatorActionPayload<"updateBlockConfig">,
): Generator<any, MutationReturnWithMeta<void>, any> {
  const { controlFlow } = drafts;
  const { updatedBlock } = payload;

  const oldBlock = controlFlow.blocks[updatedBlock.name];
  // Replace the old block with the new one
  controlFlow.blocks[updatedBlock.name] = updatedBlock;

  // Set default values depending on updatedBlock properties
  // Extract this into more general function if there's a second use case for this
  if (
    updatedBlock.type === BlockType.LOOP &&
    oldBlock.type === BlockType.LOOP
  ) {
    const oldLoopConfig = oldBlock.config as LoopControl;
    const updatedLoopConfig = updatedBlock.config as LoopControl;

    const isChangedLoopType = oldLoopConfig.type !== updatedLoopConfig.type;
    const isDefaultRangeValue =
      LOOP_DEFAULT_RANGE_VALUES[oldLoopConfig.type] === updatedLoopConfig.range;
    const isRangeEmpty = updatedLoopConfig.range === "{{}}";

    if (isChangedLoopType && (isDefaultRangeValue || isRangeEmpty)) {
      updatedLoopConfig.range =
        LOOP_DEFAULT_RANGE_VALUES[updatedLoopConfig.type];
    }
  }

  if (oldBlock) {
    for (const refactor of getGeneratedNameRefactors(
      oldBlock,
      updatedBlock,
      controlFlow,
    )) {
      const { oldName, newName, affectedBlockNames } = refactor;
      yield call(refactorEntityName, controlFlow, {
        prevName: oldName,
        newName,
        refactoredType: RefactoredType.BLOCK_VAR,
        affectedBlockNames,
      });
    }
  }
}

export function dropBlock(
  drafts: Drafts,
  payload: MutatorActionPayload<"dropBlock">,
) {
  const { controlFlow } = drafts;
  const { droppedNames, location } = payload;

  moveBlocks({
    newLocation: location,
    blockNames: droppedNames,
    state: controlFlow,
  });
}

export function updateDSL(
  drafts: Drafts,
  payload: MutatorActionPayload<"updateDSL">,
) {
  const { controlFlow } = drafts;
  const { newControlFlowDSL } = payload;

  Object.assign(controlFlow, newControlFlowDSL);
}

export function addNewControlBlock(
  drafts: Drafts,
  payload: MutatorActionPayload<"addNewControlBlock">,
) {
  const { controlFlow } = drafts;
  const { controlType, location, triggerType } = payload;

  const blockList = getBlockListFromLocator(controlFlow, location);
  const variableNames = computeControlFlowVarsInScope(location, controlFlow);
  const blankBlock = createBlankControlBlock({
    controlType,
    existingBlocks: controlFlow.blocks,
    parentId: location.parentId ?? undefined,
    usedVariables: variableNames,
    triggerType,
  });
  blockList.splice(location.idx, 0, blankBlock.name);
  controlFlow.blocks[blankBlock.name] = blankBlock;
  return { result: blankBlock.name };
}

export function addNewStepBlock(
  drafts: Drafts,
  payload: MutatorActionPayload<"addNewStepBlock">,
) {
  const { controlFlow } = drafts;
  const { actionConfig, location } = payload;

  const blockList = getBlockListFromLocator(controlFlow, location);
  const newBlock = createNewStepBlock(
    actionConfig,
    controlFlow.blocks,
    location.parentId ?? undefined,
  );
  if (location.parentId == null) {
    blockList.splice(location.idx, 0, newBlock.name);
  } else {
    blockList.splice(location.idx, 0, newBlock.name);
  }
  controlFlow.blocks[newBlock.name] = newBlock;
  return { result: newBlock.name, hydrateBlockId: newBlock.name };
}

export function setActionConfig(
  drafts: Drafts,
  payload: MutatorActionPayload<"setActionConfig">,
) {
  const { controlFlow } = drafts;
  const { blockName, config } = payload;

  const blockToEdit = controlFlow.blocks[blockName];
  if (blockToEdit.type !== BlockType.STEP) {
    throw new Error("Invalid block type for setting config");
  }
  (blockToEdit.config as StepConfig).configuration = config;
}

export function deleteBlock(
  drafts: Drafts,
  payload: MutatorActionPayload<"deleteBlock">,
) {
  const { controlFlow, enrichedExecutionResult } = drafts;
  const { blockName } = payload;

  enrichedExecutionResult &&
    executionResponseManipulators.deleteBlock(enrichedExecutionResult, {
      deletedName: blockName,
    });
  deleteBlockTree(controlFlow, blockName);
}

import { AgentsHealthStatus } from "@superblocksteam/shared";
import unreachable from "@superblocksteam/shared/src/utils/unreachable";
import { Typography } from "antd";
import React from "react";
import styled from "styled-components";
import NoAgentsImage from "assets/images/errors/no-agents-image.svg";
import ErrorComponent from "components/app/Error/ErrorComponent";
import Link from "components/ui/Link";
import { useFeatureFlag } from "hooks/ui";
import {
  AGENTS_DEPLOYMENT_INSTRUCTIONS_URL,
  DOCS_URL,
} from "legacy/constants/routes";
import { getAppProfilesInCurrentMode } from "legacy/selectors/applicationSelectors";
import { getUserCurrentOrgId } from "legacy/selectors/organizationSelectors";
import { getCurrentUserIsAdmin } from "legacy/selectors/usersSelectors";
import { useAppSelector } from "store/helpers";
import { selectAgentsHealthStatus } from "store/slices/agents";
import { getEnvironment } from "store/slices/application/selectors";
import { Flag } from "store/slices/featureFlags";
import { selectOrganizationById } from "store/slices/organizations";

const Wrapper = styled.div`
  text-align: center;
  .bold-text {
    font-weight: ${(props) => props.theme.legacy.fontWeights[3]};
    font-size: 24px;
  }
  .page-unavailable-img {
    width: 35%;
  }
  .button-position {
    margin: auto;
  }
  height: 100%;
`;

const AgentErrorMessage: React.FC = (props) => {
  const organizationId = useAppSelector(getUserCurrentOrgId) ?? "";
  const organization = useAppSelector((state) =>
    selectOrganizationById(state, organizationId),
  );
  const enableProfiles = useFeatureFlag(Flag.ENABLE_PROFILES);
  const profiles = useAppSelector(getAppProfilesInCurrentMode);
  const profile = profiles?.selected;
  const environment = useAppSelector(getEnvironment);
  const agentsHealthStatus = useAppSelector(
    selectAgentsHealthStatus(
      organization?.agentType,
      enableProfiles ? (profile?.key ?? "") : environment,
    ),
  );
  const userIsAdmin = useAppSelector(getCurrentUserIsAdmin);
  if (
    agentsHealthStatus === AgentsHealthStatus.HEALTHY ||
    agentsHealthStatus === AgentsHealthStatus.UNKNOWN
  ) {
    // This should not really happen
    return (
      <>
        Unable to connect to on-premise agent(s) to verify your code. There was
        an error. Please try again
      </>
    );
  }
  if (userIsAdmin) {
    switch (agentsHealthStatus) {
      case AgentsHealthStatus.NO_AGENTS:
        return (
          <>
            Unable to connect to on-premise agent(s) to verify your code. No
            agents are registered.{" "}
            <Link to={AGENTS_DEPLOYMENT_INSTRUCTIONS_URL}>Deploy an agent</Link>
          </>
        );
      case AgentsHealthStatus.NO_AGENTS_FOR_PROFILE:
        return (
          <>
            Unable to connect to on-premise agent(s) to verify your code. No
            agents are registered for{" "}
            {profile?.displayName ?? profile?.key ?? " the current profile"}.{" "}
            <Link to={AGENTS_DEPLOYMENT_INSTRUCTIONS_URL}>Deploy an agent</Link>{" "}
            with the tag <code>profile:{profile?.key ?? "<PROFILE_KEY>"}</code>
          </>
        );
      case AgentsHealthStatus.NO_REACHABLE_AGENTS:
        return (
          <>
            Unable to connect to on-premise agent(s) to verify your code. Check
            your VPN and firewall rules of your agent. Refer to the{" "}
            <Typography.Link
              href={`${DOCS_URL}/on-premise-agent/manage/troubleshooting`}
            >
              troubleshooting guide
            </Typography.Link>{" "}
            for more info
          </>
        );
      default:
        unreachable(agentsHealthStatus);
    }
  } else {
    switch (agentsHealthStatus) {
      case AgentsHealthStatus.NO_AGENTS:
        return (
          <>
            Unable to connect to on-premise agent(s) to verify your code. No
            agents are registered. Please contact your admins for assistance
          </>
        );
      case AgentsHealthStatus.NO_AGENTS_FOR_PROFILE:
        return (
          <>
            Unable to connect to on-premise agent(s) to verify your code. No
            agents are registered for{" "}
            {profile?.displayName ?? profile?.key ?? " your profile"}. Please
            contact your admins for assistance
          </>
        );
      case AgentsHealthStatus.NO_REACHABLE_AGENTS:
        return (
          <>
            Unable to connect to on-premise agent(s) to verify your code. Please
            log in to your VPN and check network connection. Contact your admins
            if the issue persists
          </>
        );
      default:
        unreachable(agentsHealthStatus);
    }
  }
};

const PageNoAgents = () => {
  return (
    <Wrapper>
      <ErrorComponent
        title="Can’t contact your agent"
        errorMessage={
          <div style={{ maxWidth: 520 }}>
            <AgentErrorMessage />
          </div>
        }
        image={NoAgentsImage}
        buttonText="Try Again"
        handleButtonClick={() => window.location.reload()}
        secondaryButtonText="Return Home"
        secondaryButtonAction={() => window.location.replace("/")}
      />
    </Wrapper>
  );
};

export default PageNoAgents;

import {
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { createImmerReducer } from "../createReducer";

const lastSuccessfulWrite = createImmerReducer(-1, (builder) =>
  builder.addCase(
    ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
    (
      state: number,
      action: ReduxAction<
        Date,
        typeof ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE
      >,
    ) => {
      return Math.max(state, new Date(action.payload).getTime());
    },
  ),
);

export default lastSuccessfulWrite;

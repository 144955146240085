import { SagaType, callSagas, createSaga } from "../../../utils/saga";

import slice from "../slice";
import { getV2ApiTestDataSaga } from "./getV2ApiTestData";

function* getV2ApiTestDataDebouncedInternal(payload: {
  apiId: string;
  blockName: string;
  computeDependencies?: boolean;
}) {
  yield callSagas([getV2ApiTestDataSaga.apply(payload)]);
}

export const getV2ApiTestDataDebouncedSaga = createSaga(
  getV2ApiTestDataDebouncedInternal,
  "getV2ApiTestDataDebouncedSaga",
  {
    sliceName: slice.name,
    keySelector: (payload) => `${payload.apiId}_${payload.blockName}`,
    type: SagaType.Debounced,
    delay: 1000,
  },
);

slice.saga(getV2ApiTestDataDebouncedSaga, {
  start(state, { payload, callId }) {
    //
  },
  success(state, { payload, meta }) {
    //
  },
  error(state, { meta }) {
    //
  },
});

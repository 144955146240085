// entity type used for coloring and permissioned types
export enum EntityType {
  APPLICATION = "application",
  WORKFLOW = "workflow",
  SCHEDULED_JOB = "scheduled_job",
}

export const getEntityLabel = (entityType: EntityType) => {
  switch (entityType) {
    case EntityType.APPLICATION:
      return "Application";
    case EntityType.WORKFLOW:
      return "Workflow";
    case EntityType.SCHEDULED_JOB:
      return "Scheduled Job";
  }
};

import { getType, Types } from "legacy/utils/TypeHelpers";
import { generateReactKey } from "legacy/utils/generators";
import { TypeDef, TypeInfo } from "./types";

// TODO(mark/saksham/alex) merge this code with customTreeTypeDefCreator.ts
function generateTypeDef(obj: any, extraDefs: TypeInfo): TypeDef {
  const type = getType(obj);
  switch (type) {
    case Types.ARRAY: {
      if (obj.length <= 0) {
        return "[]";
      }
      const arrayType = generateTypeDef(obj[0], extraDefs);
      const name = generateReactKey();
      extraDefs[name] = arrayType;
      return `[${name}]`;
    }
    case Types.OBJECT: {
      const objType: Record<string, string | Record<string, unknown>> = {};
      Object.keys(obj).forEach((k) => {
        objType[k] = generateTypeDef(obj[k], extraDefs);
      });
      return objType;
    }
    case Types.STRING:
      return "string";
    case Types.NUMBER:
      return "number";
    case Types.BOOLEAN:
      return "bool";
    case Types.MAP:
      return "Map.prototype";
    case Types.SET:
      return "Set.prototype";
    default:
      return "?";
  }
}

export default generateTypeDef;

import type { AppState } from "store/types";

export const getSafeCrash = (state: AppState) => {
  return state.legacy.ui.errors.safeCrash;
};

export const getSafeCrashCode = (state: AppState) => {
  return state.legacy.ui.errors.safeCrashCode;
};

export const getSafeCrashCurrentError = (state: AppState) => {
  return state.legacy.ui.errors.currentError;
};

export const getSafeCrashReason = (state: AppState) => {
  return state.legacy.ui.errors.safeCrashReason;
};

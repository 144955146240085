import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const PagerDutyPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'PagerDuty',
  id: ExtendedIntegrationPluginId.PAGERDUTY,
  authMethods: {
    tokenPrefixed: {
      prefix: 'Token token='
    }
  },
  serverURL: 'https://api.pagerduty.com'
});

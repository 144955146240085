import { ExtendedIntegrationPluginId, Plugin } from '../../types';
import { getNativeOpenApiPlugin } from './shared';

export const SlackPlugin: Plugin = getNativeOpenApiPlugin({
  name: 'Slack',
  id: ExtendedIntegrationPluginId.SLACK,
  authMethods: {
    bearer: true,
    oauth2BringYourOwn: {
      tokenUrl: 'https://slack.com/api/oauth.access',
      authorizationUrl: 'https://slack.com/oauth/authorize',
      revokeTokenUrl: 'https://slack.com/api/auth.revoke',
      iconUrl: 'https://integrations.superblocks.com/img/slack.png'
    }
  },
  serverURL: 'https://slack.com/api'
});
